import React from "react"
import { Label } from "reactstrap"
import FadeLoader from "react-spinners/FadeLoader"
import RingLoader from "react-spinners/RingLoader"
import ClockLoader from "react-spinners/ClockLoader"

import "./FullContentLoading.scss"

const FullContentLoading = ({ loading, type, title, filter = false, fixed = false }) => {
  return (
    <section className="full-content-loading" style={fixed ? { position: "fixed" } : {}}>
      {(() => {
        switch (type) {
          case "ring":
            return (
              <div className="ring-loading">
                <RingLoader color={"#FFFFFF"} size={150} loading={loading} />
                {!!title ? <Label>{title}</Label> : ""}
              </div>
            )
          case "clock":
            return (
              <div className="clock-loading">
                <ClockLoader color={"#FFFFFF"} size={150} loading={loading} />
                {!!title ? <Label>{title}</Label> : ""}
              </div>
            )
          case "fade":
            return (
              <div className="fade-loading">
                <FadeLoader
                  css={"z-index: 1001; margin-top: -100px;"}
                  color={"#FFFFFF"}
                  height={32}
                  width={6}
                  radius={3}
                  margin={12}
                  loading={loading}
                />
                {!!title ? <Label>{title}</Label> : ""}
              </div>
            )
          default:
            return (
              <>
                <div className={loading ? "filter" : " "} />
                <FadeLoader
                  css={"z-index: 1001; margin-top: -100px;"}
                  color={"#EAEAEA"}
                  height={32}
                  width={6}
                  radius={3}
                  margin={12}
                  loading={loading}
                />
              </>
            )
        }
      })()}
    </section>
  )
}

export default FullContentLoading
