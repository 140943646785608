import React from "react"
import { Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./AddressesInfoItem.scss"

const AddressesInfoItem = ({ selected, address, handlShowAddressOptions, origin = "addressinfo", selectThisAddress }) => {
  const {
    id_street,
    nm_street,
    street_type: { nm_type },
    neighbourhood: {
      nm_neighbourhood,
      city: {
        nm_city,
        state: { ds_acronym },
      },
    },
    customer_address: { ds_address_additional_info: complement, ds_observation: reference, num_address },
  } = address

  return (
    <div
      //
      className={"addresses-info--item" + (selected ? " selected-addresses-info--item" : "")}
      onClick={() => {
        if (origin === "deliverytype") {
          selectThisAddress(id_street)
        }
      }}
    >
      <div className={"addresses-info--item--left"} />
      <div className={"addresses-info--item--center"}>
        <div className={"addresses-info--item--center--title"}>
          <Label>{nm_street + `${!!num_address ? `, ${num_address}` : ""}`}</Label>
        </div>

        <div className={"addresses-info--item--center--neighbourhood"}>
          <Label> {`${nm_neighbourhood}, ${nm_city} - ${ds_acronym}`}</Label>
        </div>

        {reference ? (
          <div className={"addresses-info--item--center--details"}>
            <Label>
              {/*{!!address.ds_observation && !!address.ds_address_additional_info*/}
              {/*  ? `${complement || ""} - ${*/}
              {/*      !!address.ds_observation ? address.ds_observation : ""*/}
              {/*    }`*/}
              {/*  : ""}*/}
              {reference ? <Label>{reference}</Label> : ""}
            </Label>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={"addresses-info--item--right"}
        onClick={() => {
          if (origin === "addressinfo") {
            handlShowAddressOptions()
          } else if (origin === "deliverytype") {
            // console.log("eaeeeeee")
          }
        }}
      >
        {origin === "addressinfo" ? <FontAwesomeIcon icon={"ellipsis-vertical"} /> : ""}
      </div>
    </div>
  )
}

export default AddressesInfoItem
