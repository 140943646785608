import { sha224, sha256 } from "js-sha256"
import { encode, decode } from "js-base64"
import Cookies from "universal-cookie"

const cookies = new Cookies()

export const encrypt = (value, type) => {
  switch (type) {
    case "sha224":
      return sha224(value)
    case "sha256":
      return sha256(value)
    case "base64":
      return encode(value)
    default:
      return sha224(value)
  }
}

export const decrypt = (value) => {
  return decode(value)
}

export const setSessionItem = (key, value, json = false) => {
  sessionStorage.setItem(encrypt(key), encrypt(json ? JSON.stringify(value) : value, "base64"))
}

export const getSessionItem = (key, json = false) => {
  const result = sessionStorage.getItem(encrypt(key))
  return result ? (json ? JSON.parse(decrypt(result)) : decrypt(result)) : null
}

export const removeSessionItem = (key) => {
  sessionStorage.removeItem(encrypt(key))
}

export const setLocalItem = (key, value, json = false) => {
  localStorage.setItem(encrypt(key), encrypt(json ? JSON.stringify(value) : value, "base64"))
}

export const getLocalItem = (key, json = false) => {
  const result = localStorage.getItem(encrypt(key))
  return result ? (json ? JSON.parse(decrypt(result)) : decrypt(result)) : null
}

export const removeLocalItem = (key) => {
  localStorage.removeItem(encrypt(key))
}

export const setCookie = async (name, value, maxAge = 3600 * 8) => {
  await cookies.set(encrypt(name), value, { path: "/", maxAge })
}

export const getCookie = async (name) => {
  return await cookies.get(encrypt(name))
}

export const removeCookie = async (name) => {
  return await cookies.remove(encrypt(name))
}
