import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import CFButton from "../../../components/CFButton/CFButton"
import { MapsCF } from "../../../components/MapsCF"

import CompanyContext from "../../../contexts/CompanyContext"

import { getLocalItem, setLocalItem } from "../../../helpers/StorageTools"
import { onMessageDialog } from "../../../helpers/GeneralTools"
import LoadingContext from "../../../contexts/LoadingContext"

import "./ModalMapAddress.scss"

const ModalMapAddress = ({ status, location, onClose, onConfirmLocation }) => {
  const { company } = React.useContext(CompanyContext)
  const { setLoading } = React.useContext(LoadingContext)

  const defaultCenterRef = React.useRef(
    company?.company_address?.length > 0
      ? { latitude: company.company_address[0].geo_coordinates[0], longitude: company.company_address[0].geo_coordinates[1] }
      : { latitude: -27.582736076873427, longitude: -48.61364576177011 }
  )
  const [initialView, setInitialView] = React.useState({
    latitude: defaultCenterRef.current?.latitude || 0,
    longitude: defaultCenterRef.current?.longitude || 0,
    zoom: 14,
  })

  const [mapLocation, setMapLocation] = React.useState({
    latitude: defaultCenterRef.current?.latitude || 0,
    longitude: defaultCenterRef.current?.longitude || 0,
    zoom: 10,
    width: "100vw",
    height: "100vh",
  })

  React.useEffect(() => {
    if (status) {
      if (!location?.latitude) {
        setLoading(true)
        const locationSession = getLocalItem("location", true)
        // console.log("locationSession", locationSession)

        if (!!locationSession) {
          // Definindo a localização padrão como a localização do cliente
          defaultCenterRef.current = { latitude: locationSession.latitude, longitude: locationSession.longitude }

          // Definindo a localização do mapa
          setMapLocation({ latitude: locationSession.latitude, longitude: locationSession.longitude })
          setInitialView({ longitude: defaultCenterRef.current?.longitude || 0, latitude: defaultCenterRef.current?.latitude || 0, zoom: 14 })
          setLoading(false)
        }
        // else {
        // console.log("geolocation")
        if ("geolocation" in navigator) {
          getCustomerLocation()
          // }
        }
        setLoading(false)
      }
    }
  }, [status])

  const getCustomerLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        // Definindo a localização padrão como a localização do cliente
        defaultCenterRef.current = { latitude: position.coords.latitude, longitude: position.coords.longitude }

        // Armazenando no localStrage a localização do cliente
        setLocalItem(
          "location",
          {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          true
        )

        // Definindo a localização do mapa
        setMapLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        setInitialView({ longitude: position.coords.longitude || 0, latitude: position.coords.latitude || 0, zoom: 14 })
        setLoading(false)
      },
      (error) => {
        if (error.code === 1) {
          // Definindo a localização do mapa
          setMapLocation({
            latitude: defaultCenterRef.current?.latitude || company?.company_address[0]?.geo_coordinates[0] || 0,
            longitude: defaultCenterRef.current?.longitude || company?.company_address[0]?.geo_coordinates[1] || 0,
          })
          setLocalItem(
            "location",
            {
              latitude: defaultCenterRef.current?.latitude || company?.company_address[0]?.geo_coordinates[0] || 0,
              longitude: defaultCenterRef.current?.longitude || company?.company_address[0]?.geo_coordinates[1] || 0,
            },
            true
          )

          onMessageDialog("error")
            .fire({
              title: "Erro ao obter a localização, permissão negada",
              icon: "error",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()

          setLoading(false)
        }
      }
    )
  }

  React.useEffect(() => {
    if (!!location && !!location.latitude && !!location.longitude) {
      // Definindo a localização do mapa
      setMapLocation({ latitude: location.latitude, longitude: location.longitude })
      setInitialView({ longitude: location.longitude, latitude: location.latitude, zoom: 14 })
    }
  }, [location])

  const onMoveMap = (mapChanged) => {
    setMapLocation({
      ...mapLocation,
      latitude: mapChanged.viewState.latitude,
      longitude: mapChanged.viewState.longitude,
      zoom: mapChanged.viewState.zoom,
    })
  }
  return (
    <Modal className="modal-map-address" isOpen={status} toggle={onClose} backdrop={"static"} fade={false} fullscreen={true} keyboard={false}>
      <ModalHeader className="modal-map-address--header">
        <div className="modal-map-address--header-label">
          <h2>Posicione o pino em cima do local de entrega</h2>
        </div>
      </ModalHeader>
      <ModalBody className="modal-map-address--body">
        <MapsCF initialViewState={initialView} onMoveMap={onMoveMap} mapLocation={mapLocation} />
      </ModalBody>
      <ModalFooter className="modal-map-address--footer">
        <CFButton title="Voltar" onClick={onClose} outline={true} />
        <CFButton title="Confirmar Localização" onClick={() => {
          console.log("mapLocation", mapLocation)
          onConfirmLocation(mapLocation, true)
        }} />
      </ModalFooter>
    </Modal>
  )
}

export default ModalMapAddress
