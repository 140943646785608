import React from "react"
//External Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import CFCheckButton from "../CFCheckButton/CFCheckButton"

//Contexts
import CompanyContext from "../../contexts/CompanyContext"

import "./DeliveryTypeSelectorTakeOut.scss"

const DeliveryTypeSelectorTakeOut = ({ orderToTakeOut, setOrderToTakeOut }) => {
  const { company } = React.useContext(CompanyContext)

  const companyGeolocation = React.useRef(null)

  const companyAddress = company?.company_address?.find(({ st_primary }) => st_primary === true)

  if (!!companyAddress) {
    companyGeolocation.current = {
      latitude: companyAddress.geo_coordinates[0],
      longitude: companyAddress.geo_coordinates[1],
    }
  }

  return (
    <div className="delivery-type-selector-take-out">
      {/*<div className="delivery-type-selector-take-out--header">*/}
      {/*  <span>Retirar no restaurante</span>*/}
      {/*  <CFCheckButton status={orderToTakeOut} onClick={(status) => setOrderToTakeOut(status)} />*/}
      {/*</div>*/}

      {!!companyAddress ? (
        <div className="delivery-type-selector-take-out--body">
          <div className="delivery-type-selector-take-out--body--left">
            <FontAwesomeIcon icon={"location-dot"} />
          </div>
          <div className="delivery-type-selector-take-out--body--center">
            <span className="delivery-type-selector-take-out--body--center--title">Retirar em</span>
            <span>
              {companyAddress?.street?.nm_street}, {companyAddress?.num_address} - {companyAddress?.street?.neighbourhood?.city?.nm_city}
            </span>
          </div>
          {!!companyGeolocation.current ? (
            <div
              className="delivery-type-selector-take-out--body--right"
              onClick={() => {
                window.open(
                  `http://www.google.com/maps/place/${companyGeolocation.current.latitude},${companyGeolocation.current.longitude}`,
                  "_blank",
                  "location=yes"
                )
              }}
            >
              <span>Abrir no mapa</span>
              <FontAwesomeIcon icon={"chevron-right"} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default DeliveryTypeSelectorTakeOut
