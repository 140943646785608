import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./CFCheckButton.scss"

const CFCheckButton = ({ status, onClick, radio = false }) => {
  const statusHandler = () => {
    let statusSelector = ""
    if (radio) {
      statusSelector = " selector-radio-button"
      if (status) statusSelector = statusSelector + " selector-radio-selected"
    } else {
      if (status) statusSelector = " selector-selected"
    }

    return statusSelector
  }

  return (
    <div onClick={() => onClick(!status)} className={"check-button" + statusHandler()}>
      {!radio && status ? <FontAwesomeIcon icon="check" /> : ""}
    </div>
  )
}

export default CFCheckButton
