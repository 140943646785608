import { useEffect, useState } from "react"
import { Label, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./CheckSelector.scss"

export default function CheckSelector({ onClick, selected }) {
  const checkButton = () => {
    onClick()
  }

  return (
    <Button color="none" className={`check-selector-button ${selected ? " check-selector-active" : ""}`} onClick={checkButton}>
      {selected ? <FontAwesomeIcon icon="check" /> : ""}
    </Button>
  )
}
