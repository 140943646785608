// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const BeverageController = {
  fetchAll() {
    const params = {
      orderField: "name",
      orderKeyword: "asc",
      page: 1,
      pageSize: 1000,
      stWebsite: true,
      withCompany: true,
      withCategory: true,
    }

    return API.request("/v1/beverage/all", params, "GET")
  },
  fetchAllMemory() {
    return API.request("/v1/beverage/memory/all", [], "GET")
  },
}

export default BeverageController
