import React from "react"
import { Card, CardBody, CardTitle, CardText, CardImg, Label } from "reactstrap"

// Styles
import "./Combos.scss"

// Resources
import { formatMoney, getUrlAWSManagerFile } from "../../helpers/GeneralTools"

// Imagens
import noImage from "../../assets/img/builder/no-image.png"

// Contexts
import SettingsContext from "../../contexts/SettingsContext"

const Combos = ({ onClickCombo }) => {
  const { combos } = React.useContext(SettingsContext)
  // console.log("combos", combos)
  if (combos.length > 0) {
    return (
      <section id="combos" className="section-combos">
        <div className="content-combos">
          <div className="combos-title">
            <label>Combos</label>
            <span>Nossos super combos</span>
          </div>
          <div className="combos-content">
            {combos.map((combo, key) => (
              <Card key={key} className="shadow-05 card-combo" onClick={() => onClickCombo("combo", { combo })}>
                <CardBody>
                  <strong className="card-title">{!!combo.lb_combo ? combo.lb_combo : combo.nm_combo}</strong>
                  <CardText className="card-description">{!!combo.ds_combo ? combo.ds_combo : "Sem descrição"}</CardText>
                  <div>
                    <span className="card-price">
                      {combo?.lb_val_price} {!!combo.val_price && combo.val_price > 0 ? formatMoney(combo.val_price) : ""}
                    </span>
                  </div>
                  {/* <CardText className="card-price">A partir de {formatMoney(val_sell_combo)}</CardText> */}
                </CardBody>
                <div className="card-preview-image">
                  {(() => {
                    if (!!combo.lb_tag_image) {
                      return (
                        <div className="flag-image">
                          <div
                            className="flag-image--body"
                            style={{
                              backgroundColor: combo.color_tag_image || "#F44336",
                              borderRightColor: combo.color_tag_image || "#F44336",
                            }}
                          >
                            <div>
                              <Label>{combo.lb_tag_image}</Label>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })()}
                  <CardImg alt="" width="100%" src={getUrlAWSManagerFile(combo.url_image, noImage)} />
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>
    )
  }

  return ""
}

export default Combos
