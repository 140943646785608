// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const CompanyController = {
  fetchAll(withModality = true, companyIds = []) {
    return API.request("/v1/company/all", { withModality, companyIds, st_status: true }, "GET")
  },

  fetchOne() {
    return API.request("/v1/company", [], "GET", {}, 60000)
  },

  fetchWorkingHours() {
    return API.request("/v1/company/working/hours", [], "GET")
  },
}

export default CompanyController
