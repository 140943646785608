import React from "react"

//External libs
import CurrencyInput from "react-currency-input"

//Contexts
import CompanyContext from "../../contexts/CompanyContext"

//Components
import PaymentCategoryItems from "./PaymentCategoryItems"
import ModalNewCard from "../../views/modals/ModalNewCard/ModalNewCard"
import CFButton from "../CFButton/CFButton"

//Tools
import GeneralTools, { formatBoolean, onMessageDialog } from "../../helpers/GeneralTools"
import { getDiscountOrder } from "../../helpers/OrderTools"
import { getCashbackValue } from "../../helpers/UserTools"

//Context
import SettingsContext from "../../contexts/SettingsContext"
import UserContext from "../../contexts/UserContext"
import OrderContext from "../../contexts/OrderContext"

//Styles
import "./PaymentTypeSelector.scss"
import CFInput from "../CFInput/CFInput"
import PaymentsController from "../../controllers/PaymentsController"
import MenuContext from "../../contexts/MenuContext"

const PaymentTypeSelector = ({
  onClose,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectPaymentOrder,
  paymentOnDelivery,
  onlinePayment,
  setPaymentOnDelivery,
  setOnlinePayment,
  scheduleOrder = false,
}) => {
  const { company } = React.useContext(CompanyContext)
  const { order } = React.useContext(OrderContext)
  const { userSelectedAddress, user } = React.useContext(UserContext)
  const [categoryPaymentMethods, setCategoryPaymentMethods] = React.useState([])
  const [showPaymentChange, setShowPaymentChange] = React.useState(false)
  const [paymentChange, setPaymentChange] = React.useState(0)
  const { setModalSignIn } = React.useContext(MenuContext)
  const { settings, modality } = React.useContext(SettingsContext)

  const [paymentType, setPaymentType] = React.useState(null)
  const [newCardModal, setNewCardModal] = React.useState({ status: false, title: "Adicionar novo cartão de crédito", content: null })
  const onChangePaymentMethod = (payment) => {
    setShowPaymentChange(`${payment.nm_payment_method}`.toLowerCase() === "dinheiro")
    setSelectedPaymentMethod(payment)
  }

  React.useEffect(() => {
    const { company_payment_categories = null, company_payment_methods = null } = company
    if (!!company_payment_methods) {
      const payments = company_payment_methods.map(
        ({ category_association, ds_key_payment_method, ds_key_type_payment_method, id_payment_method, nm_payment_method, tp_method, url_image }) => {
          return {
            ...category_association,
            ds_key_payment_method,
            ds_key_type_payment_method,
            id_payment_method,
            nm_payment_method,
            tp_method,
            url_image,
          }
        }
      )
      if (!!company_payment_categories) {
        const categories = company_payment_categories
          .filter(({ st_website }) => st_website)
          .map(({ id_category_payment_methods, nm_category_payment_methods, subcategory_payment_methods }) => {
            let subCategories = []

            if (subcategory_payment_methods.length > 0) {
              subCategories = subcategory_payment_methods
                .filter(({ st_website }) => st_website)
                .map((subcategory_payment) => {
                  const subCategoryPayments = payments.filter(
                    (payment) => subcategory_payment.id_subcategory_payment_methods === payment.fk_id_subcategory_method
                  )

                  return {
                    ...subcategory_payment,
                    subCategoryPayments,
                  }
                })
            } else if (subcategory_payment_methods.length === 0) {
              subCategories = [
                {
                  nm_subcategory_payment_methods: nm_category_payment_methods,
                  subCategoryPayments: payments.filter(({ fk_id_category_method }) => fk_id_category_method === id_category_payment_methods),
                },
              ]
            }

            return {
              id_category_payment_methods,
              nm_category_payment_methods,
              subCategories,
            }
          })

        setCategoryPaymentMethods(categories)
      }
    }

    if (formatBoolean(settings.enable_online_payments) && !scheduleOrder) {
      setPaymentOnDelivery(false)
      setOnlinePayment(true)
    }
  }, [company])

  return (
    <div className="payment-type-selector">
      <div className="payment-type-selector--header">
        {!scheduleOrder && formatBoolean(settings.enable_online_payments) && (
          <button
            className={"payment-type-selector--header--left" + (onlinePayment ? " payment-type-selected" : "")}
            onClick={() => {
              setPaymentOnDelivery(false)
              setOnlinePayment(true)
            }}
          >
            <span>PAGUE PELO SITE</span>
          </button>
        )}

        <button
          className={"payment-type-selector--header--right" + (paymentOnDelivery ? " payment-type-selected" : "")}
          onClick={() => {
            setPaymentOnDelivery(true)
            setOnlinePayment(false)
          }}
        >
          <span>PAGUE NA ENTREGA</span>
        </button>
      </div>
      {paymentOnDelivery && (
        <div className="payment-type-selector--body right">
          {categoryPaymentMethods.map((categoryPayment, index) => {
            // console.log("categoryPayment", categoryPayment)
            return (
              <PaymentCategoryItems
                key={index + "_payment_category"}
                index={index}
                data={categoryPayment}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={onChangePaymentMethod}
                showPaymentChange={showPaymentChange}
                setShowPaymentChange={setShowPaymentChange}
                paymentChange={paymentChange}
                setPaymentChange={setPaymentChange}
              />
            )
          })}
        </div>
      )}
      {onlinePayment && (
        <div className="payment-type-selector--body left">
          {categoryPaymentMethods.map((categoryPayment, index) => {
            return (
              <PaymentCategoryItems
                key={index + "_payment_category"}
                index={index}
                data={categoryPayment}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={onChangePaymentMethod}
                showPaymentChange={showPaymentChange}
                setShowPaymentChange={setShowPaymentChange}
                paymentChange={paymentChange}
                setPaymentChange={setPaymentChange}
                onlinePayment={onlinePayment}
                setPaymentType={setPaymentType}
              />
            )
          })}
        </div>
      )}
      <div className="payment-type-selector--footer">
        <CFButton title="Voltar" onClick={onClose} outline={true} />
        <CFButton
          title="Escolher"
          onClick={() => {
            if (!!selectedPaymentMethod && selectedPaymentMethod?.nm_payment_method?.toLowerCase() === "dinheiro") {
              if (paymentChange === 0) {
                selectPaymentOrder(0)
                return null
              }

              if (
                paymentChange <
                order.subTotal +
                  (modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
                  getDiscountOrder({ ...order }, order.subTotal, modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0, settings, modality) -
                  (!!order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
                    ? order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback").val_payment
                    : 0)
              ) {
                onMessageDialog("warning").fire({
                  title: "Valor do pagamento menor que o valor do pedido",
                  icon: "warning",
                  timer: 4000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                return null
              } else {
                selectPaymentOrder(paymentChange)
              }
            } else if (selectedPaymentMethod.id_payment_method === -1) {
              onMessageDialog("warning").fire({
                title: "Escolha a forma de pagamento!",
                icon: "warning",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
              })
            } else {
              selectPaymentOrder(0)
            }
          }}
        />
      </div>

      <ModalNewCard
        title={newCardModal.title}
        status={newCardModal.status}
        onClose={() => setNewCardModal({ status: false, title: "Adicionar novo cartão de crédito", content: null })}
      />
    </div>
  )
}

export default PaymentTypeSelector
