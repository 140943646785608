import React from "react"

import { Label, Card, CardBody, CardImg, CardText, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

// Helpers
import GeneralTools, { formatBoolean, getUrlAWSManagerFile } from "../../helpers/GeneralTools"

//Components
import Message from "../../components/Message"

// Controllers
import SettingsContext from "../../contexts/SettingsContext"

// Styles
import noImage from "../../assets/img/builder/no-image.png"
import "./ModalBeverages.scss"
import CompanyContext from "../../contexts/CompanyContext"

const ModalBeverages = ({ title, status, content, onClose, onClick, category, data = null }) => {
  const { beverages } = React.useContext(SettingsContext)
  const { company } = React.useContext(CompanyContext)

  const [selectedBeverages, setSelectedBeverages] = React.useState([])
  const [categories, setCategories] = React.useState([])
  /* eslint-disable */
  React.useEffect(() => {
    if (!data && !!content && content.length > 0) {
      const filterBeveragesWithProduct = content.filter((beverage) => !beverage.id_product && !beverage.nro_item)
      setSelectedBeverages(filterBeveragesWithProduct)
    } else {
      setSelectedBeverages([])
    }

    setCategories(getCategories(beverages))
  }, [content])

  React.useEffect(() => {
    if (!content && !!data) {
      const {
        selectedItem: {
          size: { beverages },
        },
      } = data
      setCategories(getCategories(beverages))
    }
  }, [data])

  const onSelectedBeverage = (beverage) => {
    const index = selectedBeverages.findIndex(({ id_beverage }) => id_beverage === beverage.id_beverage)

    if (index !== -1) {
      selectedBeverages.splice(index, 1)
      setSelectedBeverages([...selectedBeverages])
    } else if (!data) {
      setSelectedBeverages([...selectedBeverages, { ...beverage, amount: 1 }])
    } else if (selectedBeverages.length < data.maxBeverages) {
      const { selectedItem } = data
      setSelectedBeverages([
        ...selectedBeverages,
        {
          ...beverage,
          amount: 1,
          id_product: selectedItem.id_product,
          val_sale: "0",
        },
      ])
    } else if (data.maxBeverages === 1 && selectedBeverages.length === data.maxBeverages) {
      const { selectedItem } = data
      selectedBeverages.splice(0, 1)
      setSelectedBeverages([
        ...selectedBeverages,
        {
          ...beverage,
          amount: 1,
          id_product: selectedItem.id_product,
          val_sale: "0",
        },
      ])
    } else {
      Message.info("Número máximo de bebida atingido")
    }
  }

  const handleOnClose = () => {
    setSelectedBeverages([])
    onClose()
  }

  const handleOnClick = () => {
    if (!data) {
      onClick(selectedBeverages)
      setSelectedBeverages([])
    } else if (data.isMandatory) {
      if (selectedBeverages.length !== 0) {
        const order = {
          products: data.selectedItem,
          beverages: selectedBeverages,
        }
        onClick(selectedBeverages, order)
        setSelectedBeverages([])
      } else {
        Message.info("Faltou selecionar a bebida!")
      }
    } else {
      const newBeverages = selectedBeverages.map((beverage) => {
        return { ...beverage, amount: data.selectedItem.amount }
      })
      const order = {
        products: data.selectedItem,
        beverages: newBeverages,
      }
      onClick(selectedBeverages, order)
      setSelectedBeverages([])
    }
  }

  const getCategories = (beverages) => {
    let categories = []
    let noCategories = []

    for (const beverage of beverages) {
      const { category } = beverage

      if (!!category) {
        const categoryIndex = categories.findIndex(({ id_category }) => id_category === category.id_category)

        if (categoryIndex !== -1) {
          categories[categoryIndex].beverages.push(beverage)
        } else {
          categories = [...categories, { ...category, beverages: [beverage] }]
        }
      } else {
        if (noCategories.length > 0) {
          noCategories[0].beverages.push(beverage)
        } else {
          noCategories.push({ ds_category: "Diversas", beverages: [beverage] })
        }
      }
    }

    return [...categories, ...noCategories]
  }

  const getBeveragePrice = (beverage) => {
    let beveragePrice = 0

    if (!!beverage.beverage_size) {
      beveragePrice = beverage.beverage_size.val_price
    } else {
      beveragePrice = beverage.val_price
    }

    if (beveragePrice > 0) {
      return GeneralTools.formatMoney(beveragePrice)
    } else {
      return "Grátis"
    }
  }

  const getBeverageImage = (beverage) => {
    if (!!beverage.url_image) {
      return getUrlAWSManagerFile(beverage.url_image, noImage)
    } else {
      const beverageImage = beverages.find(({ uuid_beverage }) => uuid_beverage === beverage.uuid_beverage)
      let url_image = ""
      if (beverageImage !== undefined) {
        url_image = beverages
      }
      return getUrlAWSManagerFile(url_image, noImage)
    }
  }

  return (
    <Modal isOpen={status} toggle={handleOnClose} className={"modal-beverages"}>
      <ModalHeader toggle={handleOnClose}>{!!category ? category.ds_category : title}</ModalHeader>
      <ModalBody>
        {(() => {
          // Verificando se já foi informada categoria
          if (!!category) {
            if (beverages.length > 0) {
              return category.beverages.map((beverage, key) => (
                <Card
                  key={key}
                  className={
                    "shadow-05 card-beverage " +
                    (selectedBeverages.find(({ id_beverage }) => id_beverage === beverage.id_beverage) ? "beverage-selected" : "")
                  }
                  onClick={() => onSelectedBeverage(beverage)}
                >
                  <CardBody>
                    <CardTitle>{!!beverage.lb_beverage ? beverage.lb_beverage : beverage.nm_beverage}</CardTitle>
                    <CardText className="card-description">{!!beverage.ds_beverage ? beverage.ds_beverage : "Sem descrição"}</CardText>
                    <CardText className="card-price">{getBeveragePrice(beverage)}</CardText>
                  </CardBody>
                  <div className="card-preview-image">
                    {(() => {
                      if (!!beverage.lb_tag_image) {
                        return (
                          <div className="flag-image">
                            <div
                              className="flag-image--body"
                              style={{
                                backgroundColor: beverage.color_tag_image || "#F44336",
                                borderRightColor: beverage.color_tag_image || "#F44336",
                              }}
                            >
                              <div>
                                <Label>{beverage.lb_tag_image}</Label>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })()}
                    <CardImg alt="" width="100%" src={getBeverageImage(beverage)} />
                  </div>
                  {/*<CardImg alt="" width="100%" src={getBeverageImage(beverage)} />*/}
                </Card>
              ))
            } else {
              return <label>Sem bebidas</label>
            }
          } else {
            if (categories.length > 0) {
              return categories.map(({ ds_category, beverages }, key) => (
                <div key={key} className="card-category">
                  <Label>{ds_category}</Label>
                  <div className="card-category-content">
                    {beverages.map((beverage, key) => (
                      <Card
                        key={key}
                        className={
                          "shadow-05 card-beverage " +
                          (selectedBeverages.find(({ id_beverage }) => id_beverage === beverage.id_beverage) ? "beverage-selected" : "")
                        }
                        onClick={() => onSelectedBeverage(beverage)}
                      >
                        <CardBody>
                          <CardTitle>{beverage.nm_beverage}</CardTitle>
                          <CardText className="card-description">{beverage.ds_about || "Sem descrição"}</CardText>
                          <CardText className="card-price">{getBeveragePrice(beverage)}</CardText>
                        </CardBody>
                        <CardImg alt="" width="100%" src={getBeverageImage(beverage)} />
                      </Card>
                    ))}
                  </div>
                </div>
              ))
            } else {
              return <label>Sem bebidas</label>
            }
          }
        })()}
      </ModalBody>
      <ModalFooter>
        <Label
          onClick={formatBoolean(company.st_website) || formatBoolean(company.st_schedule) ? () => handleOnClick() : () => {}}
          className="footer-button-right"
        >
          {formatBoolean(company.st_website) || formatBoolean(company.st_schedule) ? "Adicionar a sacola" : "Estamos fechados"}
        </Label>
      </ModalFooter>
    </Modal>
  )
}

export default ModalBeverages
