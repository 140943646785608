import React from "react"
import moment from "moment/moment"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Componentes
import CFButton from "../../../components/CFButton/CFButton"
import DeliveryInfo from "../../../components/DeliveryInfo/DeliveryInfo"
import ScheduleDateSelector from "../../../components/ScheduleDateSelector/ScheduleDateSelector"
import PaymentInfo from "../../../components/PaymentInfo/PaymentInfo"
import PaymentTypeSelector from "../../../components/PaymentInfo/PaymentTypeSelector"

//Contexts
import UserContext from "../../../contexts/UserContext"
import MenuContext from "../../../contexts/MenuContext"
import OrderContext from "../../../contexts/OrderContext"
import SettingsContext from "../../../contexts/SettingsContext"

//Helpers
import { getLocalUser } from "../../../helpers/UserTools"
import { getLocalItem } from "../../../helpers/StorageTools"
import { onMessageDialog } from "../../../helpers/GeneralTools"

// Controllers
import ScheduleController from "../../../controllers/ScheduleController"

import "./ModalOrderSchedule.scss"

export default function ModalOrderSchedule({ status, onConfirmOrder, onClose }) {
  const dateNow = new Date()
  const dateStartEnd = new Date()
  dateStartEnd.setDate(dateStartEnd.getDate() + 7)

  const weekdayDefault = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]

  const { modality } = React.useContext(SettingsContext)
  const { setModalSignIn } = React.useContext(MenuContext)
  const { order, setOrder } = React.useContext(OrderContext)
  const { userSelectedAddress } = React.useContext(UserContext)

  const [loadingSchedule, setLoadingSchedule] = React.useState(false)
  const [paymentOnDelivery, setPaymentOnDelivery] = React.useState(true)
  const [showDeliveryTypeSelector, setShowDeliveryTypeSelector] = React.useState(false)
  const [onlinePayment, setOnlinePayment] = React.useState(false)

  const [showPaymentTypeSelector, setShowPaymentTypeSelector] = React.useState(false)

  const [schedules, setSchedules] = React.useState([])
  const [orderSchedules, setOrderSchedules] = React.useState([])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState({ id_payment_method: -1 })
  const [scheduleOptionsSelected, setScheduleOptionsSelected] = React.useState({ scheduleData: {}, workingTime: {} })

  React.useEffect(() => {
    if (status) {
      setLoadingSchedule(true)

      // Obtendo dados das bebidas amazenado no storage
      const schedulesSession = getLocalItem("schedules", true)

      if (!!schedulesSession) {
        const scheduleList = schedulesSession.map(({ uuid_schedule, day_of_week, st_open, work_times, neighbourhoods }) => {
          return {
            uuid_schedule,
            day_of_week,
            st_open,
            work_times,
            neighbourhoods,
            name: weekdayDefault[day_of_week],
          }
        })
        setSchedules(scheduleList)
        setLoadingSchedule(false)
      } else {
        ScheduleController.fetchAll()
          .then((responseSchedule) => {
            if (responseSchedule.status === 200) {
              const scheduleList = responseSchedule.data.map(({ uuid_schedule, day_of_week, st_open, work_times, neighbourhoods }) => {
                return {
                  uuid_schedule,
                  day_of_week,
                  st_open,
                  work_times,
                  neighbourhoods,
                  name: weekdayDefault[day_of_week],
                }
              })

              getScheduleOrders().then((scheduleOrders) => {
                setOrderSchedules(scheduleOrders)
                setSchedules(scheduleList)
                setLoadingSchedule(false)
              })
            } else {
              setSchedules([])
              setLoadingSchedule(false)
              onMessageDialog("danger")
                .fire({
                  title: "Erro ao buscar dados de agendamento",
                  icon: "error",
                  showConfirmButton: true,
                  confirmButtonText: "Aceitar",
                  showCancelButton: true,
                  cancelButtonText: "Recusar",
                  reverseButtons: true,
                })
                .then()
            }
          })
          .catch(() => {
            setSchedules([])
            setLoadingSchedule(false)
            onMessageDialog("danger")
              .fire({
                title: "Erro ao buscar dados de agendamento",
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "Aceitar",
                showCancelButton: true,
                cancelButtonText: "Recusar",
                reverseButtons: true,
              })
              .then()
          })
      }
    }
  }, [status])

  const selectPaymentOrder = (payment, paymentChange) => {
    setOrder({
      ...order,
      payments: [
        {
          ...selectedPaymentMethod,
          val_receivable: paymentChange,
          val_payment: order.subTotal + userSelectedAddress.deliveryFee,
        },
      ],
    })
    setShowPaymentTypeSelector(false)
  }

  const handleConfirmSchedule = () => {
    const scheduleData = {
      formatedDate: scheduleOptionsSelected?.scheduleData?.date,
      hr_end: scheduleOptionsSelected?.workingTime?.hr_end,
      hr_start: scheduleOptionsSelected?.workingTime?.hr_start,
    }

    const localStorageUser = getLocalUser()
    //Verificando se foi selecionada data e horário
    if (!scheduleData?.formatedDate || !scheduleData?.hr_end || !scheduleData?.hr_start) {
      onMessageDialog("delete")
        .fire({
          title: `Para realizar o agendamento, é preciso selecionar um dia e horário!`,
          icon: "info",
          timer: 3000,
          showConfirmButton: true,
          confirmButtonText: "Entendi",
        })
        .then()
      return null
    }

    //Verificando se usuário está logado
    if (!localStorageUser) {
      onMessageDialog("delete")
        .fire({
          title: `Para realizar o agendamento, é preciso estar logado!`,
          icon: "info",
          timer: 3000,
          showConfirmButton: true,
          confirmButtonText: "Entendi",
        })
        .then(() => {
          setModalSignIn({ status: true })
        })
      return null
    }

    //Verificando se foi selecionado endereço e está com uma rua válida
    if (modality === 1 && !userSelectedAddress) {
      onMessageDialog("delete")
        .fire({
          title: "Falha no endereço, favor selecionar o endereço novamente.",
          icon: "info",
          timer: 3000,
          showConfirmButton: true,
          confirmButtonText: "Entendi",
        })
        .then()
      return null
    }

    //Verificando se foi selecionada modalidade
    if (modality === 0) {
      onMessageDialog("delete")
        .fire({
          title: "Favor escolha se deseja entrega ou retirada do pedido.",
          icon: "info",
          timer: 3000,
          showConfirmButton: true,
          confirmButtonText: "Entendi",
        })
        .then()
      return null
    }

    //Verificando se foi selecionada forma de pagamento
    if (order?.payments.length < 1) {
      onMessageDialog("delete")
        .fire({
          title: "Favor escolha a forma de pagamento!",
          icon: "info",
          timer: 3000,
          showConfirmButton: true,
          confirmButtonText: "Entendi",
        })
        .then()
      return null
    }

    onConfirmOrder(scheduleData)
  }

  const getScheduleOrders = async () => {
    const responseScheduleOrders = await ScheduleController.fetchAllScheduleOrders({
      startDate: moment(dateNow).format("YYYY-MM-DD"),
      endDate: moment(dateStartEnd).format("YYYY-MM-DD"),
    })

    if (responseScheduleOrders.status === 200) {
      return responseScheduleOrders.data.rows || []
    }

    onMessageDialog("danger")
      .fire({
        title: "Erro ao buscar dados dos agendamentos do estabelecimento",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "Aceitar",
        showCancelButton: true,
        cancelButtonText: "Recusar",
        reverseButtons: true,
      })
      .then()

    return []
  }

  return (
    <Modal isOpen={status} toggle={onClose} backdrop={"static"} fade={false} className="modal-order-schedule ">
      {showPaymentTypeSelector ? (
        <PaymentTypeSelector
          paymentOnDelivery={paymentOnDelivery}
          setPaymentOnDelivery={setPaymentOnDelivery}
          selectPaymentOrder={selectPaymentOrder}
          onClose={() => setShowPaymentTypeSelector(false)}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setOnlinePayment={setOnlinePayment}
          onlinePayment={false} // Fixado false, pois ainda não há rotina
          scheduleOrder={true}
        />
      ) : (
        <>
          <ModalHeader className="modal-order-schedule--header">
            <FontAwesomeIcon icon={"chevron-down"} onClick={onClose} />
            <span>AGENDAR PEDIDO</span>
          </ModalHeader>
          <ModalBody className="modal-order-schedule--body">
            {/* Escolha do dia e dos próximos 7 dias sequentes. */}
            <div className="modal-order-schedule--body--date-selector">
              <ScheduleDateSelector
                schedules={schedules}
                orderSchedules={orderSchedules}
                loadingSchedule={loadingSchedule}
                setScheduleOptionsSelected={setScheduleOptionsSelected}
                scheduleOptionsSelected={scheduleOptionsSelected}
              />
            </div>
            {/* Escolha do endereço para entrega/retirada */}
            <div className="modal-order-schedule--body--delivery-info">
              <DeliveryInfo />
            </div>
            {/* Escolha da forma de pagamento */}
            <div className="modal-order-schedule--body--payment-info">
              <PaymentInfo
                paymentOnDelivery={paymentOnDelivery}
                handlePaymentTypeSelector={() => setShowPaymentTypeSelector(!showPaymentTypeSelector)}
              />
            </div>
          </ModalBody>
          <ModalFooter className="modal-order-schedule--footer">
            <div className="modal-order-schedule--footer--cancel">
              <CFButton title="Voltar" onClick={onClose} outline={true} />
            </div>
            <div className="modal-order-schedule--footer--confirm">
              <CFButton title="Confirmar" onClick={handleConfirmSchedule} />
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
