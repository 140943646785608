import React from "react"
import { Label } from "reactstrap"

// Helpers
import { formatMoney } from "../../helpers/GeneralTools"

import "./OrderTotalInfo.scss"

const OrderTotalInfo = ({
  total = 0,
  change = 0,
  totalBenefits = 0,
  deliveryFee = 0,
  additionalFee = 0,
  totalDiscount = 0,
  totalPayment = 0,
  cashbackPayment = 0,
  discountTypeOrder,
  discountOrder,
  valSubTotal = null,
}) => {
  return (
    <div className="order-total-info">
      <div>
        <Label>Total</Label>
        <Label>{formatMoney(total)}</Label>
      </div>
      <div>
        <Label>Taxa de entrega</Label>
        <Label>{formatMoney(deliveryFee)}</Label>
      </div>
      {/*CUPONS DE DESCONTO*/}
      {(() => {
        if (totalBenefits) {
          return (
            <div>
              <Label>Cupons de Desconto</Label>
              <Label>{formatMoney(totalBenefits)}</Label>
            </div>
          )
        }
      })()}
      {/*DESCONTO*/}
      {(() => {
        if (totalDiscount) {
          return (
            <div>
              <Label>{`Desconto ${discountTypeOrder === "%" ? `(${discountOrder} ${discountTypeOrder})` : ""}`}</Label>
              <Label className={"order-total-info-discount"}>
                {discountTypeOrder === "R$" ? formatMoney(totalDiscount) : `${formatMoney(totalDiscount)}`}
              </Label>
            </div>
          )
        }
      })()}
      {/*CASHBACK*/}
      {(() => {})()}

      {/*VALOR PAGO*/}
      {(() => {
        if (change) {
          return (
            <div>
              <Label>Valor pago</Label>
              <Label>{formatMoney(change)}</Label>
            </div>
          )
        }
      })()}
      {/*TROCO*/}
      {(() => {
        if (change) {
          return (
            <div>
              <Label>Troco</Label>
              <Label>
                {formatMoney(change - (total + deliveryFee + additionalFee - totalBenefits - totalDiscount - cashbackPayment), "R$ ", false)}
              </Label>
            </div>
          )
        }
      })()}
      {(() => {
        if (cashbackPayment) {
          return (
            <div className={"order-total-info--cashback"}>
              <div className={"order-total-info--cashback--use-cashback"}>
                <Label>Cashback</Label>
              </div>

              <Label>- {formatMoney(cashbackPayment, "R$ ", false)}</Label>
            </div>
          )
        }
      })()}
      {/*PAGAMENTO*/}
      {/*{(() => {*/}
      {/*  if (cashback) {*/}
      {/*    return (*/}
      {/*      <div className={"order-total-info--cashback"}>*/}
      {/*        <div className={"order-total-info--cashback--use-cashback"}>*/}
      {/*          <Label>Saldo de Cashback</Label>*/}
      {/*          <input type="checkbox" />*/}
      {/*          <p>usar saldo</p>*/}
      {/*        </div>*/}

      {/*        <Label>{formatMoney(cashback, "R$ ", false)}</Label>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  }*/}
      {/*})()}*/}

      {/*PAGAMENTO*/}
      {(() => {
        if (totalPayment) {
          return (
            <div className={"order-total-info--cashback"}>
              <div className={"order-total-info--cashback--use-cashback"}>
                <Label>Pagamentos</Label>
              </div>

              <Label>{formatMoney(totalPayment, "R$ ", false)}</Label>
            </div>
          )
        }
      })()}

      <div>
        <Label>
          <span>Subtotal</span>
        </Label>
        <Label>
          <span>
            {formatMoney(!!valSubTotal ? valSubTotal : total + deliveryFee + additionalFee - totalBenefits - totalDiscount - cashbackPayment)}
          </span>
        </Label>
      </div>
    </div>
  )
}

export default OrderTotalInfo
