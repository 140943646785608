import slug from "slug"
import axios from "axios"
import Message from "../components/Message"
import AddressController from "../controllers/AddressController"
import GeneralTools, { escapeSpecialChart, onMessageDialog } from "./GeneralTools"
import { getSessionItem, setSessionItem } from "./StorageTools"
import React from "react"

// Método para obter os tipos de endereço
export const getStreetTypes = async () => {
  let streetTypes = []
  try {
    const response = await AddressController.streeTypes()

    if (response.status === 200) {
      streetTypes = response.data
    }
  } catch (error) {
    console.error("Erro ao obter tipos de endereços", error)
  }

  return streetTypes
}

// Método para identificar o tipo da rua
export const getStreetType = (streetTypes, street) => {
  const street_type = streetTypes.find((item) => !!item.nm_type && `${street}`.toLowerCase().indexOf(`${item.nm_type}`.toLowerCase()) !== -1)
  return !!street_type ? [street_type.id_street_type, street_type.nm_type] : [3, "Rua"]
}

// Método para obter os estados
export const getStates = async () => {
  let states = []
  try {
    let response = await AddressController.states()

    if (response.status === 200) {
      states = response.data
    }
  } catch (e) {
    Message.error("Ocorreu um erro ao consultar os estados (nc-gs-01). Favor tente mais tarde!")
  }
  return states
}

// Método para obter o estado
export const getState = (states, stateName) => {
  // const stateName = result.state

  if (!!stateName) {
    const stateFound = states.find(({ nm_state }) => nm_state === stateName)
    if (!!stateFound) {
      return [stateFound.id_state, stateFound.uuid_state, stateName, stateName]
    }

    return [null, null, stateName, stateName]
  }

  return [null, null, "", ""]
}

// Método para obter os CEP
export const getZipCode = (result) => {
  const zipcode = result.address_components.find(({ types }) => types.includes("postal_code"))
  return !!zipcode ? zipcode.long_name : null
}

// Método para formatar o nome da rua sem o tipo
export const getStreetNameFormatted = (street_name, street_type_name) => {
  // Procurando nome do tipo de logradouro dentro do nome da logradouro
  return `${street_name}`
    .toLowerCase()
    .replace(`${street_type_name}`.toLowerCase(), "")
    .trim()
    .replace(/(?:^|\s)\S/g, function (value) {
      return `${value}`.toUpperCase()
    })
}

// Método para pegar o nome formatado da rua
export const getStreetName = (streetTypes, streetName) => {
  // const streetName = result.street

  const [id_street_type, nm_street_type] = getStreetType(streetTypes, streetName)

  return [getStreetNameFormatted(streetName, nm_street_type), id_street_type, nm_street_type]
}

// Método para pegar o número da rua
export const getNumberAddress = (fullStreet) => {
  const street = fullStreet.split(",")[0]
  const match = street.match(/\b\d+\b/)
  return match ? match[0] : null
}

// Método para obter o bairro
export const getNeighbourhood = async (neighbourhoodName, cityUid) => {
  // const neighbourhoodName = result.neighbourhood

  const responseNeighbourhood = await AddressController.neighbourhoodByCity(cityUid)
  const neighbourhoods = responseNeighbourhood.status === 200 ? responseNeighbourhood.data.filter(({ nm_neighbourhood }) => nm_neighbourhood) : []

  if (!!neighbourhoodName) {
    const neighbourhoodFound = neighbourhoods.find(({ nm_slug }) => `${nm_slug}`.toLowerCase() === `${slug(neighbourhoodName)}`.toLowerCase())

    if (!!neighbourhoodFound) {
      return [neighbourhoodFound.id_neighbourhood, neighbourhoodFound.uuid_neighbourhood, neighbourhoodName, neighbourhoods, responseNeighbourhood]
    }

    return [null, null, neighbourhoodName, neighbourhoods, responseNeighbourhood]
  }

  return [null, null, "", neighbourhoods, responseNeighbourhood]
}

// Método para obter a cidade
export const getCity = async (cityName, uuid_state) => {
  if (!!cityName) {
    const responseCity = await AddressController.cityByName(cityName, uuid_state)
    if (responseCity.status === 200) {
      const { id_city, uuid_city } = responseCity.data
      return [id_city, uuid_city, cityName]
    } else {
      return [null, null, cityName]
    }
  }
  return [null, null, null]
}

// Método para obter o número da rua
export const getStreetNumber = (result) => {
  const number = result.address_components.find(({ types }) => types.includes("street_number"))
  return !!number ? number.long_name : ""
}

//Método para validar se os dados obrigatórios estão preenchidos
export const validateSelectedAddress = (selectedAddress) => {
  const {
    nm_street,
    neighbourhood: {
      nm_neighbourhood,
      city: {
        nm_city,
        state: { ds_acronym },
      },
    },
    customer_address: { ds_address_additional_info: complement, num_address },
  } = selectedAddress

  if (!nm_street) {
    GeneralTools.swalMessage("warning")
      .fire({
        title: "Preencha o campo logradouro.",
        icon: "warning",
        timer: 3500,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: true,
      })
      .then()

    return false
  }

  if (!nm_neighbourhood) {
    GeneralTools.swalMessage("warning")
      .fire({
        title: "Preencha o campo bairro.",
        icon: "warning",
        timer: 3500,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: true,
      })
      .then()

    return false
  }

  if (!nm_city || !ds_acronym) {
    GeneralTools.swalMessage("warning")
      .fire({
        title: "Volte para a etapa anterior para selecionar sua cidade e estado.",
        icon: "warning",
        timer: 4000,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: true,
      })
      .then()
    return false
  }

  if (`${num_address}`.trim().length === 0) {
    if (!complement || complement.trim().length === 0) {
      GeneralTools.swalMessage("info")
        .fire({
          title: `Endereço sem número, favor informar um complemento!`,
          icon: "info",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
      return false
    }
  }

  return true
}

export const getLabelAddress = (address, filter = {}) => {
  /*
  {
                zipcode,
                id_street: null,
                nm_street,
                street_type: { id_street_type, nm_type },
                neighbourhood: {
                  id_neighbourhood,
                  uuid_neighbourhood,
                  nm_neighbourhood,
                  city: { id_city, uuid_city, nm_city, state: { id_state, uuid_state, nm_state, ds_acronym } },
                },
                customer_address: {
                  geo_coordinates: [location.lat, location.lng],
                  num_address,
                  ds_address_additional_info: "",
                  ds_observation: "",
                  st_primary: true,
                  ds_title: "",
                },
              }
   */

  if (Object.keys(address).length > 0) {
    const {
      nm_street,
      street_type,
      neighbourhood: {
        nm_neighbourhood,
        city: {
          nm_city,
          state: { ds_acronym },
        },
      },
      customer_address: { ds_address_additional_info: complement, ds_observation: reference, num_address },
    } = address

    const { withNumber = false, withNeighbourhood = true, withCity = true, withState = true, withComplement = false, withReference = false } = filter

    let labelAddress = `${street_type?.nm_type || "Rua"} ${nm_street || "Não identificada"}`

    if (withNumber) {
      labelAddress += `, ${num_address || "S/N"}`
    }

    if (withNeighbourhood) {
      labelAddress += `, ${nm_neighbourhood || "Sem bairro"}`
    }

    if (withComplement) {
      if (withReference) {
        labelAddress += complement ? `(${complement} - ${reference || ""})` : ""
      } else {
        labelAddress += complement ? `(${complement})` : ""
      }
    }

    if (withCity) {
      labelAddress += nm_city ? ` - ${nm_city}` : ""
    }

    if (withState) {
      labelAddress += ds_acronym ? ` - ${ds_acronym}` : ""
    }

    return labelAddress
  }

  return ""
}

//Método para buscar a distância entre o estabelecimento e o endereço selecionado

export const getDistanceCoordinates = (arg1, arg2) => {
  let p = 0.017453292519943295
  let c = Math.cos
  let a =
    0.5 -
    c((arg2.latitude - arg1.latitude) * p) / 2 +
    (c(arg1.latitude * p) * c(arg2.latitude * p) * (1 - c((arg2.longitude - arg1.longitude) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(a))
}

export const getCitiesByState = async (uuid_state) => {
  let cities = []
  try {
    let response = await AddressController.citiesByState(uuid_state)

    if (response.status === 200) {
      cities = response.data.rows
    }
  } catch (e) {
    Message.error("Ocorreu um erro ao consultar as cidades (nc-gs-01). Favor tente mais tarde!")
  }
  return cities
}

export const getAddressDefailsByCoords = async (longitude, latitude) => {
  // const locationInfo = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`, {
  //   params: {
  //     access_token: process.env.REACT_APP_MAPBOX_KEY,
  //   },
  // })

  const locationInfo = await axios.get(`https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}`, {
    params: {
      access_token: process.env.REACT_APP_MAPBOX_KEY,
    },
  })

  if (locationInfo.status === 200) {
    const addressContexts = locationInfo?.data?.features[0]?.properties?.context
    // const contextsLength = addressContexts.length
    // const zipcode = escapeSpecialChart(addressContexts[1].text)
    const zipcode = escapeSpecialChart(addressContexts?.postcode?.name || "")
    // console.log("zipcode", zipcode)
    // const number = locationInfo?.data?.features[0]?.address
    const number = addressContexts?.address?.address_number
    let streetData = {
      // street: locationInfo?.data?.features[0]?.text,
      street: addressContexts?.street?.name || "",
      neighbourhood: addressContexts?.neighborhood?.name || addressContexts?.place?.name,
      zipcode: zipcode.length === 8 ? zipcode : zipcode + "000",
      // city: addressContexts[contextsLength === 5 ? 2 : 3]?.text,
      city: addressContexts?.place?.name,
      // state: addressContexts[contextsLength === 5 ? 3 : 2]?.text,
      state: addressContexts?.region?.name,
      coordinates: { latitude, longitude },
      number,
    }

    if (streetData.zipcode.length !== 8) {
      console.error("Falha ao identificar CEP do endereço, CEP encontrado:", streetData.zipcode)
      streetData.zipcode = null
      // return false
    }

    if (streetData.street.trim().length === 0) {
      console.error("Falha ao identificar LOGRADOURO do endereço, LOGRADOURO encontrado:", streetData.street)
      return false
    }
    // console.log("streetData", streetData)
    return streetData
  } else {
    return false
  }
}

export const getAddressSuggestionsByName = async (placemap, companyAddressCordinates = null) => {
  let suggestions = []
  try {
    //Buscando o token de sessão do mapbox
    let sessionToken = getSessionItem("sessionMapToken", false)
    // console.log("sessionToken", sessionToken)

    if (!sessionToken) {
      sessionToken = crypto.randomUUID()
      setSessionItem("sessionMapToken", sessionToken, false)
    }

    let defaultCenter = { latitude: -27.582736076873427, longitude: -48.61364576177011 }

    if (!!companyAddressCordinates && companyAddressCordinates.length > 0) {
      defaultCenter = {
        latitude: companyAddressCordinates[0],
        longitude: companyAddressCordinates[1],
      }
    }

    const suggestionsInfo = await axios.get(
      `https://api.mapbox.com/search/searchbox/v1/suggest?q=${placemap}&proximity=${defaultCenter.longitude},${defaultCenter.latitude}&access_token=${process.env.REACT_APP_MAPBOX_KEY}&session_token=${sessionToken}`
    )

    if (suggestionsInfo.status === 200) {
      // console.log("suggestionsInfo.data.suggestions", suggestionsInfo.data.suggestions)
      for (const suggestion of suggestionsInfo.data.suggestions) {
        suggestions.push({
          street: suggestion?.context?.street?.name,
          neighbourhood: suggestion?.context?.neighborhood?.name,
          zipcode: suggestion?.context?.postcode?.name,
          city: suggestion?.context?.place?.name,
          state: suggestion?.context?.region?.name,
          coordinates: null,
          number: suggestion?.context?.address?.address_number,
          mapbox_id: suggestion.mapbox_id,
        })
      }
      return suggestions
    } else {
      return suggestions
    }
  } catch (error) {
    console.error("Falha ao consultar sugestões de endereço", error)
    return suggestions
  }
}

export const getAddressCoordinates = async (placeId) => {
  try {
    //Buscando o token de sessão do mapbox
    let sessionToken = getSessionItem("sessionMapToken", false)
    if (!sessionToken) {
      sessionToken = crypto.randomUUID()
      setSessionItem("sessionMapToken", sessionToken, false)
    }
    const coordinatesInfo = await axios.get(
      `https://api.mapbox.com/search/searchbox/v1/retrieve/${placeId}?session_token=${sessionToken}&access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
      {}
    )
    if (coordinatesInfo.status === 200) {
      return {
        latitude: coordinatesInfo?.data?.features[0]?.geometry?.coordinates[1],
        longitude: coordinatesInfo?.data?.features[0]?.geometry?.coordinates[0],
      }
    } else {
      return false
    }
  } catch (error) {
    console.error("Falha ao consultar coordenadas do endereço", error)
    return false
  }
}
