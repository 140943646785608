import React from "react"
//Components
import CFButton from "../CFButton/CFButton"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import "./AddressesInfoOptions.scss"
import MenuContext from "../../contexts/MenuContext"
import UserContext from "../../contexts/UserContext"
// import Geocode from "react-geocode"
import { getAddressCoordinates, getAddressSuggestionsByName, getLabelAddress } from "../../helpers/AddressessTools"

const AddressesInfoOptions = ({ status, id, handlShowAddressOptions, address, removeAddress, setModalEditAddress }) => {
  const { setModalMapAddress } = React.useContext(MenuContext)
  const { setEditAddressRef } = React.useContext(UserContext)
  const toggleOptions = (event) => {
    if (event.target.id === `${id}`) handlShowAddressOptions()
  }

  const {
    nm_street,
    street_type: { nm_type },
    neighbourhood: {
      nm_neighbourhood,
      city: {
        nm_city,
        state: { ds_acronym },
      },
    },
    customer_address: { num_address, geo_coordinates },
  } = address
  // console.log("address", address)
  return (
    <Modal
      id={`${id}`}
      onClick={toggleOptions}
      className={"addresses-info--options"}
      isOpen={status}
      toggle={handlShowAddressOptions}
      backdrop={"static"}
      fade={false}
    >
      <ModalHeader className={"addresses-info--options--content--header"}>
        <span>{`${nm_type} ${nm_street}` + (!!num_address ? `, ${num_address}` : "")}</span>
        <span>{`${nm_neighbourhood}, ${nm_city} - ${ds_acronym}`}</span>
      </ModalHeader>
      <ModalBody className={"addresses-info--options--content--body"}>
        {address.canDelete ? (
          <CFButton
            title={"Editar"}
            icon={"pen"}
            onClick={async () => {
              let latitude = geo_coordinates[0]
              let longitude = geo_coordinates[1]
              if (!geo_coordinates[0] || !geo_coordinates[1]) {
                const addressSuggestionsByName = await getAddressSuggestionsByName(getLabelAddress(address, { withNumber: true }))
                if (!!addressSuggestionsByName) {
                  const addressCoordinates = await getAddressCoordinates(addressSuggestionsByName[0].mapbox_id)
                  if (!!addressCoordinates) {
                    latitude = addressCoordinates.latitude
                    longitude = addressCoordinates.longitude
                  }
                }
              }
              setModalMapAddress({
                status: true,
                location: { latitude, longitude },
              })
              setEditAddressRef({ ...address?.customer_address, source: "addressesInfoOptions" })
            }}
            outline={true}
          />
        ) : (
          ""
        )}
        <CFButton title={"Excluir"} icon={"trash"} onClick={() => removeAddress(address)} />
      </ModalBody>
      <ModalFooter className={"addresses-info--options--content--footer"}>
        <span onClick={handlShowAddressOptions}>Cancelar</span>
      </ModalFooter>
    </Modal>
  )
}

export default AddressesInfoOptions
