import React from "react"
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Contexts

//Components

//Helpers

import "./ModalOrderProcess.scss"
import { BarLoader } from "react-spinners"
import CFButton from "../../../components/CFButton/CFButton"
import OrderContext from "../../../contexts/OrderContext"
import TabContext from "../../../contexts/TabContext"
import PaymentsController from "../../../controllers/PaymentsController"

const ModalOrderProcess = ({ status, resend, newRequest, scheduleInfo, content, setTabSelected, onClose, finishOrder, insertOrder }) => {
  const { clearOrder } = React.useContext(OrderContext)
  const { moveToOrders } = React.useContext(TabContext)
  const countRetryRef = React.useRef(1)

  const [manualRetry, setManualRetry] = React.useState(false)

  React.useEffect(() => {
    if (status) {
      const interval = setInterval(async () => {
        if (!!resend && !newRequest && !!content) {
          const { orderInsert, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment } = content
          console.info("TENTANDO NOVAMENTE", countRetryRef.current)
          if (countRetryRef.current + 1 <= 15) {
            countRetryRef.current = countRetryRef.current + 1
            insertOrder({ ...orderInsert, st_resended: false }, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment, false, true)
          } else {
            setManualRetry(true)
          }
        }
      }, 15000)

      return () => clearInterval(interval)
    }
  }, [status, resend, newRequest])

  React.useEffect(() => {
    if (!status) {
      countRetryRef.current = 1
      setManualRetry(false)
    }
  }, [status])

  return (
    <Modal isOpen={status} backdrop={"static"} fade={false} className="modal-order-process">
      <ModalBody className={"modal-order-process--body"}>
        <div className={"modal-order-process--body--content"}>
          {!resend ? (
            <div className={"modal-order-process--body--content"}>
              <Label>{"Enviando seu pedido para o restaurante, aguarde... 😋"}</Label>
              <BarLoader color="#df2833" width="100%" height={4} />
            </div>
          ) : (
            <Label>
              {!newRequest
                ? !manualRetry
                  ? "Estamos quase finalizando!"
                  : "Verifique sua conexão com a internet e clique no botão abaixo para confirmar se seu pedido chegou ao estabelecimento."
                : "Você já possui um pedido no dia de hoje, deseja realmente fazer um novo pedido?"}
            </Label>
          )}
          {!!resend ? (
            <div className={"modal-order-process--body--content--buttons"}>
              {!newRequest && !manualRetry ? (
                <div className={"modal-order-process--body--content"}>
                  <Label>{"Verificando se seu pedido chegou ao restaurante, aguarde... 😋"}</Label>
                  <BarLoader color="#df2833" width="100%" height={4} />
                </div>
              ) : (
                <CFButton
                  title={!!newRequest ? "Enviar pedido" : "Verificar"}
                  extraClass={"modal-order-process--body--content--button"}
                  onClick={() => {
                    setManualRetry(false)
                    if (!newRequest && !!content && !!content.orderInsert && !!content.customer && !!content.customer.uuid_customer) {
                      countRetryRef.current = 1
                      const { orderInsert, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment } = content
                      insertOrder(
                        { ...orderInsert, st_resended: false },
                        customer,
                        orderType,
                        scheduleInfo,
                        onlinePayment,
                        typeOnlinePayment,
                        false,
                        true
                      )
                    } else {
                      finishOrder(
                        !!scheduleInfo ? "schedule" : "direct",
                        scheduleInfo,
                        resend,
                        content.onlinePayment || false,
                        content.typeOnlinePayment || null,
                        newRequest
                      )
                    }
                  }}
                  outline={!!newRequest}
                />
              )}
              {!!newRequest ? (
                <CFButton
                  title={"Ver pedidos"}
                  extraClass={"modal-order-process--body--content--button"}
                  onClick={() => {
                    clearOrder()
                    moveToOrders(true)
                    setTabSelected("profile")
                    onClose()
                  }}
                  outline={false}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalOrderProcess
