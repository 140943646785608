import React from "react"
//Componentes Externos
import PropTypes from "prop-types"
// import Geocode from "react-geocode"
import MaskedInput from "react-text-mask"
import FadeLoader from "react-spinners/FadeLoader"
import { Button, Label, Modal, ModalBody, ModalHeader } from "reactstrap"

//Componentes internos
import { getLocalItem, removeLocalItem, setLocalItem } from "../../helpers/StorageTools"
import GeneralTools, { getUrlAWSManagerFile } from "../../helpers/GeneralTools"
import AddressController from "../../controllers/AddressController"
import CompanyController from "../../controllers/CompanyController"
import DeliveryFeeController from "../../controllers/DeliveryFeeController"
import SettingsContext from "../../contexts/SettingsContext"

import placeholder from "../../assets/img/general/placeholder@2x.png"
import "./ModalCompanies.scss"
import { getAddressDefailsByCoords } from "../../helpers/AddressessTools"
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY)

const ModalCompanies = ({ title, status, earlyAddress, content, onUpdate }) => {
  //

  const { settings } = React.useContext(SettingsContext)
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState({
    status: false,
    type: "info",
    message: "Nenhuma unidade selecionada",
    content: null,
  })

  const [zipcode, setZipcode] = React.useState(null)

  // const [cities, setCities] = React.useState([])
  // const [neighbourhoods, setNeighbourhoods] = React.useState([])

  const [companies, setCompanies] = React.useState([])

  /* eslint-disable */
  React.useEffect(() => {
    if (status) {
      const companiesStorage = getLocalItem("companies", true)
      const storageCompanyIds = getLocalItem("companyIds", true)
      if (!!companiesStorage) {
        setCompanies(companiesStorage)
      }

      // Obtendo dados das unidades
      CompanyController.fetchAll(true, storageCompanyIds).then((responseCompany) => {
        if (responseCompany.status === 200) {
          const companies = responseCompany?.data || []

          setCompanies(companies)
          // verifyCompanyToDelivery(companies)
          setLocalItem("companies", companies, true)
        }
      })
    }
  }, [content])

  const onChangeCEP = async (event) => {
    // Obtendo apenas os valores númericos
    const lastValue = !!zipcode ? zipcode.replace(/[^0-9]/g, "") : ""
    const newValue = event.target.value.replace(/[^0-9]/g, "")

    // Só realiza a consulta quando todos dos digitos de um CEP é preenchido
    if (newValue.length === 8 && (lastValue.length === 7 || !zipcode)) {
      setLoading(true)
      setZipcode(newValue)

      const response = await AddressController.zipcode(newValue)

      if (response.status === 200) {
        const {
          neighbourhood: { id_neighbourhood, nm_neighbourhood },
        } = response.data

        let deliveryCompanies = []
        for (const company of companies) {
          const resultDeliveryFee = await DeliveryFeeController.calculate(null, null, id_neighbourhood, 1, null, company.id_company, nm_neighbourhood, "website")

          if (resultDeliveryFee.status === 200) {
            deliveryCompanies.push({
              ...company,
              delivery: !!company.company_modality.find(({ fk_id_modality }) => fk_id_modality === 1),
              comeGet: !!company.company_modality.find(({ fk_id_modality }) => fk_id_modality === 2),
              deliveryData: resultDeliveryFee.data
            })
          }
        }

        if (deliveryCompanies.length > 0) {
          validateCompanieModality(deliveryCompanies, nm_neighbourhood)
        } else {
          setResult({
            status: false,
            type: "error",
            message: "CEP encontrado, porém não é atendido. ",
          })
        }
      } else {
        setResult({
          status: false,
          type: "error",
          message: "CEP não encontrado, tente outro meio. ",
        })
      }

      setLoading(false)
    } else {
      setZipcode(newValue)

      if (!newValue) {
        setResult({
          status: false,
          type: "info",
          message: "Nenhuma unidade selecionada",
        })
      }
    }
  }

  const validateCompanieModality = (deliveryCompaniesData, neighbourhoodName) => {
    if (deliveryCompaniesData.length > 1) {
      setResult({
        status: true,
        type: "success",
        message: "Encontramos a(s) unidade(s) abaixo para lhe atender.",
        content: deliveryCompaniesData,
      })
    } else if (deliveryCompaniesData.length === 1) {
      if (!deliveryCompaniesData[0].url_redirect) {
        onSelectedCompany(deliveryCompaniesData[0])
      }

      if (deliveryCompaniesData.length === 1 && !!deliveryCompaniesData[0].url_redirect) {
        window.open(deliveryCompaniesData[0].url_redirect, "_self")
      }

    } else {
      setResult({
        status: false,
        type: "info",
        message: `Localização encontrada, porém infelizmente não atendemos em seu bairro (${neighbourhoodName})`,
      })
    }

  }

  const onSelectedCompany = async (company) => {
    if (!company.st_website) {
      let fowardToClosedCompany = false
      await GeneralTools.swalMessage("delete")
        .fire({
          title: `A loja está fechada! Deseja prosseguir?`,
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "Voltar",
          showConfirmButton: true,
          confirmButtonText: "Prosseguir",
          reverseButtons: true,
        })
        .then(({ isConfirmed }) => {
          fowardToClosedCompany = isConfirmed
        })
      if (!fowardToClosedCompany) return null

    }
    if (!company.url_redirect) {
      // Vai definir que o pedido está fora da área de entrega
      // handleOutsideCoverageArea(isOutsideCoverageArea)
      setLocalItem("company", company)
      // Limpando todos os dados salvos no Storage
      removeLocalItem("menuOptions")
      removeLocalItem("settings")

      removeLocalItem("highlights")
      removeLocalItem("combos")

      removeLocalItem("products")
      removeLocalItem("productSizes")
      removeLocalItem("productFlavours")

      removeLocalItem("beverages")
      removeLocalItem("schedules")

      removeLocalItem("deliveryFee")
      removeLocalItem("deliveryTime")

      onUpdate(company, earlyAddress)
    } else {
      GeneralTools.swalMessage("delete")
        .fire({
          title: `Você será direcionado ao endereço desta loja, deseja prosseguir?`,
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "Voltar",
          showConfirmButton: true,
          confirmButtonText: "Prosseguir",
          reverseButtons: true,
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            setLoading(true)
            window.open(company.url_redirect, "_self")
          }
        })
    }
  }

  const getLocation = () => {
    if ("geolocation" in navigator) {
      setLoading(true)

      navigator.geolocation.getCurrentPosition(
        async function (position) {

          setLocalItem(
            "location",
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            true
          )

          try {
            await getInfoLocation(position.coords.latitude, position.coords.longitude)
          } catch (error) {
            console.error("ocorreu um erro ao tratar localização", error)
            setLoading(false)
            setResult({
              status: false,
              type: "error",
              message: "Erro ao obter a localização, tente outro meio.",
            })
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setLocalItem("location", null, true)

            setResult({
              status: false,
              type: "error",
              message: "Erro ao obter a localização, tente outro meio.",
            })
          }
          setLoading(false)
        }
      )
    } else {
      console.warn("Localização não disponível")
    }
  }

  const getInfoLocation = async (latitude, longitude) => {

    const address = await getAddressDefailsByCoords(longitude, latitude)
    if (!!address) {
      let deliveryCompanies = []
      const neighbourhoodName = address.neighborhood

      for (const company of companies) {
        const resultDeliveryFee = await DeliveryFeeController.calculate(latitude, longitude, null, 1, null, company.id_company, neighbourhoodName, "website")

        if (resultDeliveryFee.status === 200) {
          deliveryCompanies.push({
            ...company,
            delivery: !!company.company_modality.find(({ fk_id_modality }) => fk_id_modality === 1),
            comeGet: !!company.company_modality.find(({ fk_id_modality }) => fk_id_modality === 2),
            deliveryData: resultDeliveryFee.data
          })
        }
      }
      validateCompanieModality(deliveryCompanies, neighbourhoodName)


      setLoading(false)
    }
  }

  // Método para obter o bairro
  const getNeighbourhood = (result) => {
    const neighbourhood = result.address_components.find(
      ({ types }) => !!types.find((item) => ["sublocality_level_1", "sublocality", "locality"].includes(item))
    )
    return !!neighbourhood ? neighbourhood.long_name : null
  }

  return (
    <Modal isOpen={status} keyboard={false} backdrop={true} className={"modal-companies"}>
      <ModalHeader toggle={null}>{title}</ModalHeader>
      <ModalBody>
        {!!earlyAddress ? (
          <div className="description">
            <Label>Escolha uma das nossas lojas para realizar o seu pedido!</Label>
          </div>
        ) : (
          <>
            <div className="description">
              <Label>Precisamos da sua localização para conseguirmos te atender melhor!</Label>
              <Label>Temos duas formas de obtermos a sua localização:</Label>
            </div>

            <div className="location-methods">
              <div className="left-side">
                <Button color="dark" onClick={getLocation}>
                  Liberar localização
                </Button>
              </div>
              {settings.display_mode_fee === "neighbourhood" ? <>
                <div className="separator">
                  <Label>ou</Label>
                </div>
                <div className="center">
                  <MaskedInput
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                    className="form-control"
                    placeholder="Informe seu CEP"
                    guide={true}
                    autoFocus={true}
                    value={zipcode}
                    onChange={onChangeCEP}
                  />
                </div>
              </> : ""}

            </div>
          </>
        )}

        <div className="status">
          {(() => {
            if (loading) {
              return (
                <div className="loading">
                  <FadeLoader color={"#000000"} height={28} width={4} radius={2} margin={6} loading={true} />
                  <Label>Procurando unidades próximas...</Label>
                </div>
              )
            } else {
              if (!!result.status) {
                if (!!result.content && result.content.length > 0) {
                  return (
                    <div className="result">
                      <Label className={result.type}>{result.message}</Label>
                    </div>
                  )
                } else {
                  return <Label className="error">Erro ao obter as unidades mais próximas</Label>
                }
              } else {
                if (!!settings && settings.st_outside_coverage_area === "1" && result.type === "error") {
                  return (
                    <div className="result">
                      <div className="result">
                        <Label className={result.type}>Não entregamos no seu bairro, porém está disponível para retirada na loja.</Label>
                        <Label className="info">Escolha a loja para retirada</Label>
                      </div>
                    </div>
                  )
                }
              }
            }
          })()}
        </div>

        <div>
          {(() => {
            if (!!result.status) {
              if (!!result.content && result.content.length > 0) {
                return (
                  <div className={"company-list"}>
                    {result.content.map((company, key) => {
                      const companiesFiltered = company.company_modality.filter(
                        ({ fk_id_modality: idModality }) => idModality === 1 || idModality === 2
                      )
                      if (companiesFiltered.length > 0) {
                        return (
                          <div key={key}>
                            <div className="company" onClick={() => onSelectedCompany(company, earlyAddress)}>
                              <div className="company--img">
                                <img
                                  alt={`logo ${company.nm_company}`}
                                  src={!!company.url_image ? getUrlAWSManagerFile(company.url_image) : placeholder}
                                />
                              </div>

                              <div className="companies--company">
                                <div className="companies--company--name">{company.nm_company}</div>

                                <div className="companies--company--modalities">
                                  {company.delivery ? <div className="companies--company--modalities--active">Entrega</div> : ""}
                                  {company.comeGet ? <div className="companies--company--modalities--active">Retirada</div> : ""}
                                </div>
                                <div className={"companies--company--st-site " + (company.st_website ? "companies--company--st-site--open" : "")}>
                                  {company.st_website ? "Aberto" : "Fechado"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                )
              }
            } else {
              return (
                <div className={"company-list"}>
                  {companies
                    .filter(({ company_modality }) => company_modality.find(({ fk_id_modality: modalityId }) => [1, 2].includes(modalityId)))
                    .map((company, key) => {
                      return (
                        <div key={key}>
                          {key === 0 ? <Label className={"company-list--tittle"}>Todas as nossas unidades </Label> : ""}
                          <div className="company" onClick={() => onSelectedCompany(company, earlyAddress)}>
                            <div className="company--img">
                              <img
                                alt={`logo ${company.nm_company}`}
                                src={!!company.url_image ? getUrlAWSManagerFile(company.url_image) : placeholder}
                              />
                            </div>

                            <div className="companies--company">
                              <div className="companies--company--name">{company.nm_company}</div>
                              <div className="companies--company--modalities">
                                {company.company_modality.map((modality, key) => {
                                  if (modality.fk_id_modality === 1 || modality.fk_id_modality === 2) {
                                    return (
                                      <div key={key} className="companies--company--modalities--active">
                                        {modality.fk_id_modality === 1 ? "Entrega" : modality.fk_id_modality === 2 ? "Retirada" : ""}
                                        {modality.length > 1 ? " - " : ""}
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                              <div className={"companies--company--st-site " + (company.st_website ? "companies--company--st-site--open" : "")}>
                                {company.st_website ? "Aberto" : "Fechado"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )
            }
          })()}
        </div>
      </ModalBody>
    </Modal>
  )
}

ModalCompanies.propTypes = {
  title: PropTypes.string,
  status: PropTypes.bool,
  content: PropTypes.array,
  onClose: PropTypes.func,
}

export default ModalCompanies
