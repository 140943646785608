import React from "react"
//External components
import { Modal, ModalBody, ModalHeader, ModalFooter, Label, Input } from "reactstrap"

//Images
import SecureIcon from "../../../assets/img/icons/secure.png"
import EloIcon from "../../../assets/img/payment/elo-icon.png"
import MastercardIcon from "../../../assets/img/payment/mastercard-icon.png"
import VisaIcon from "../../../assets/img/payment/visa-icon.png"
import VisaWhiteIcon from "../../../assets/img/payment/visa-white.png"

//Styles
import "./ModalNewCard.scss"
import MaskedInput from "react-text-mask"
import CFButton from "../../../components/CFButton/CFButton"

const ModalNewCard = ({ status, title, content, onClose }) => {
  const [value, setValue] = React.useState(null)
  const [cardNumber, setCardNumber] = React.useState(null)
  const [cardBrand, setCardBrand] = React.useState(null)
  const [cardExpiration, setCardExpiration] = React.useState(null)
  const [cardCvv, setCardCvv] = React.useState(null)

  const getCardBrand = (e) => {
    setCardNumber(e.target.value)

    if (e.target.value.startsWith(3306)) {
      setCardBrand("mastercard")
    } else if (e.target.value.startsWith("2204")) {
      setCardBrand("visa")
    } else if (e.target.value.startsWith("1204")) {
      setCardBrand("elo")
    } else {
      setCardBrand(null)
    }
  }

  return (
    <Modal
      isOpen={status}
      toggle={() => {
        onClose()
      }}
      backdrop={"static"}
      fade={true}
      className="modal-new-card"
    >
      <ModalBody className="modal-new-card--body">
        <div className="modal-new-card--body--top">
          <img className="modal-new-card--body--top-secure" src={SecureIcon} alt="Ícone de compra com segurança" />
          <div
            className={`modal-new-card--body--top-card ${cardBrand === "mastercard" ? "mastercard active" : ""} ${
              cardBrand === "elo" ? "elo active" : ""
            } ${cardBrand === "visa" ? "visa active" : ""}`}
          >
            <div className="modal-new-card--body--top-card--content">
              <div className="modal-new-card--body--top-card--content--chip" />
              <h1 className="modal-new-card--body--top-card--content-card-number">
                {!!cardNumber && cardNumber.length > 0 ? cardNumber : "**** **** **** ****"}
              </h1>
              <div className="modal-new-card--body--top-card--content--infos">
                <div className="modal-new-card--body--top-card--content--infos--left">
                  <h2>
                    CVV: <span>{!!cardCvv && cardCvv.length > 0 ? cardCvv : "___"}</span>
                  </h2>
                  <h2>
                    Data de vencimento: <span>{!!cardExpiration && cardExpiration.length > 0 ? cardExpiration : "__/__"}</span>
                  </h2>
                </div>
                <div className="modal-new-card--body--top-card--content--infos--right">
                  <img
                    className="modal-new-card--body--top-card--content--infos--right-card-brand"
                    alt=""
                    src={cardBrand === "visa" ? VisaWhiteIcon : cardBrand === "mastercard" ? MastercardIcon : cardBrand === "elo" ? EloIcon : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-new-card--body--center">
          <div className="modal-new-card--body--center-form">
            <div className="modal-new-card--body--center-form-row">
              <div className="modal-new-card--body--center-form-row-input">
                <h4>Número do cartão</h4>
                {cardBrand && (
                  <img
                    src={cardBrand === "visa" ? VisaIcon : cardBrand === "mastercard" ? MastercardIcon : cardBrand === "elo" ? EloIcon : ""}
                    alt={"Bandeira do cartão"}
                  />
                )}
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]}
                  className="form-control"
                  placeholder="Informe o número do cartão"
                  onChange={getCardBrand}
                  value={cardNumber}
                />
              </div>
            </div>
            <div className="modal-new-card--body--center-form-row">
              <div className="modal-new-card--body--center-form-row-input">
                <h4>Data de Vencimento</h4>
                <MaskedInput
                  onChange={(e) => setCardExpiration(e.target.value)}
                  mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                  className="form-control"
                  placeholder="Data de vencimento"
                />
              </div>
              <div className="modal-new-card--body--center-form-row-input">
                <h4>CVV</h4>
                <MaskedInput onChange={(e) => setCardCvv(e.target.value)} className="form-control" placeholder="CVV" mask={[/\d/, /\d/, /\d/]} />
              </div>
            </div>
            <div className="modal-new-card--body--center-form-row">
              <div className="modal-new-card--body--center-form-row-input">
                <h4>Nome do Titular</h4>
                <Input className="form-control" placeholder="Informe o nome do titular" />
              </div>
            </div>
            <div className="modal-new-card--body--center-form-row">
              <div className="modal-new-card--body--center-form-row-input">
                <h4>CPF do Titular</h4>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                  className="form-control"
                  placeholder="Informe o CPF do titular"
                />
              </div>
            </div>
            <div className="modal-new-card--body--center-form-row">
              <div className="modal-new-card--body--center-form-row-checkbox">
                <input type="checkbox" id="modal-new-card--body--center-form-row-checkbox" />
                <label htmlFor="modal-new-card--body--center-form-row-checkbox">Deseja salvar esse cartão para compras futuras?</label>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="modal-new-card--footer">
        <div className="modal-new-card--footer-buttons">
          <CFButton title="Fechar" onClick={onClose} outline={true} />
          <CFButton title="Adicionar Cartão" />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalNewCard
