export const getComboName = ({ nm_combo, lb_combo }) => {
  return lb_combo ? lb_combo : nm_combo
}

export const getProductName = ({ nm_product, lb_product }) => {
  return lb_product ? lb_product : nm_product
}

export const getBeverageName = ({ nm_beverage, lb_beverage }) => {
  return lb_beverage ? lb_beverage : nm_beverage
}

export const getSizeName = ({ nm_size, lb_size }) => {
  return lb_size ? lb_size : nm_size
}

export const getFlavourName = ({ nm_flavour, lb_flavour }) => {
  return lb_flavour ? lb_flavour : nm_flavour
}

export const getIngredientName = ({ nm_ingredient, lb_ingredient }) => {
  return lb_ingredient ? lb_ingredient : nm_ingredient
}

export const getNameAdditional = (additional) => {
  if (!!additional.lb_additional) {
    return additional.lb_additional
  } else {
    return additional.nm_additional
  }
  // }
}

export const getNameComplement = (complement) => {
  if (`${complement.nm_complement}`.toLowerCase() === "borda") {
    if (`${complement.complement.nm_additional}`.toLowerCase() === "sem borda") {
      return "Sem borda"
    }

    return `Borda de ${complement.complement.nm_additional}`
  }
  if (`${complement.nm_complement}`.toLowerCase() === "massa") {
    return `Massa ${complement.complement.nm_additional}`
  }
}
