import React from "react"

// External libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

// Components
import CFInput from "../CFInput/CFInput"
import CFButton from "../CFButton/CFButton"

import "./BenefitFinder.scss"

const BenefitFinder = ({
  returnMenu,
  verifyBenefit,
  benefitCode,
  setBenefitCode,
  promotions,
  onApplyPromotion,
  selectedPromotion,
  status,
  loadingApply,
}) => {
  const toggleBenefitFinder = () => {
    returnMenu()
  }

  return (
    <Modal isOpen={status} toggle={toggleBenefitFinder} backdrop={"static"} fade={false} className="benefit-finder">
      <ModalHeader className={"benefit-finder--header"}>
        <FontAwesomeIcon icon={"chevron-down"} onClick={returnMenu} />
        <span>CUPONS E PROMOÇÕES</span>
      </ModalHeader>

      <ModalBody className={"benefit-finder--body"}>
        <div className={"benefit-finder--body--top"}>
          <CFInput placeholder="Código do cupom" value={benefitCode} onChange={(event) => setBenefitCode(event.target.value)} />
          <div className={"benefit-finder--body--top--button"}>
            <CFButton
              title={loadingApply ? <FontAwesomeIcon icon={"spinner"} spin /> : "Adicionar"}
              outline={true}
              onClick={loadingApply ? () => {} : () => verifyBenefit()}
            />
          </div>
        </div>

        <div className={"benefit-finder--body--bottom"}>
          {!!promotions && promotions.length > 0
            ? promotions.map((promotion, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "benefit-finder--body--bottom--item " +
                      (promotion.uuid_benefit === selectedPromotion || promotion.uuid_fidelity === selectedPromotion ? "promotion-selected" : "")
                    }
                  >
                    <div className={"benefit-finder--body--bottom--item--left"} onClick={loadingApply ? () => {} : () => onApplyPromotion(promotion)}>
                      <FontAwesomeIcon icon={promotion.icon} />
                    </div>
                    <div
                      className={"benefit-finder--body--bottom--item--right"}
                      onClick={loadingApply ? () => {} : () => onApplyPromotion(promotion)}
                    >
                      <span>{promotion.nm_benefit || promotion.nm_fidelity}</span>
                    </div>

                    {promotion.uuid_benefit === selectedPromotion || promotion.uuid_fidelity === selectedPromotion ? (
                      <div className={"benefit-finder--body--bottom--item--right--remove"}>
                        <FontAwesomeIcon id={"id-benefit-remove"} icon={"xmark"} onClick={() => onApplyPromotion("clear")} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )
              })
            : "Sem promoções ou cupons disponíveis"}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BenefitFinder
