import { useEffect, useState } from "react"
import { Label, Modal, ModalBody, ModalHeader, ModalFooter, Button, Spinner } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Message from "../../components/Message"
import GeneralTools from "../../helpers/GeneralTools"

import "./ModalSuccessOrder.scss"
import CFButton from "../../components/CFButton/CFButton"

export default function ModalSuccessOrder({ status, content, onClose, onBack }) {
  useEffect(() => {
    getOrderProgressionTitle()
  }, [content])

  const getOrderProgressionTitle = () => {
    let title = "Realizando pedido..."
    if (!!content) {
      if (content.orderStatus === "success") {
        title = "Confirmado!"
      } else if (content.orderStatus === "error") {
        title = "Falha ao realizar pedido!"
      }
    }
    return title
  }

  const loadingContent = () => {
    let loading = true
    if (!!content) {
      if (content.orderStatus === "success") {
        loading = false
      } else if (content.orderStatus === "error") {
        loading = false
      }
    }
    return loading
  }

  const copyToClipBoard = (value) => {
    navigator.clipboard.writeText(value)
    Message.info("Chave pix copiada com sucesso!")
  }

  const getContentType = () => {
    let icon = "spinner"
    if (!!content) {
      if (content.orderStatus === "success") {
        icon = "check"
      } else if (content.orderStatus === "error") {
        icon = "times"
      }
    }
    return icon
  }

  return (
    <Modal centered isOpen={status} size={"lg"} backdrop={true} keyboard={true} className={"modal-success-order"}>
      <ModalHeader className={"modal-success-order--header"}>
        {(() => {
          switch (getContentType()) {
            case "spinner": {
              break
            }
            case "check": {
              return <FontAwesomeIcon icon={getContentType()} color="#00BF08" />
            }
            case "times": {
              return <FontAwesomeIcon icon={getContentType()} color="#dc3545" />
            }
            default: {
              break
            }
          }
        })()}
      </ModalHeader>
      <ModalBody className={"modal-success-order--body"}>
        <div className="modal-success-order--body--top">
          <p>{getOrderProgressionTitle()}</p>

          {(() => {
            switch (getContentType()) {
              case "spinner": {
                return <Spinner />
              }
              case "check": {
                return <p>Seu pedido foi realizado com sucesso.</p>
              }
              case "times": {
                return <p>Ocorreu um erro ao realizar o seu pedido!</p>
              }
              default: {
                break
              }
            }
          })()}
        </div>

        {(() => {
          if (!!content) {
            const { company = null, order = null } = content

            if (!!company && !!order) {
              const paymentPix = order.payments.find(({ nm_payment }) => `${nm_payment}`.toLowerCase() === "pix")

              if (!!paymentPix) {
                const paymentInfo = company.company_payment_methods.find(
                  ({ id_payment_method }) => id_payment_method === paymentPix.id_payment_method
                )

                if (!!paymentInfo) {
                  let value = paymentInfo.ds_key_payment_method
                  let whatsapp = company.company_contact.find(({ contact }) => `${contact.ds_contact}`.toLowerCase() === "whatsapp")
                  switch (`${paymentInfo.ds_key_type_payment_method}`.toLowerCase()) {
                    case "cnpj":
                      value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                      break
                    case "cpf":
                      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
                      break
                    case "phone":
                      value = value.replace(/^(\d{2})(\d)(\d{4})(\d{4})/, "($1) $2 $3-$4")
                      break
                    default:
                      break
                  }

                  return (
                    <div className="modal-success-order--body--bottom">
                      <div className="modal-success-order--body--bottom--top">
                        <label>Dados para pagamento Pix</label>
                      </div>
                      <div className="modal-success-order--body--bottom--center">
                        <span>
                          Tipo:&nbsp;<strong>{paymentInfo.ds_key_type_payment_method}</strong>
                        </span>
                        <span>
                          Chave:&nbsp;<strong>{value}</strong>&nbsp;
                          <FontAwesomeIcon icon="clipboard" onClick={() => copyToClipBoard(value)} />
                        </span>
                      </div>
                      {(() => {
                        if (!!whatsapp && !!whatsapp.val_contact) {
                          return (
                            <div className="modal-success-order--body--bottom--footer">
                              <Label>Enviar comprovante para:&nbsp;</Label>
                              <a
                                href={`https://wa.me/+55${whatsapp.val_contact.replace(/[^0-9]/g, "")}?text=Meu comprovante de pagamento`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <FontAwesomeIcon icon={["fab", "whatsapp"]} />
                                &nbsp;{GeneralTools.formatPhone(whatsapp.val_contact)}
                              </a>
                            </div>
                          )
                        }
                      })()}
                    </div>
                  )
                } else {
                  return <Label>Erro ao consultar dados da chave Pix :(</Label>
                }
              }
            }
          }
        })()}
      </ModalBody>

      <ModalFooter className={"modal-success-order--footer"}>
        {(() => {
          if (!!content) {
            switch (content.orderStatus) {
              case "inprogress": {
                break
              }
              case "success": {
                return (
                  <CFButton
                    title={"Acompanhar pedido"}
                    onClick={() => onClose(content.order.id_order)}
                    style={{
                      backgroundColor: "#00BF08",
                      color: "#FFF",
                    }}
                  />
                  // <Button color="success" onClick={() => onClose(content.order.id_order)}>
                  //   Acompanhar pedido
                  // </Button>
                )
              }
              case "error": {
                return (
                  <Button color="danger" onClick={onBack}>
                    Retornar
                  </Button>
                )
              }
              default: {
                break
              }
            }
          }
        })()}
      </ModalFooter>
    </Modal>
  )
}
