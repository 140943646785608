import React from "react"

//External Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Contexts
import OrderContext from "../../contexts/OrderContext"
import SettingsContext from "../../contexts/SettingsContext"
import CompanyContext from "../../contexts/CompanyContext"
import TabContext from "../../contexts/TabContext"
import MenuContext from "../../contexts/MenuContext"
import UserContext from "../../contexts/UserContext"
//Components
import CFButton from "../CFButton/CFButton"
import ProductItem from "../ProductItem/ProductItem"
import ComboItem from "../ComboItem/ComboItem"
import BenefitItem from "../BenefitItem/BenefitItem"
import BeverageItem from "../BeverageItem/BeverageItem"
import DeliveryInfo from "../DeliveryInfo/DeliveryInfo"
import OrderTotalInfo from "../OrderTotalInfo/OrderTotalInfo"
import CFObservationInput from "../CFObservationInput/CFObservationInput"

//Helpers
import { onMessageDialog, isAppleDevice, formatBoolean, formatFloat, getUrlAWSManagerFile } from "../../helpers/GeneralTools"
import {
  extractFlavoursDetails,
  extractComplementsDetails,
  getTotalOrder,
  getProductName,
  getProductPrice,
  getBeverageName,
  extratComboComplementsDetails,
  extractComboFlavoursDetails,
  getDiscountOrder,
} from "../../helpers/OrderTools"

import "./OrderPreview.scss"

const OrderPreview = ({ status, onClose, openOrderFinisher, openOrderSchedule }) => {
  const { order, setOrder } = React.useContext(OrderContext)
  const { company } = React.useContext(CompanyContext)
  const { modality, settings } = React.useContext(SettingsContext)
  const { userSelectedAddress } = React.useContext(UserContext)
  const { setModalInitialAddress, setModalBuilder } = React.useContext(MenuContext)
  const { setTabSelected } = React.useContext(TabContext)
  // const [showDeliveryTypeSelector, setShowDeliveryTypeSelector] = React.useState(false)

  //Função para montar os itens a serem mostrados na tela do pedido.
  const getDisplayItems = () => {
    const itemsOrder = []
    let { products = [], combos = [], beverages = [] } = order

    if (products.length > 0) {
      products.forEach((product, index) => {
        if (!("id_combo" in product)) {
          itemsOrder.push(
            <ProductItem
              key={product.uuid_product + index}
              isCombo={false}
              productData={{
                title: getProductName(product),
                image: getUrlAWSManagerFile(product.size?.url_image),
                amount: product.amount,
                id_product: product.id_product,
                size: product.size || null,
                nro_combo: product.nro_combo,
                nro_product: product.nro_product,
                val_price: getProductPrice({ ...product, index }, order),
                observation: !!product.ds_obs ? product.ds_obs : null,
                flavours: extractFlavoursDetails(product, !!product.id_combo),
                complements: extractComplementsDetails(product.complements, !!product.id_combo),
              }}
              promotionalItem={!!product.id_fidelity}
              changeAmount={onChangeProductAmount}
            />
          )
        }
      })
    }

    if (combos.length > 0) {
      combos.forEach((combo, index) => {
        if ("id_combo" in combo) {
          const productsThisCombo = products.filter(({ nro_combo }) => nro_combo === combo.nro_combo)
          const beveragesThisCombo = beverages.filter(({ nro_combo }) => nro_combo === combo.nro_combo)

          itemsOrder.push(
            <ComboItem
              key={"combo_data" + index}
              title={`${combo?.amount > 1 ? combo?.amount + "x" : ""} ${combo.nm_combo}`}
              price={combo.val_price}
              observation={!!combo.ds_obs ? combo.ds_obs : null}
              removeCombo={() => onRemoveCombo(combo.id_combo, combo.nro_combo)}
              editCombo={() => onEditCombo(combo)}
            >
              {productsThisCombo.length > 0
                ? productsThisCombo.map((product, index) => {
                  return (
                    <ProductItem
                      key={"combo_product" + product.uuid_product + index}
                      isCombo={true}
                      promotionalItem={!!product.id_fidelity}
                      productData={{
                        title: getProductName(product),
                        image: getUrlAWSManagerFile(
                          product?.flavours?.length === 1 ? product.flavours[0]?.url_main_image : product.size?.url_image
                        ),
                        val_price: getProductPrice(product, order),
                        nro_combo: combo.nro_combo,
                        id_combo: combo.id_combo,
                        observation: !!product.ds_obs ? product.ds_obs : null,
                        flavours: extractComboFlavoursDetails(product, !!product.id_combo),
                        complements: extratComboComplementsDetails(product.complements),
                        size: product.size || null
                      }}
                    />
                  )
                })
                : ""}
              {beveragesThisCombo.length > 0
                ? beveragesThisCombo.map((beverage, index) => {
                  const [category, name] = getBeverageName(beverage)
                  return (
                    <BeverageItem
                      key={"combo_beverage" + beverage.uuid_product + +index}
                      isCombo={true}
                      productBeverage={!!beverage.nro_product}
                      beverageData={{
                        category,
                        name,
                        amount: combo.amount,
                        val_price: beverage.val_price,
                        image: getUrlAWSManagerFile(beverage.url_image),
                      }}
                    />
                  )
                })
                : ""}
            </ComboItem>
          )
        }
      })
    }

    if (beverages.length > 0) {
      beverages.forEach((beverage, index) => {
        if (!beverage.nro_combo) {
          const [category, name] = getBeverageName(beverage)
          itemsOrder.push(
            <BeverageItem
              key={beverage.uuid_beverage + "_" + category + "_" + name + "_" + index}
              isCombo={!!beverage.id_combo}
              productBeverage={!!beverage.nro_product}
              beverageData={{
                category,
                name,
                id_beverage: beverage.id_beverage,
                amount: beverage.amount,
                val_price: beverage.val_price,
                image: getUrlAWSManagerFile(beverage.url_image),
              }}
              changeAmount={onChangeBeverageAmount}
            />
          )
        }
      })
    }

    return itemsOrder
  }

  const onChangeBeverageAmount = (beverageId, amount) => {
    //procura qual o index da bebida sendo removida, lembrando que não pode alterar a quantidade de uma bebida que seja de combo ou vinculada a um produto
    const beverageIndex = order.beverages.findIndex(
      ({ id_beverage, id_combo, nro_product }) => beverageId === id_beverage && !id_combo && !nro_product
    )

    if (beverageIndex !== -1) {
      const tempOrder = { ...order }

      if (amount === 0) {
        onMessageDialog("delete")
          .fire({
            title: `Deseja realmente remover a bebida do carrinho?`,
            icon: "question",
            confirmButtonText: "Remover",
            showConfirmButton: true,
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: "Voltar",
          })
          .then(({ isConfirmed }) => {
            if (!!isConfirmed) {
              tempOrder.beverages.splice(beverageIndex, 1)
              setOrder({ ...tempOrder })
            }
          })
      } else {
        tempOrder.beverages[beverageIndex].amount = amount
        setOrder({ ...tempOrder })
      }
    }
  }

  const onChangeProductAmount = (productId, nroProduct, nroCombo, amount) => {
    //Procurando o index do produto para manipular
    const productIndex = order.products.findIndex(
      ({ id_product, nro_combo, nro_product }) => id_product === productId && nro_combo === nroCombo && nro_product === nroProduct
    )

    if (productIndex !== -1) {
      const tempOrder = { ...order }

      //Fazendo um reduce para casos em que o estabelecimento de mais de uma bebida no produto.
      const itemBeveragesCount = order.beverages.reduce((prev, { nro_product }) => {
        return nroProduct === nro_product ? prev + 1 : prev
      }, 0)

      let productBeverageIndex = null

      if (itemBeveragesCount === 1) {
        productBeverageIndex = order.beverages.findIndex(({ nro_product }) => nroProduct === nro_product)
      } else {
        productBeverageIndex = order.beverages.filter(({ nro_product }) => nroProduct === nro_product)
      }

      if (amount === 0) {
        onMessageDialog("delete")
          .fire({
            title: `Deseja realmente remover o item do carrinho?`,
            icon: "question",
            confirmButtonText: "Remover",
            showConfirmButton: true,
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: "Voltar",
          })
          .then(({ isConfirmed }) => {
            if (!!isConfirmed) {
              tempOrder.products.splice(productIndex, 1)

              if (typeof productBeverageIndex === "number" && itemBeveragesCount === 1) {
                //Estabelecimento da apenas 1 bebida
                const productBeverageIndex = order.beverages.findIndex(({ nro_product }) => nroProduct === nro_product)
                if (productBeverageIndex !== -1) {
                  tempOrder.beverages.splice(productBeverageIndex, 1)
                }
              } else if (typeof productBeverageIndex === "object" && productBeverageIndex.length > 1) {
                productBeverageIndex.forEach((item) => {
                  const productBeverageIndex = order.beverages.findIndex(({ nro_product }) => item.nro_product === nro_product)
                  if (productBeverageIndex !== -1) {
                    tempOrder.beverages.splice(productBeverageIndex, 1)
                  }
                })
              }
              setOrder({ ...tempOrder })
            }
          })
      } else {
        if (itemBeveragesCount === 1) {
          if (productBeverageIndex !== -1) {
            tempOrder.beverages[productBeverageIndex].amount = amount
          }
        } else {
          //TODO: Estabelecimento que da mais de uma bebida no item
        }

        tempOrder.products[productIndex].amount = amount
        setOrder({ ...tempOrder })
      }
    }
  }

  const onRemoveCombo = (comboId, comboNro) => {
    onMessageDialog("delete")
      .fire({
        title: `Deseja realmente remover o combo do carrinho?`,
        icon: "question",
        confirmButtonText: "Remover",
        showConfirmButton: true,
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: "Voltar",
      })
      .then(async ({ isConfirmed }) => {
        if (!!isConfirmed) {
          const comboIndex = order.combos.findIndex(({ nro_combo, id_combo }) => nro_combo === comboNro && id_combo === comboId)
          if (comboIndex !== -1) {
            const tempOrder = { ...order }
            tempOrder.combos.splice(comboIndex, 1)

            tempOrder.products = tempOrder.products.filter(({ nro_combo, id_combo }) => nro_combo !== comboNro || id_combo !== comboId)
            tempOrder.beverages = tempOrder.beverages.filter(({ nro_combo, id_combo }) => nro_combo !== comboNro || id_combo !== comboId)
            setOrder({ ...tempOrder })
          }
        }
      })
  }

  const onEditCombo = (comboSelected) => {
    setModalBuilder({ status: true, type: "combo", content: { combo: comboSelected } })
  }

  const onChangeDeliveryComment = (event) => {
    setOrder({ ...order, deliveryComments: event })
  }
  if (isAppleDevice()) {
    window.scrollTo(0, document.body.scrollHeight)
  }

  return (
    <Modal
      className="order-preview"
      isOpen={status}
      toggle={() => {
        onClose()
      }}
      backdrop={"static"}
      fade={false}
    >
      <ModalHeader className="order-preview--header">
        <FontAwesomeIcon className="order-preview--header--return" icon={"chevron-down"} onClick={() => onClose()} />
        <span>MEU PEDIDO</span>
      </ModalHeader>

      <ModalBody className="order-preview--body">
        {/*Todos os produtos, bebidas e combos*/}
        <div className="order-preview--body--top">
          {(() => {
            const itemsToShow = getDisplayItems()
            return itemsToShow.map((item) => {
              return item
            })
          })()}

          <div
            className="order-preview--body--top--button"
            onClick={() => {
              setTabSelected("home")
              onClose()
            }}
          >
            <FontAwesomeIcon icon="plus" />
            <span>Adicionar mais itens</span>
          </div>
        </div>

        {/*Todos os dados do pedido*/}
        {order.products.length > 0 || order.beverages.length > 0 ? (
          <div className="order-preview--body--bottom">
            <div className="order-preview--body--bottom--divider" />
            {/*Detalhes de cupom de desconto*/}
            <div className="order-preview--body--bottom--benefit-item">
              <BenefitItem />
            </div>
            <div className="order-preview--body--bottom--divider" />
            {/*Totais do pedido*/}
            <div className="order-preview--body--bottom--order-total-info">
              {(() => {
                const [total, subTotal, benefit] = getTotalOrder(order, userSelectedAddress?.deliveryFee)

                const deliveryFeeValue = modality === 1 ? userSelectedAddress?.deliveryFee : 0
                const discount = getDiscountOrder(order, subTotal, deliveryFeeValue, settings, modality)

                const discountType = order.discountType
                const discountOrder = order.discount

                return (
                  <OrderTotalInfo
                    total={total}
                    totalBenefits={benefit}
                    deliveryFee={deliveryFeeValue}
                    additionalFee={0}
                    totalDiscount={discount}
                    discountTypeOrder={discountType}
                    discountOrder={discountOrder}
                  />
                )
              })()}
            </div>
            <div className="order-preview--body--bottom--divider" />
            {/*Dados do endereço para entrega*/}
            <div className="order-preview--body--bottom--delivery-info">
              <DeliveryInfo
                handleDeliveryTypeSelector={() => {
                  setModalInitialAddress({ status: true, title: "", content: { source: "orderPreview" } })
                }}
              />
            </div>
            <div className="order-preview--body--bottom--divider" />
            {/*Orientações de entrega*/}
            <div className="order-preview--body--bottom--delivery-observation">
              <CFObservationInput title="OBSERVAÇÃO ENTREGA" onChange={onChangeDeliveryComment} value={order.deliveryComments || ""} />
            </div>
            <div className="order-preview--body--bottom--divider" />
          </div>
        ) : (
          ""
        )}
      </ModalBody>

      {/*botões da tela do carrinho*/}
      {order.products.length > 0 || order.beverages.length > 0 ? (
        <ModalFooter className="order-preview--footer">
          <div className="order-preview--footer--button">
            {(() => {
              if (formatBoolean(company?.st_schedule) && formatBoolean(settings?.enable_schedule_order_website)) {
                return <CFButton onClick={() => openOrderSchedule()} title={"Agendar"} outline={true} />
              }
            })()}

            {(() => {
              if (formatBoolean(company?.st_website)) {
                let title = "Continuar"
                let actionFunction = () => openOrderFinisher()

                const [_, subTotal, __, totalDiscount] = getTotalOrder(order, userSelectedAddress?.deliveryFee)

                if (settings.length === 0 || (settings.length > 0 && !settings.minimal_order_price)) {
                  title = `Carregando informações`
                  actionFunction = () => null
                }


                const minimalOrderValue = subTotal
                  + (formatBoolean(settings?.discount_on_minimal_order_price || true) ? 0 : totalDiscount)
                  + (formatBoolean(settings?.delivery_fee_on_minimal_order_price || false) ? userSelectedAddress?.deliveryFee : 0)


                if (minimalOrderValue < formatFloat(settings.minimal_order_price)) {
                  title = `Valor mínimo ${settings.minimal_order_price}`
                  actionFunction = () => null
                }

                return <CFButton onClick={actionFunction} title={title} disabled={!company.st_website} />
              }

              return <CFButton title={"Loja Fechada"} disabled={true} />
            })()}
          </div>
        </ModalFooter>
      ) : (
        ""
      )}

    </Modal>
  )
}

export default OrderPreview
