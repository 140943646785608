import Swal from "sweetalert2"

import { sha224, sha256 } from "js-sha256"

/**
 * Método responsável por preencher valores numéricos com 0
 * @param value         Valor numérico
 * @param fill          Quantidade de casas a preencher
 * @returns {*}
 */
export const setNumber = (value, fill = 3) => {
  const setFill = (number) => {
    let result = ""
    for (let i = number; i < fill; i++) {
      result += "0"
    }
    return result
  }

  switch (String(value).length) {
    case 1:
      return setFill(1) + value
    case 2:
      return setFill(2) + value
    default:
      return value
  }
}

export const setFirstLetter = (string) => {
  if (typeof string !== "string") return ""
  return `${string.charAt(0)}`.toUpperCase() + `${string.slice(1)}`.toLowerCase()
}

/**
 * Métodos auxiliares para funções mais comuns
 */
export const sortList = (a, b, field, order = "asc") => {
  if (order === "asc") {
    if (a[field] > b[field]) return 1
    if (a[field] < b[field]) return -1
  } else {
    if (b[field] > a[field]) return 1
    if (b[field] < a[field]) return -1
  }
  return 0
}

/**
 * Método reponsável por formatar e converter um valor para Float
 * @param value         Valor a ser convertido
 * @returns {number}
 */
export const formatFloat = (value) => {
  if (typeof value === "string") {
    if (value.indexOf("R$") !== -1) {
      value = value.substring("R$ ".length).replace(".", "")
    }
    value = value.replace(",", ".")
  }

  return Number(parseFloat(value).toFixed(2))
}

export const formatBoolean = (value) => {
  return value === "true" || value === "1" || value === 1 || value === true
}

/**
 * Método responsável por formatar valor numérico em valor monetário
 * @param value         Valor numérico
 * @param prefix        Indica uso de prefixo monetário
 * @param free          Retorna "Grátis" quando valor igual a 0
 * @returns {string}
 */
export const formatMoney = (value, prefix = "R$ ", free = true) => {
  if (typeof value === "string") {
    value = value.replace(",", ".")
  }

  return parseFloat(value) > 0 || !free ? prefix + parseFloat(value).toFixed(2).replace(".", ",") : "Grátis"
}

export const onMessageDialog = (type = "success") => {
  let options = {
    customClass: {
      actions: "gap-3",
      icon: "m-10",
      title: "font-swal-title m-0",
      content: "font-weight-600 mb-10",
      color: "red",
    },
    buttonsStyling: false,
  }

  let customClass = {
    confirmButton: "btn btn-success m-10 pl-20 pr-20",
    cancelButton: "btn btn-primary m-10 pl-20 pr-20",
  }

  switch (type) {
    case "primary":
      customClass = {
        confirmButton: "btn btn-primary m-10 pl-20 pr-20",
        cancelButton: "btn btn-secondary m-10 pl-20 pr-20",
      }
      break
    case "success":
      customClass = {
        confirmButton: "btn btn-success m-10 pl-20 pr-20",
        cancelButton: "btn btn-primary m-10 pl-20 pr-20",
      }
      break
    case "danger":
      customClass = {
        confirmButton: "btn btn-danger m-10 pl-20 pr-20",
        cancelButton: "btn btn-primary m-10 pl-20 pr-20",
      }
      break
    case "warning":
      customClass = {
        confirmButton: "btn btn-info m-10 pl-20 pr-20",
        cancelButton: "btn btn-primary m-10 pl-20 pr-20",
      }
      break
    case "delete":
      customClass = {
        confirmButton: "btn btn-danger m-10 pl-20 pr-20",
        cancelButton: "btn btn-light m-10 pl-20 pr-20",
      }
      break
    case "loading":
      return Swal.mixin(options)
    default:
      break
  }

  return Swal.mixin({ ...options, customClass: { ...options.customClass, ...customClass } })
}

export const getFormatDate = (value) => {
  if (typeof value === "string" && value.indexOf("/") !== -1) {
    const dateSplit = value.split("/")
    value = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0]
  }

  const date = new Date(value)

  const setZero = (value) => {
    return String(value).length === 1 ? "0" + value : value
  }

  const getMonth = (value) => {
    value = value + 1
    return String(value).length === 1 ? "0" + value : value
  }

  const getDate = (format = "DD/MM/YYYY HH:mm:ss") => {
    switch (format) {
      case "DD/MM":
        return setZero(date.getDate()) + "/" + getMonth(date.getMonth())
      case "DD/MM/YYYY":
        return setZero(date.getDate()) + "/" + getMonth(date.getMonth()) + "/" + date.getFullYear()
      case "YYYY-MM-DD":
        return date.getFullYear() + "-" + getMonth(date.getMonth()) + "-" + setZero(date.getDate())
      case "DD/MM/YYYY HH:mm":
        return (
          setZero(date.getDate()) +
          "/" +
          getMonth(date.getMonth()) +
          "/" +
          date.getFullYear() +
          " " +
          setZero(date.getHours()) +
          ":" +
          setZero(date.getMinutes())
        )
      case "DD/MM: HH:mm:ss":
        return (
          setZero(date.getDate()) +
          "/" +
          getMonth(date.getMonth()) +
          " às " +
          setZero(date.getHours()) +
          ":" +
          setZero(date.getMinutes()) +
          ":" +
          setZero(date.getSeconds())
        )
      case "YYYY-MM-DD HH:mm":
        return (
          date.getFullYear() +
          "-" +
          getMonth(date.getMonth()) +
          "-" +
          setZero(date.getDate()) +
          " " +
          setZero(date.getHours()) +
          ":" +
          setZero(date.getMinutes())
        )
      case "YYYY-MM-DD HH:mm:ss":
        return (
          date.getFullYear() +
          "-" +
          getMonth(date.getMonth()) +
          "-" +
          setZero(date.getDate()) +
          " " +
          setZero(date.getHours()) +
          ":" +
          setZero(date.getMinutes()) +
          ":" +
          setZero(date.getSeconds())
        )
      default:
        return (
          setZero(date.getDate()) +
          "/" +
          getMonth(date.getMonth()) +
          "/" +
          date.getFullYear() +
          " " +
          setZero(date.getHours()) +
          ":" +
          setZero(date.getMinutes()) +
          ":" +
          setZero(date.getSeconds())
        )
    }
  }

  const getHour = (format = "hh:mm") => {
    if (format === "hh:mm") {
      return setZero(date.getHours()) + ":" + setZero(date.getMinutes())
    } else {
      return setZero(date.getHours()) + ":" + setZero(date.getMinutes()) + ":" + setZero(date.getSeconds())
    }
  }

  return { getDate, getHour }
}

export const getUrlAWSFile = (key, defaultImage) => {
  return !!key ? (key.indexOf("blob:") === -1 ? `${process.env.REACT_APP_S3_PUBLIC_HOST}/${key}` : key) : defaultImage
}

export const getUrlAWSManagerFile = (key, defaultImage) => {
  return !!key ? (key.indexOf("blob:") === -1 ? `${process.env.REACT_APP_S3_MANAGER_HOST}/${key}` : key) : defaultImage
}

// Método responsável por detectar se o site é acessado por um dispositivo móvel
export const isMobile = () => {
  return !!(
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}

export const isAppleDevice = () => {
  return !!(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i))
}

export const formatPhone = (value, mask = true) => {
  if (!!value) {
    if (mask) {
      switch (String(value).length) {
        case 8:
          return value.replace(/^(\d{4})(\d{4}).*/, "$1-$2")
        case 10:
          return value.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
        default:
          return value.replace(/^(\d{2})(\d)(\d{4})(\d{4}).*/, "($1) $2 $3-$4")
      }
    } else {
      return String(value).replace(/[()-/\s]/g, "")
    }
  }

  return ""
}

export const capitalize = (string) => {
  if (string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  } else {
    return ""
  }
}

export const getJsonParsed = (jsonData, defaultData = null) => {
  let data
  if (!!jsonData) {
    const jsonDataType = Object.getPrototypeOf(jsonData)?.constructor?.name
    if (!!jsonDataType) {
      switch (jsonDataType) {
        case "String": {
          return JSON.parse(jsonData)
        }
        case "Object": {
          return jsonData
        }
        case "Array": {
          return jsonData
        }
        default: {
          return jsonData
        }
      }
    } else if (!!defaultData) {
      data = defaultData
    } else {
      data = null
    }
  } else if (!!defaultData) {
    data = defaultData
  } else {
    data = null
  }

  return data
}

export const escapeSpecialChart = (string) => {
  return string
    .replace(/[ÀÁÂÃÄÅ]/g, "A")
    .replace(/[àáâãäå]/g, "a")
    .replace(/[ÈÉÊË]/g, "E")
    .replace(/[èéêë]/g, "e")
    .replace(/[ÌÍÎÏ]/g, "I")
    .replace(/[ìíîï]/g, "i")
    .replace(/[ÒÓÔÕÖ]/g, "O")
    .replace(/[òóôõö]/g, "o")
    .replace(/[ÙÚÛÜ]/g, "U")
    .replace(/[ùúûü]/g, "u")
    .replace(/[^a-z0-9]/gi, "")
}


const GeneralTools = {
  /**
   * Método responsável por encriptar valores
   * @param value     Valor a ser encriptado
   * @param type      Tipo de criptografia
   */
  encrypt(value, type) {
    switch (type) {
      case "sha224":
        return sha224(value)
      case "sha256":
        return sha256(value)
      case "base64":
        return btoa(value)
      default:
        return sha224(value)
    }
  },

  /**
   * Método responsável por preencher valores numéricos com 0
   * @param value         Valor numérico
   * @param fill          Quantidade de casas a preencher
   * @returns {*}
   */
  setNumber(value, fill = 3) {
    const setFill = (number) => {
      let result = ""
      for (let i = number; i < fill; i++) {
        result += "0"
      }
      return result
    }

    switch (String(value).length) {
      case 1:
        return setFill(1) + value
      case 2:
        return setFill(2) + value
      default:
        return value
    }
  },

  setFirstLetter(string) {
    if (typeof string !== "string") return ""
    return `${string.charAt(0)}`.toUpperCase() + `${string.slice(1)}`.toLowerCase()
  },

  /**
   * Método responsável por formatar e converter um valor para Float
   * @param value         Valor a ser convertido
   * @returns {number}
   */
  parseFloat(value) {
    if (typeof value === "string") {
      if (value.indexOf("R$") !== -1) {
        value = value.substring("R$ ".length).replace(".", "")
      }
      value = value.replace(",", ".")
    }

    return Number(parseFloat(value).toFixed(2))
  },

  /**
   * Método reponsável por formatar e converter um valor para Boolean
   * @param value         Valor a ser convertido
   * @returns {boolean}
   */
  parseBoolean(value) {
    return value === "true" || value === "1" || value === 1 || value === true
  },

  /**
   * Método responsável por formatar valor numérico em valor monetário
   * @param value         Valor numérico
   * @param prefix        Indica uso de prefixo monetário
   * @param free          Retorna "Grátis" quando valor igual a 0
   * @returns {string}
   */
  formatMoney(value, prefix = "R$ ", free = true) {
    if (typeof value === "string") {
      value = value.replace(",", ".")
    }

    return parseFloat(value) > 0 || !free ? prefix + parseFloat(value).toFixed(2).replace(".", ",") : "Grátis"
  },

  formatPhone(value, mask = true) {
    if (!!value) {
      if (mask) {
        switch (String(value).length) {
          case 8:
            return value.replace(/^(\d{4})(\d{4}).*/, "$1-$2")
          case 10:
            return value.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
          default:
            return value.replace(/^(\d{2})(\d)(\d{4})(\d{4}).*/, "($1) $2 $3-$4")
        }
      } else {
        return String(value).replace(/[()-/\s]/g, "")
      }
    }

    return null
  },

  /**
   * Método responsável abrir automaticamente o WhatApp do usuário
   */
  openWhatsApp(event, number, text = "") {
    event.preventDefault()

    let formatedNumber = number.replace(/[^\d]+/g, "")
    let removeDigit = ""

    if (formatedNumber.length > 10) {
      removeDigit = number.split(" ")
      removeDigit.splice(1, 1)
      removeDigit = removeDigit.join("")
      formatedNumber = removeDigit.replace(/[^\d]+/g, "")
    }

    window.open(`https://wa.me/55${formatedNumber}?text=${text}`)
  },

  openPhone(event, number) {
    event.preventDefault()
    window.open(`tel:+${number}`, "_system")
  },

  swalMessage(type = "success") {
    let options = {
      customClass: {
        actions: "gap-3",
        icon: "m-10",
        title: "font-swal-title m-0",
        content: "font-20 font-weight-600 mb-10",
        color: "red",
      },
      buttonsStyling: false,
    }

    let customClass = {
      // confirmButton: "",
      // cancelButton: "",
      confirmButton: "btn btn-success m-10 pl-20 pr-20",
      cancelButton: "btn btn-primary m-10 pl-20 pr-20",
    }

    switch (type) {
      case "primary":
        customClass = {
          confirmButton: "btn btn-primary m-10 pl-20 pr-20",
          cancelButton: "btn btn-secondary m-10 pl-20 pr-20",
        }
        break
      case "success":
        customClass = {
          confirmButton: "btn btn-success m-10 pl-20 pr-20",
          cancelButton: "btn btn-primary m-10 pl-20 pr-20",
        }
        break
      case "danger":
        customClass = {
          confirmButton: "btn btn-danger m-10 pl-20 pr-20",
          cancelButton: "btn btn-primary m-10 pl-20 pr-20",
        }
        break
      case "warning":
        customClass = {
          confirmButton: "btn btn-info m-10 pl-20 pr-20",
          cancelButton: "btn btn-primary m-10 pl-20 pr-20",
        }
        break
      case "delete":
        customClass = {
          confirmButton: "btn btn-danger m-10 pl-20 pr-20",
          cancelButton: "btn btn-light m-10 pl-20 pr-20",
        }
        break
      case "loading":
        return Swal.mixin(options)
      default:
        break
    }

    return Swal.mixin({ ...options, customClass: { ...options.customClass, ...customClass } })
  },

  getNameAdditional(additional) {
    if (!!additional.lb_additional) {
      return additional.lb_additional
    } else {
      return additional.nm_additional
    }
    // }
  },

  getNameComplement(complement, additional) {
    if (`${complement.nm_complement}`.toLowerCase() === "borda") {
      if (`${additional.nm_additional}`.toLowerCase() === "sem borda") {
        return "Sem borda"
      }

      return `Borda de ${additional.nm_additional}`
    }
    if (`${complement.nm_complement}`.toLowerCase() === "massa") {
      return `Massa ${additional.nm_additional}`
    }

    return `${complement.nm_complement} ${additional.nm_additional}`
  },
}

export default GeneralTools
