import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CollapseItem from '../../components/CollapseItem'

// Sytles
import './ModalAdditional.scss'

const ModalAdditional = ({ status, title, content, onClose, finishBuilderOrder, onChangeAdditional}) => {

    return (
        <Modal
            isOpen={status}
            toggle={onClose}
            size={"xl"}
            className={"modal-additional"}
        >
            <ModalHeader toggle={onClose} onClick={onClose}>
                <div className="button-go-back-mobile" onClick={onClose}>
                    <FontAwesomeIcon icon="arrow-left"/>
                </div>
                {title}
            </ModalHeader>

            <ModalBody>
                {!!content && !!content.product && !!content.selectedProduct && (

                    <CollapseItem
                        type="additional"
                        status={true}
                        title="Escolha os adicionais"
                        content={content.product.additionals}
                        selected={content.selectedProduct.additionals}
                        onClick={(additional) => {
                            onChangeAdditional(additional, content.product.id_product, 1)
                        }}
                        onChangeAmount={() => {}}
                        size={content.selectedProduct.size}
                    />
                )}

            </ModalBody>
            <ModalFooter>
                <div className="button-go-back">
                    <Label
                        onClick={onClose}
                        className="footer-button-left"
                    >
                        <FontAwesomeIcon icon="arrow-left"/>&nbsp;Voltar
                    </Label>
                </div>
                <Label
                    onClick={finishBuilderOrder}
                    className="footer-button-right"
                >
                    <FontAwesomeIcon icon="check"/>&nbsp;
                    {
                        !!content && !!content.size && !!content.size.st_mandatory_beverage === 1 ? "Adicionar a Sacola" : "Continuar"
                    }
                </Label>

            </ModalFooter>
        </Modal>
    )
};

export default ModalAdditional;