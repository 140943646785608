import React from "react"

// Resources
import GeneralTools, { getUrlAWSManagerFile } from "../../helpers/GeneralTools"

// Contexts
import SettingsContext from "../../contexts/SettingsContext"

// Styles
import "./Highlights.scss"

const Highlights = ({ onClickHighlight }) => {
  const { highlights } = React.useContext(SettingsContext)

  // const [highlights, setHighlights] = React.useState([])
  // const [separatorSelected, setSeparatorSelected] = React.useState(0)

  const onMoveBySeparator = (id, key) => {
    document.getElementById(id).scrollIntoView({ block: "center", inline: "center", behavior: "smooth" })
    // setSeparatorSelected(key)
  }

  if (highlights.length === 0) {
    return ""
  }

  return (
    <section id="highlights" className="section-highlights">
      {!!highlights ? (
        <>
          <div
            // onScroll={scrollHighlights}
            id="separator-content"
            className="section-highlights--content"
          >
            {highlights.map((item, key) => (
              <div
                id={item.id_highlight + "hl"}
                key={key}
                className="section-highlights--content--card-highlight"
                onClick={() => onClickHighlight("highlight", item)}
              >
                <img src={getUrlAWSManagerFile(item.url_highlight_image)} alt="" />
              </div>
            ))}
          </div>

          <div className="section-highlights--content--separator">
            {highlights.map((item, key) => (
              <div
                key={key}
                className="section-highlights--content--separator--item"
                // className={`section-highlights--content--separator--item ${separatorSelected === key ? "separator-selected" : ""}`}
                onClick={() => onMoveBySeparator(item.id_highlight + "hl", key)}
              />
            ))}
          </div>
        </>
      ) : (
        ""
      )}
    </section>
  )
}

export default Highlights
