import axios from "axios"
import axiosRetry from "axios-retry"

import { getLocalItem, getCookie } from "./StorageTools"

/**
 * Componente funcional para auxiliar no envio e recebimento de dados via API
 * Utilizada a biblioteca (Axios) para realizar as requisições
 */
const API = {
  /**
   * Método responsável por ralizar um determinada requisição
   * @param url           Rota (endpoint) da requisição
   * @param params        Parametros da requisição
   * @param method        Metodo da requisição
   * @param headers       Cabeçalhos da requisição
   * @param timeout       Tempo de conexão
   * @param retries       Número de tentativas
   * @returns {*}
   */
  async request(url, params, method = "GET", headers = {}, timeout = 30000, retries = 3) {
    // Obtendo o token da sessão
    const token = await getCookie("publicToken")

    const database = getLocalItem("database")
    const company = getLocalItem("company", true)

    // console.log("company", company)

    // Definindo as configurações da requisição
    let config = {
      // Endereço da API
      baseURL: process.env.REACT_APP_CF_API_HOST,

      // Rota da requisição
      url: url,

      // Método da requisição
      method: method,

      // Cabeçalho da requisição
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        Database: database,
        Company: company?.uuid_company,
        Companyhead: company?.uuid_company,
        Token: process.env.REACT_APP_CF_SITE_TOKEN,
      },

      // Tempo para execução (10s)
      timeout: timeout,

      withCredentials: false,
    }

    // Caso for passado cabeçalhos diferente do padrão, unifica os registros
    if (!!headers) {
      // Juntando os dados de cabeçalhos
      // Aqui é utilizado o operador "Spread"
      config.headers = { ...config.headers, ...headers }
    }

    // Definindo cabeçalho de autorização
    if (!!token) {
      config.headers = { ...config.headers, ...{ Authorization: `Bearer ${token}` } }
    }

    // Verificando se foi informado parametros para a requiição
    // Caso o método seja GET coloca os parametros na URL
    if (!!params) {
      if (method === "GET") {
        config.params = params
      } else {
        config.data = params
      }
    }

    // console.log(config);

    axiosRetry(axios, {
      retries,
      retryCondition: axiosRetry.isNetworkError,
      retryDelay: (retryCount) => {
        return retryCount * 1000
      },
      onRetry: (retryCount, error) => {
        console.warn(`TENTATIVA ${retryCount}`, error)
      },
    })

    // Executando a requisição
    return await axios
      .request(config)
      .then((response) => {
        // Verificando se no objeto retornado existe o item "data"
        if (!!response.data.status && !!response.data.message && !!response.data.data) {
          return { status: response.status, message: response.data.message, data: response.data.data }
        }

        return { status: response.status, data: response.data }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("Request", error.request)
        // console.log("Response", error.response)
        // console.log("Message", error.message)
        // console.log("Config", error.config)

        // console.clear();

        if (error.response) {
          return { status: error.response.status, data: error.response.data }
        }

        return {
          status: 500,
          message: "Ocorreu um erro de comunicação com o servidor. Favor tente mais tarde!",
        }
      })
  },
}

export default API
