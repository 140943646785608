// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const SupportController = {
  throwNewLog({ type = "info", content = null, origin = "site", message = "" }) {
    return API.request("/v1/support/throw/log", { type, content, origin, message }, "POST")
  },

  getWebsiteVersion() {
    return API.request("/v1/version/site_cityfoods_subscribers", {}, "GET")
  }
}

export default SupportController
