// Componentes internos
import API from "../helpers/API"

const FlavourController = {
  fetchOne(flavourUid, productUid, withIngredients = false, withOptionals = false, withUtensilsSizes = false) {
    return API.request("/v1/flavour/one", { flavourUid, productUid, withIngredients, withOptionals, withUtensilsSizes }, "GET")
  },
}

export default FlavourController
