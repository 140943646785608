import React from "react"
//External components
import { Modal, ModalBody, ModalHeader, ModalFooter, Label, Input, Progress, TabContent } from "reactstrap"


// Images
import SecureIcon from "../../../assets/img/icons/secure.png"

//Styles
import "./ModalPixPayment.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import copy from "copy-to-clipboard";
import toast from 'react-hot-toast';
import moment from "moment"

import QRCode from "react-qr-code"
import PaymentsController from "../../../controllers/PaymentsController"
import TabContext from "../../../contexts/TabContext"

const ModalPixPayment = ({ status, content, onClose }) => {
  const [seconds, setSeconds] = React.useState(null)

  const { goToOrderProggressAfterOnlinePayment } = React.useContext(TabContext)

  React.useEffect(async () => {
    if (!!content) {
      let dtCreatedAt = moment(content.dt_created_at)
      const dtExpiresAt = moment(content.dt_created_at).add(5, "minutes")

      const secondsToExpire = dtExpiresAt.diff(dtCreatedAt, "seconds")

      setSeconds(secondsToExpire)
    }
  }, [content])

  React.useEffect(() => {
    if (!!content) {
      if (seconds > 0) {
        const interval = setInterval(() => {
          setSeconds(seconds - 1)

          const dt_created_at = moment(content.dt_created_at)
          const now = moment()
          const diff = now.diff(dt_created_at, "seconds")

          if (moment.duration(300 - diff, 'seconds')._data.seconds < 0) {
            onClose()
          }
        }, 1000)

        return () => clearInterval(interval)
      } else {
        onCancelPendingOrder(content)
      }
    }
  }, [seconds])

  React.useEffect(() => {
    if (status && !!content) {
      const interval = setInterval(async () => {
        const request = await PaymentsController.getOrderByPaymentRef(content.paymentId)

        if (request.status === 200) {
          goToOrderProggressAfterOnlinePayment(request.data.uuid_order)
        }
      }, 30000)

      return () => clearInterval(interval)
    }
  }, [status])

  const onCancelPendingOrder = (content) => {
    console.log(content.paymentId)
  }

  if (status && !!content) {
    const dt_created_at = moment(content.dt_created_at)
    const now = moment()
    const diff = now.diff(dt_created_at, "seconds")

    return (
      <Modal
        isOpen={status}
        toggle={() => {
          onClose()
        }}
        backdrop={"static"}
        fade={true}
        className="modal-pix-payment"
      >
        <ModalHeader className="modal-pix-payment--header">
          <div className="modal-pix-payment--header--left">
            <button onClick={onClose}>
              <FontAwesomeIcon icon="chevron-left" />
            </button>
          </div>
          <div className="modal-pix-payment--header--center">
            <h2>Pague seu pedido</h2>
          </div>
          <div />
        </ModalHeader>
        <ModalBody className="modal-pix-payment--body">

          <div className="modal-pix-payment--body--top">
            <img className="modal-pix-payment--body--top-secure" src={SecureIcon} alt="Ícone de pagamento seguro" />
            <div className="modal-pix-payment--body--top-qrimage">
              <QRCode value={content.pixCode} />
            </div>
            <div className="modal-pix-payment--body--top--pix">
              <h1>
                Código copia e cola
              </h1>
              <div className="modal-pix-payment--body--top--pix--code" onClick={async () => {
                copy(content.pixCode)
                toast.success("Código copiado com sucesso")
              }}>
                <Input className="modal-pix-payment--body--top--pix--code--input" readOnly value={content.pixCode} />
                <button className="modal-pix-payment--body--top--pix--code--input-copy">
                  <FontAwesomeIcon icon="copy" />
                </button>
              </div>
            </div>
            <div className="modal-pix-payment--body--center">
              <h1>
                Pedido aguardando pagamento
              </h1>
              <p>Copie ou escaneie o código no aplicativo do seu banco para realizar o pagamento.</p>

            </div>
          </div>
        </ModalBody>
        <ModalFooter className="modal-pix-payment--footer">
          <div className="modal-pix-payment--footer--progress">
            <h2>Tempo para pagar</h2>
            <h3>0{moment.duration(300 - diff, 'seconds')._data.minutes}:{moment.duration(300 - diff, 'seconds')._data.seconds <= 9 ? "0" + moment.duration(300 - diff, 'seconds')._data.seconds : moment.duration(300 - diff, 'seconds')._data.seconds}</h3>
            <Progress min={0} max={300} value={moment.duration(300 - diff, 'seconds')._data.seconds + (moment.duration(300 - diff, 'seconds')._data.minutes * 60)} barStyle={{ backgroundColor: "#df2833" }} animated />
          </div>
        </ModalFooter>
      </Modal>
    )
  }

  return ""
}

export default ModalPixPayment
