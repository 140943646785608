// Resources
import API from "../helpers/API"
import GeneralTools from "../helpers/GeneralTools"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const BenefitController = {
  getAll() {
    return API.request("/v1/benefit/all", [], "GET")
  },
  getOne(benefitCode) {
    return API.request("/v1/benefit/one/" + benefitCode, [], "GET")
  },
  validateBenefit(params) {
    return API.request("/v1/benefit/validate", params, "GET")
  },
}

export default BenefitController
