import React from "react"

import "./CFObservationInput.scss"

const CFObservationInput = ({ title = "OBSERVAÇÃO", value = "", onChange, placeholder = "" }) => {
  const [textValue, setTextValue] = React.useState("")
  const [limitTextValueSize, setLimitTextValueSize] = React.useState(false)

  const onChangeText = (value) => {
    if (textValue.length < 140) {
      setTextValue(value)
      onChange(value)
    } else if (textValue.length === 140) {
      setLimitTextValueSize(true)
    } else if (textValue.length > 140) {
      setTextValue(value.substring(0, 139))
      onChange(value.substring(0, 140))
    }
  }

  return (
    <div className="observation-input">
      <div className="observation-input--top">
        <span className="observation-input--top--title">{title}</span>
        <span className="observation-input--top--size">{`${value.length} / 140`}</span>
      </div>
      <div className={"observation-input--bottom" + (limitTextValueSize ? " text-on-limit-size" : "")}>
        <input value={value} onChange={(event) => onChangeText(event.target.value)} placeholder={placeholder} />
      </div>
    </div>
  )
}

export default CFObservationInput
