import React from "react"
import { Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Modals
import MainOptions from "./MainOptions"

// Contexts
import SettingsContext from "../contexts/SettingsContext"

// Resources
import GeneralTools, { getUrlAWSManagerFile } from "../helpers/GeneralTools"
// import { setLocalItem, getLocalItem, removeLocalItem } from "../helpers/StorageTools"
// import Message from "./Message"

//Controllers
// import UserController from "../controllers/UserController"
import CompanyContext from "../contexts/CompanyContext"
// import customerController from "../controllers/CustomerController"

// Styles
import headerBackground from "../assets/img/general/header-background.jpg"
import "./Header.scss"

const Header = ({ origin = "home", setModalCompanies }) => {
  const { company } = React.useContext(CompanyContext)
  const { settings } = React.useContext(SettingsContext)
  //
  // const [update, setUpdate] = React.useState(false)
  //
  // const [modalSignIn, setModalSignIn] = React.useState(false)
  // const [modalSignUp, setModalSignUp] = React.useState(false)

  const [backgroundImage, setBackgroundImage] = React.useState(null)

  /* eslint-disable */
  React.useEffect(() => {
    getBackgroundImage(settings)

    window.addEventListener("resize", () => {
      getBackgroundImage(settings)
    })
  }, [settings])

  // const onSignIn = async (username, password = null) => {
  //   const response = await UserController.signIn(username, !!password ? btoa(password) : null)
  //   if (response.status === 200) {
  //     const { data: user } = response
  //     let addresses = []
  //     if (!!user) {
  //       customerController.fetchOne(user.uuid_customer).then((responseCustomer) => {
  //         if (responseCustomer.data.streets?.length > 0) {
  //           let primaryAddress = null
  //           primaryAddress = responseCustomer.data.streets.find(({ customer_address: { st_primary } }) => st_primary)
  //           if (!primaryAddress) {
  //             primaryAddress = responseCustomer.data.streets[0]
  //           }
  //           const deliveryFees = getLocalItem("deliveryFees", true)
  //
  //           //Verificando se o endereço selecionado está dentro da área de entrega.
  //
  //           const deliveryIndex = deliveryFees.findIndex(
  //             ({ fk_id_company, neighbourhood: { id_neighbourhood } }) =>
  //               fk_id_company === company.id_company && id_neighbourhood === primaryAddress.neighbourhood.id_neighbourhood
  //           )
  //
  //           if (deliveryIndex !== -1) {
  //             setUserSelectedAddress(primaryAddress)
  //             setLocalItem("userAddress", primaryAddress, true)
  //           }
  //         }
  //       })
  //     }
  //
  //     const userSession = {
  //       uuid_user: user.uuid_user,
  //       nm_user: user.nm_user,
  //       profile_photo: user.profile_photo,
  //       uuid_customer: user.uuid_customer,
  //       addresses: addresses,
  //     }
  //
  //     setLocalItem("user", userSession, true)
  //
  //     setUpdate(!update)
  //     setModalSignIn(false)
  //     return true
  //   } else {
  //     Message.error("Usuário ou senha incorretos!")
  //   }
  //
  //   return false
  // }

  // const signOut = () => {
  //   removeLocalItem("user")
  //   setUpdate(!update)
  // }

  const getBackgroundImage = (settings = {}) => {
    let background = null
    if (window.innerWidth <= 380) {
      background = settings.site_banner_celular
    } else if (window.innerWidth <= 480) {
      background = settings.site_banner_celular
    } else if (window.innerWidth <= 600) {
      background = settings.site_banner_celular
    } else if (window.innerWidth <= 768) {
      background = settings.site_banner_note
    } else if (window.innerWidth <= 900) {
      background = settings.site_banner_note
    } else if (window.innerWidth <= 1024) {
      background = settings.site_banner_note
    } else if (window.innerWidth <= 1280) {
      background = settings.site_banner_note
    } else if (window.innerWidth <= 1600) {
      background = settings.site_banner_desktop
    } else if (window.innerWidth <= 1920) {
      background = settings.site_banner_desktop
    } else if (window.innerWidth <= 2560) {
      background = settings.site_banner_tv
    } else {
      background = settings.site_banner_tv
    }

    background = !!background ? getUrlAWSManagerFile(background) : headerBackground

    setBackgroundImage(`url(${background}) no-repeat center center`)
  }

  return (
    <header id="header" className="section-header" style={{ background: backgroundImage }}>
      <div className="top-side">
        <div className="left-side">
          {!!settings.site_logo && GeneralTools.parseBoolean(settings.site_display_logo) && (
            <div className="img-logo img-left">
              <img alt="" src={getUrlAWSManagerFile(settings.site_logo)} />
            </div>
          )}
        </div>
        <div className="center">
          {!!settings.site_display_name_company && GeneralTools.parseBoolean(settings.site_display_name_company) ? (
            <div className="center--company">
              <strong>{!!company && !!company.nm_company ? company.nm_company : ""}</strong>
            </div>
          ) : (
            ""
          )}

          {/* {!!settings.site_logo && GeneralTools.parseBoolean(settings.site_display_logo) && (
            <div className="img-logo img-center">
              <img alt="" src={getUrlAWSManagerFile(settings.site_logo)} />
            </div>
          )} */}
        </div>
        <div className={"right-side"}>
          <div className="buttons">
            {(() => {
              if (!!company) {
                return (
                  <Label className="company" onClick={setModalCompanies}>
                    <FontAwesomeIcon className="icon" icon="home" />
                    &nbsp;{company.nm_company || "Não identificado"}
                  </Label>
                )
              } else {
                return (
                  <Label className="signin" onClick={setModalCompanies}>
                    <FontAwesomeIcon className="icon" icon="home" />
                    &nbsp; Escolha o seu estabelecimento
                  </Label>
                )
              }
            })()}

            {/*{(() => {*/}
            {/*  const userSession = getLocalItem("user", true)*/}

            {/*  if (!!userSession) {*/}
            {/*    return (*/}
            {/*      <>*/}
            {/*        <Label className="username">Olá, {userSession.nm_user}</Label>*/}
            {/*        <Label className="signin" onClick={signOut}>*/}
            {/*          <FontAwesomeIcon className="icon" icon="sign-out-alt" />*/}
            {/*          &nbsp;Sair*/}
            {/*        </Label>*/}
            {/*      </>*/}
            {/*    )*/}
            {/*  } else {*/}
            {/*    return (*/}
            {/*      <Label className="signin" onClick={() => setModalSignIn(true)}>*/}
            {/*        <FontAwesomeIcon className="icon" icon="sign-in-alt" />*/}
            {/*        &nbsp;Entre ou cadastre-se*/}
            {/*      </Label>*/}
            {/*    )*/}
            {/*  }*/}
            {/*})()}*/}
          </div>
        </div>
      </div>
      <div className="bottom-side">
        <div className="left-side" />

        <div className="center" />

        <div className={(origin === "order" ? "display-none" : "") + " right-side"}>
          <div className="header-main-options-container">
            <MainOptions type="largeDevices" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
