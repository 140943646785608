import React from "react"

import "./CFInput.scss"

const CFInput = ({ disabled, value, style, placeholder, type = "text", onChange = () => null, autoFocus }) => {
  return (
    <input
      type={type}
      className="component-input"
      value={value}
      onChange={onChange}
      style={style}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  )
}

export default CFInput
