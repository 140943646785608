import React from "react"

//Components
import CFCheckButton from "../CFCheckButton/CFCheckButton"

import "./ScheduleWorkingTime.scss"
import moment from "moment"

const ScheduleWorkingTime = ({ contentData, onSelectWorkingTime, selected = false }) => {
  const hrStartSplit = contentData.hr_start.split(":")
  const hrEndSplit = contentData.hr_end.split(":")

  return (
    <div className="schedule-working-time" onClick={onSelectWorkingTime}>
      <CFCheckButton radio={true} status={selected} />
      {`${moment(new Date().setHours(hrStartSplit[0], hrStartSplit[1], hrStartSplit[2])).format("HH:mm")} - ${moment(
        new Date().setHours(hrEndSplit[0], hrEndSplit[1], hrEndSplit[2])
      ).format("HH:mm")}`}
    </div>
  )
}

export default ScheduleWorkingTime
