// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const AddressController = {
  zipcode(zipcode) {
    return API.request("/v1/address/street/zipcode/" + zipcode, [], "GET")
  },

  streeTypes() {
    return API.request("/v1/address/street-type", [], "GET")
  },

  states() {
    return API.request("/v1/address/state", [], "GET")
  },

  citiesByState(uuidState) {
    return API.request(`/v1/address/city/state/${uuidState}`, [], "GET")
  },

  cityByName(cityName, uuidState) {
    return API.request(`/v1/address/city/state/name/one`, { cityName, uuidState }, "GET")
  },


  neighbourhoodByCity(uuidCity) {
    return API.request(`/v1/address/neighbourhood/city/${uuidCity}`, [], "GET")
  },
}

export default AddressController
