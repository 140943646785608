import React from "react"

import { Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Contexts
import MenuContext from "../contexts/MenuContext"
import CompanyContext from "../contexts/CompanyContext"

// Styles
import "./Menu.scss"

const Menu = () => {
  const { company } = React.useContext(CompanyContext)
  const { menuOptions, menuFixed, updateMenu } = React.useContext(MenuContext)
  // const [isCategoryMenuOpen, setIsCategoryMenuOpen] = React.useState(false)

  // const onChangeMenuOption = (event, id, label) => {
  //   updateMenu(event, id)
  //   setIsCategoryMenuOpen(false)

  const showingMenu = menuOptions.filter(({ visible, companyId }) => visible && companyId === company.id_company)

  // console.log("menuOptions", { menuOptions, showingMenu })

  return (
    <div className={menuFixed ? "menu-options-fixed" : "menu-options"}>
      <div className="menu-options-items">
        {showingMenu.map(({ companyId, id, label, focus, icon, visible }, key) => {
          return (
            <Label key={key} className={focus ? "active" : ""} onClick={(event) => updateMenu(event, id)}>
              {icon ? <FontAwesomeIcon icon={icon} /> : ""}
              &nbsp;{label}
            </Label>
          )
        })}
      </div>
    </div>
  )
}
export default Menu
