// Resources
import API from "../helpers/API"
import { getCookie } from "../helpers/StorageTools"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const UserController = {
  updatePass(customerUid, password, oldPassword) {
    let params = {}

    if (!!password) params = { ...params, password }
    if (!!oldPassword) params = { ...params, oldPassword }

    return API.request("/v1/user/password/" + customerUid, params, "PUT")
  },

  async recoveryPass(customerUid, type, user) {
    const params = {
      customerUid,
      type,
      user,
      //    subscriberUid: await getCookie("subscriberUid").then(),
    }

    return API.request("/v1/auth/recovery", params, "POST")
  },

  async verify(username, secondaryLogin) {
    const params = {
      login: btoa(username),
      secondaryLogin: btoa(secondaryLogin),
      origin: "subscriber-site",
    }
    return API.request("/v1/auth/verify", params, "GET", {})
  },

  async verifyContacts(username, secondaryLogin, uidCustomer) {
    const params = {
      login: btoa(username),
      secondaryLogin: btoa(secondaryLogin),
      origin: "subscriber-site",
      customer: uidCustomer,
    }
    return API.request("/v1/customer/verify/contacts", params, "GET")
  },

  async update(userUid, customerUid, data) {
    return API.request("/v1/user/website/" + userUid + "/" + customerUid, data, "PUT")
  },

  fetchDelivery(filter = {}, order = {}, pagination = {}) {
    const params = {
      orderField: order.orderField || "name",
      orderKeyword: order.orderKeyword || "asc",
      pageNumber: pagination.pageNumber || 1,
      pageSize: pagination.pageSize || 1000,
      ...filter,
    }

    return API.request("/v1/user/all/delivery", params, "GET")
  },
}

export default UserController
