import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import QuantityControl from "../QuantityControl/QuantityControl"
import { formatMoney } from "../../helpers/GeneralTools"

import noImage from "../../assets/img/builder/no-image.png"
import "./BeverageItem.scss"

const BeverageItem = ({ isCombo = false, beverageData = {}, productBeverage, changeAmount }) => {
  // console.log("beverageData", beverageData)

  const getBeverageValue = () => {
    const beverageValue = beverageData.val_price * (beverageData.amount || 1)
    if (!!isCombo) {
      return beverageValue > 0 ? formatMoney(beverageValue) : "Sem exd."
    }
    return beverageValue > 0 ? formatMoney(beverageValue) : "Grátis"
  }

  return (
    <div className="beverage-item">
      <div className="beverage-item--data">
        <div className="beverage-item--data--left">
          <img alt="" className="beverage-item--data--left--image" src={beverageData.image || noImage} />
          {!isCombo && !productBeverage ? (
            <div className="beverage-item--data--left--edit-button">
              <FontAwesomeIcon icon={"pen"} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="beverage-item--data--center">
          <span className="beverage-item--data--center--category">{beverageData.category}</span>
          <span className="beverage-item--data--center--name">{beverageData.name}</span>
        </div>
        <div className="beverage-item--data--right">
          <span>{getBeverageValue()}</span>
          {!isCombo && !productBeverage ? (
            <QuantityControl amount={beverageData.amount} onChange={(amount) => changeAmount(beverageData.id_beverage, amount)} />
          ) : (
            ""
          )}
        </div>
      </div>

      {!isCombo ? <div className="beverage-item--divisor" /> : ""}
    </div>
  )
}

export default BeverageItem
