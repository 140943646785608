import React from "react"
import Alert from "react-s-alert"

// Estilos externos
import "react-s-alert/dist/s-alert-default.css"
import "react-s-alert/dist/s-alert-css-effects/scale.css"
import "react-s-alert/dist/s-alert-css-effects/genie.css"
import "react-s-alert/dist/s-alert-css-effects/jelly.css"

/**
 * Componente funcional
 * Utilizada a biblioteca (Alert) para definir a caixinha de alerta
 */
const Message = {
  /**
   * Método responsável por exibir a mensagem na tela
   * @param message           Mensagem a ser exibida
   * @param type              Tipo da mensagem
   * @param timeout           Tempo que a mensagem ficará na tela
   * @param position          Posição da mensagem na tela
   * @param effect            Efeito de exibição da mensagem
   */
  show(message, type = "error", timeout = 3000, position = "top", effect = "jelly") {
    const options = { position: position, effect: effect, timeout: timeout }

    switch (type) {
      case "success":
        Alert.success(message, options)
        break
      case "info":
        Alert.info(message, options)
        break
      case "warning":
        Alert.warning(message, options)
        break
      case "error":
        Alert.error(message, options)
        break
      default:
        break
    }
  },

  success(message) {
    this.show(message, "success")
  },

  info(message) {
    this.show(message, "info")
  },

  warning(message) {
    this.show(message, "warning")
  },

  error(message, error = null) {
    if (!!error) console.error(error)
    this.show(message, "error")
  },
}

export default Message
