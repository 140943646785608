import React from "react"
//External libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import CFButton from "../../../components/CFButton/CFButton"

//Context
import CompanyContext from "../../../contexts/CompanyContext"

//Helpers
import { isMobile } from "../../../helpers/GeneralTools"

import "./ModalCompanyContacts.scss"

const ModalCompanyContacts = ({ onClose }) => {
  const { company } = React.useContext(CompanyContext)
  const handleToggleCkick = (event) => {
    if (event.target.id === "id-modal-company-contacts") {
      onClose()
    }
  }

  const getCompanyContacts = () => {
    let contacts = []
    if (!!company) {
      company.company_contact.forEach((contact, index) => {
        const {
          contact: { ds_contact },
          val_contact,
        } = contact

        if (`${ds_contact}`.toLowerCase() === "whatsapp") {
          contacts.push(
            <a
              className={"modal-company-contacts--content--body--bottom--contact"}
              key={val_contact + "_" + ds_contact + "_" + index}
              href={`https://wa.me/+55${val_contact.replace(/[^0-9]/g, "")}?text=Gostaria de informações sobre meu agendamento`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "whatsapp"]} />
            </a>
          )
        }

        if (`${ds_contact}`.toLowerCase() === "celular" && isMobile()) {
          contacts.push(
            <a
              className={"modal-company-contacts--content--body--bottom--contact"}
              key={val_contact + "_" + ds_contact + "_" + index}
              href={`tel:+55${val_contact.replace(/[^0-9]/g, "")}`}
            >
              <FontAwesomeIcon icon={"phone"} />
            </a>
          )
        }
      })
    }
    return contacts
  }

  return (
    <div id={"id-modal-company-contacts"} className="modal-company-contacts" onClick={handleToggleCkick}>
      <div className="modal-company-contacts--content">
        <div className="modal-company-contacts--content--header">Entre em contato com a loja!</div>
        <div className="modal-company-contacts--content--body">
          <div className="modal-company-contacts--content--body--upper">
            <span>Entre em contato para informações sobre seu pedido</span>
          </div>
          <div className="modal-company-contacts--content--body--bottom">
            {(() => {
              const items = getCompanyContacts()
              return items.map((item) => {
                return item
              })
            })()}
          </div>
        </div>
        <div className="modal-company-contacts--content--footer">
          <CFButton title={"Fechar"} outline={true} onClick={onClose} />
        </div>
      </div>
    </div>
  )
}

export default ModalCompanyContacts
