// Componentes internos
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados das áreas de entrega
 */
const DeliveryAreasController = {
  fetchAll() {
    return API.request(`/v1/areas/all`, {}, "GET")
  },
}

export default DeliveryAreasController
