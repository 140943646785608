// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const ComboController = {
  fetchOne(uuid_combo) {
    return API.request("/v1/combo/" + uuid_combo, [], "GET")
  },

  fetchComboProducts(uuid_combo) {
    return API.request("/v1/combo/" + uuid_combo + "/products", [], "GET")
  },

  fetchComboBeverages(uuid_combo) {
    return API.request("/v1/combo/" + uuid_combo + "/beverages", [], "GET")
  },

  fetchComboSizesProduct(comboProductId, productId) {
    return API.request("/v1/combo/" + comboProductId + "/product/" + productId + "/sizes", [], "GET")
  },

  fetchComboFlavoursProduct(comboProductId, productId, params) {
    return API.request("/v1/combo/" + comboProductId + "/product/" + productId + "/flavours/website", params, "GET")
  },

  fetchComboAdditionalsProduct(comboProductId, productId, complementId) {
    return API.request("/v1/combo/" + comboProductId + "/product/" + productId + "/complement/" + complementId + "/additionals", [], "GET")
  },

  fetchComboComplementsProduct(comboProductId, productId) {
    return API.request("/v1/combo/" + comboProductId + "/product/" + productId + "/complements", [], "GET")
  },

  fetchComboBeveragesProduct(comboProductId) {
    return API.request("/v1/combo/" + comboProductId + "/product/beverages", [], "GET")
  },

  fetchAll(filter = {}) {
    return API.request("/v1/combo", filter, "GET")
  },
}

export default ComboController
