import React from "react"
//External Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Helpers
import { onMessageDialog } from "../../helpers/GeneralTools"
import { getLocalItem, setLocalItem } from "../../helpers/StorageTools"

//Contexts
import MenuContext from "../../contexts/MenuContext"
import LoadingContext from "../../contexts/LoadingContext"
import SettingsContext from "../../contexts/SettingsContext"

//Components
import AddressesInfoItem from "./AddressesInfoItem"
import AddressesInfoOptions from "./AddressesInfoOptions"
import FullContentLoading from "../FullContentLoading"
import CFButton from "../CFButton/CFButton"

//Controllers
import CustomerController from "../../controllers/CustomerController"

import "./AddressesInfo.scss"
import UserContext from "../../contexts/UserContext"

const AddressesInfo = ({ status, returnMenu, setLoading }) => {
  const [addresses, setAddresses] = React.useState([])
  const [addressesSelected, setAddressesSelected] = React.useState(null)

  const [showAddressOptions, setShowAddressOptions] = React.useState(false)

  const { user, setUser, userSelectedAddress } = React.useContext(UserContext)
  const { addressesLoading } = React.useContext(LoadingContext)
  const { setModalInitialAddress, setModalMapAddress } = React.useContext(MenuContext)
  const { onChangeUserSelectedAddress } = React.useContext(SettingsContext)

  React.useEffect(() => {
    if (!!user) {
      // console.log("USER", user)
      setAddresses(
        user.addresses
          .filter(({ customer_address }) => !customer_address.dt_deleted_at)
          .map((address) => {
            const orderStreetFound = user.customer_orders.find(
              ({ fk_id_customer_address }) => address?.customer_address?.id_customer_address === fk_id_customer_address
            )
            // console.log("orderStreetFound", orderStreetFound)
            return { ...address, canDelete: !!orderStreetFound ? false : true }
          })
      )
      const primaryAddress = user.addresses.find(({ customer_address: { st_primary } }) => st_primary)

      if (!!primaryAddress && !userSelectedAddress) {
        setAddressesSelected(primaryAddress)
        onChangeUserSelectedAddress(primaryAddress)
      } else if (!!userSelectedAddress) {
        const selectedAddress = user.addresses.find(
          ({ zipcode, customer_address: { num_address } }) =>
            zipcode === userSelectedAddress.zipcode && num_address === userSelectedAddress?.customer_address?.num_address
        )

        if (!!selectedAddress) {
          setAddressesSelected(selectedAddress)
          onChangeUserSelectedAddress(selectedAddress)
          // setLocalItem("userSelectedAddress", selectedAddress, true)
        }
      }
    }
  }, [user.addresses])

  const currentOptionId = React.useRef(-1)

  const handlShowAddressOptions = (event) => {
    currentOptionId.current = event
    setShowAddressOptions(!showAddressOptions)
  }

  const removeAddress = (address) => {
    let tempAddress = [...addresses]

    onMessageDialog("delete")
      .fire({
        title: "Deseja remover este endereço?",
        html: `${address?.street_type?.nm_type || "Rua"} ${address.nm_street}`,
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        showConfirmButton: true,
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          let tempAddress = [...addresses.filter(({ customer_address }) => !customer_address.dt_deleted_at)]
          // console.log("tempAddress", tempAddress)
          const removeIndex = tempAddress.findIndex(
            (address_data) => address_data?.customer_address?.id_customer_address === address?.customer_address?.id_customer_address
          )

          if (removeIndex !== -1) {
            const isPriority = tempAddress[removeIndex].customer_address.st_primary ? true : false
            if (!tempAddress[removeIndex].customer_address.id_customer_address) {
              tempAddress.splice(removeIndex, 1)
            } else {
              tempAddress[removeIndex] = {
                ...tempAddress[removeIndex],
                deleted: true,
                st_primary: false,
              }
            }
            if (tempAddress.length > 1 && isPriority) {
              const newPrimaryIndex = removeIndex > 0 ? 0 : 1
              tempAddress[newPrimaryIndex] = {
                ...tempAddress[newPrimaryIndex],
                st_primary: true,
              }
            }
            // Obtendo usuário da sessão
            setLoading(true)
            CustomerController.update(user.uuid_customer, { addresses: tempAddress }).then((response) => {
              setLoading(false)
              if (response.status === 200) {
                const addresses = response.data?.streets || []

                const addressesFiltered = addresses.filter(({ customer_address }) => !customer_address.dt_deleted_at)

                const defaultAddress = addressesFiltered.find(({ customer_address: { st_primary } }) => st_primary) || addressesFiltered[0]

                setAddresses([...addressesFiltered])
                onChangeUserSelectedAddress(defaultAddress)
                setAddressesSelected(defaultAddress)

                setUser({ ...user, addresses })
                setLocalItem("user", { ...user, addresses }, true)
              }
            })
          }
        }
      })
      .catch((error) => {
        console.error("Erro ao remover endereço: ", error)
      })
      .finally(() => {
        setShowAddressOptions(false)
      })
  }

  return (
    <Modal
      className={"addresses-info " + (status ? "addresses-info-active" : "")}
      // style={{ height: window.innerHeight }}
      isOpen={status}
      toggle={() => {
        returnMenu()
      }}
      backdrop={"static"}
      fade={false}
    >
      {/*<div className={"addresses-info " + (status ? "addresses-info-active" : "")} style={isAppleDevice() ? { height: window.innerHeight } : {}}>*/}
      <ModalHeader className={"addresses-info--header"}>
        <FontAwesomeIcon icon={"chevron-left"} onClick={returnMenu} />
        <span>Endereços</span>
      </ModalHeader>

      <ModalBody className={"addresses-info--body"}>
        <div className={"addresses-info--body--top"}>
          {addresses.length > 0 ? (
            addresses.map((address, index) => {
              return (
                <AddressesInfoItem
                  selected={address.id_street === addressesSelected?.id_street && address?.customer_address?.st_primary}
                  address={address}
                  key={index + `_${address.id_street}`}
                  handlShowAddressOptions={() => handlShowAddressOptions(index)}
                />
              )
            })
          ) : (
            <div className={"addnress-info--no-address"}>
              <span>Sem endereços cadastrados!</span>
            </div>
          )}
        </div>

        <div className={"addresses-info--body--button"}>
          <CFButton onClick={() => setModalInitialAddress({ status: true, title: "", content: { source: "newAddress" } })} title={"Novo"} />
        </div>
      </ModalBody>

      {showAddressOptions ? (
        <AddressesInfoOptions
          status={showAddressOptions}
          id={currentOptionId.current}
          address={addresses[currentOptionId.current]}
          handlShowAddressOptions={handlShowAddressOptions}
          removeAddress={removeAddress}
          setModalEditAddress={() =>
            setModalMapAddress({
              status: true,
              location: addressesSelected?.customer_address?.geo_coordinates
                ? {
                    latitude: addressesSelected.customer_address.geo_coordinates[0],
                    longitude: addressesSelected.customer_address.geo_coordinates[1],
                  }
                : {},
            })
          }
        />
      ) : (
        ""
      )}

      {addressesLoading ? <FullContentLoading loading={addressesLoading} /> : ""}
    </Modal>
  )
}

export default AddressesInfo
