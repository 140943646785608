import React from "react"

export default React.createContext({
  version: "1.0.0",
  settings: [],
  combos: [],
  products: [],
  beverages: [],
  methods: [],
  schedules: [],
  fidelities: [],
  setFidelities: () => {},
  benefits: [],
  setBenefits: () => {},
  isOutsideCoverageArea: false,
  userSelectedAddress: {},
  setUserSelectedAddress: () => {},
  currentStep: {},
  setCurrentStep: () => {},
})
