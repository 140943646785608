import React from "react"

import "./Main.scss"

import Menu from "../../components/Menu"
import MainOptions from "../../components/MainOptions"

const Main = ({ setModalCompanies }) => {
  return (
    <section id="main" className="section-main">
      <MainOptions type="smallDevices" setModalCompanies={setModalCompanies} />
      <Menu />
    </section>
  )
}

export default Main
