import React from "react"

// Controllers
import CustomerController from "../../controllers/CustomerController";

// Components
import CFInput from "../CFInput/CFInput"
import CFButton from "../CFButton/CFButton"

// Helpers
import { encrypt } from "../../helpers/StorageTools"
import { isAppleDevice, onMessageDialog } from "../../helpers/GeneralTools"


// Styles
import "./ProfileChangePassword.scss"

export default function ProfileChangePassword({ onClose, customerUid }) {
  const [currentPassword, setCurrentPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [validations, setValidations] = React.useState({
    confirmPassword: false,
    actualPassword: false,
  })

  const toggleOptions = (event) => {
    if (event.target.id === `id-profile-change-password`) onClose()
  }

  const handleChangePassword = () => {
    let validation = { ...validations }
    let validationMessage = ""

    if (currentPassword.trim() === "") {
      validationMessage = validationMessage + "Informe sua senha atual "
      validation = {
        ...validation,
        actualPassword: true,
      }
    } else {
      validation = {
        ...validation,
        actualPassword: false,
      }
    }

    if (newPassword !== confirmPassword) {
      validationMessage = validationMessage + "As senhas não conferem "
      validation = {
        ...validation,
        confirmPassword: true,
      }
    } else {
      validation = {
        ...validation,
        confirmPassword: false,
      }
    }

    if (validation.confirmPassword || validation.actualPassword) {
      onMessageDialog("warning").fire({
        title: validationMessage,
        icon: "warning",
        timer: 4000,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: true,
      })
    } else {
      CustomerController.updatePass(customerUid, encrypt(newPassword, "base64"), encrypt(currentPassword, "base64")).then((responseChangePass) => {
        if (responseChangePass.status === 201) {
          onMessageDialog("warning")
            .fire({
              title: responseChangePass.message,
              icon: "success",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
              reverseButtons: true,
            })
            .then(() => {
              onClose()
            })
        } else if (responseChangePass.status === 500) {
          onMessageDialog("warning").fire({
            title: responseChangePass.data.message,
            icon: "warning",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
          })
          setValidations({
            ...validations,
            actualPassword: true,
          })
        }
      })
    }
    setValidations({ ...validation })
  }

  return (
    <div id="id-profile-change-password" className="profile-change-password " onClick={toggleOptions}>
      <div className="profile-change-password--content" style={{ marginBottom: isAppleDevice() ? 115 : 0 }}>
        <div className="profile-change-password--content--header">
          <span>Alterar senha</span>
        </div>

        <div className="profile-change-password--content--body">
          <div
            className={
              "profile-change-password--content--body--current-password" + (validations.actualPassword ? " profile-change-password--validation" : "")
            }
          >
            <CFInput
              placeholder="Insira sua senha atual"
              type="password"
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
          </div>
          <div
            className={
              "profile-change-password--content--body--new-password" + (validations.confirmPassword ? " profile-change-password--validation" : "")
            }
          >
            <CFInput placeholder="Insira a nova senha" type="password" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
          </div>
          <div
            className={
              "profile-change-password--content--body--confirm-password" + (validations.confirmPassword ? " profile-change-password--validation" : "")
            }
          >
            <CFInput
              placeholder="Confirme a nova senha"
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </div>
        </div>

        <div className="profile-change-password--content--footer">
          <div className="profile-change-password--content--footer--cancel">
            <CFButton title="Cancelar" onClick={onClose} outline={true} />
          </div>

          <div className="profile-change-password--content--footer--confirm">
            <CFButton title="Alterar" onClick={handleChangePassword} />
          </div>
        </div>
      </div>
    </div>
  )
}
