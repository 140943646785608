import React from "react"
//External libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Contexts
import OrderContext from "../../contexts/OrderContext"

import "./PaymentInfo.scss"
import GeneralTools, { getUrlAWSFile } from "../../helpers/GeneralTools"

const PaymentInfo = ({ handlePaymentTypeSelector, paymentOnDelivery }) => {
  const { order } = React.useContext(OrderContext)

  const orderPaymentIndex = order?.payments?.findIndex(({ nm_payment_method }) => `${nm_payment_method}`.toLowerCase() !== "cashback")
  return (
    <div className="payment-info">
      {orderPaymentIndex !== -1 ? (
        <div className="payment-info--left">
          <img className="payment-info--left--image" alt="" src={getUrlAWSFile(order.payments[orderPaymentIndex].url_image)} />
        </div>
      ) : (
        ""
      )}

      <div className="payment-info--center">
        {orderPaymentIndex !== -1 ? (
          <>
            <span className="payment-info--center--title">{order.payments[orderPaymentIndex].nm_payment_method}</span>
            <div className="payment-info--center--desc">{paymentOnDelivery ? "Pagamento da entrega" : "Pagamento no site"}</div>
          </>
        ) : (
          <span className="payment-info--center--title">Selecione a forma de pagamento</span>
        )}
      </div>

      <div className="payment-info--right" onClick={handlePaymentTypeSelector}>
        {orderPaymentIndex !== -1 ? <span>Trocar</span> : <span>Selecionar</span>}

        <FontAwesomeIcon icon="chevron-up" />
      </div>
    </div>
  )
}

export default PaymentInfo
