// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados do mercado pago
 */
const MercadoPagoController = {
    getPublicKey() {
        return API.request("/v1/mercadopago/publickey", {}, "GET")
    },
}

export default MercadoPagoController
