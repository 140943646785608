import React from "react"

//Componentes
import ScheduleWeekDay from "./ScheduleWeekDay"
import ScheduleWorkingTime from "./ScheduleWorkingTime"

//Helpers
import { getFormatDate, onMessageDialog } from "../../helpers/GeneralTools"

//Contexts
import SettingsContext from "../../contexts/SettingsContext"

import "./ScheduleDateSelector.scss"
import UserContext from "../../contexts/UserContext"
import { Label, Spinner } from "reactstrap"

export default function ScheduleDateSelector({ schedules, orderSchedules, scheduleOptionsSelected, setScheduleOptionsSelected, loadingSchedule }) {
  const dateNow = new Date()

  const { settings, modality } = React.useContext(SettingsContext)
  const { userSelectedAddress } = React.useContext(UserContext)

  const [schedulesOrganized, setSchedulesOrganized] = React.useState([])

  const [selectedScheduleUid, setSelectedScheduleUid] = React.useState("")
  const [selectedWorkinTimeUid, setSelectedWorkinTimeUid] = React.useState("")

  React.useEffect(() => {}, [])

  React.useEffect(() => {
    showCardByStartingDate()
  }, [schedules, orderSchedules])

  const neighbourhoodOnDeliveryArea = (neighbourhoodUuid = {}, neighbourhoods = []) => {
    return neighbourhoods.length > 0 ? !!neighbourhoods.find(({ uuid_neighbourhood }) => uuid_neighbourhood === neighbourhoodUuid) : true
  }

  const checkHourTime = (workTimeHour, currentHour) => {
    if (workTimeHour > currentHour) {
      let time = null
      if (!!modality && !!settings) {
        if (!!settings.minimal_production_schedule_time) {
          time = parseInt(settings.minimal_production_schedule_time)
        } else if (modality === 1 && !!settings.delivery_time) {
          time = parseInt(settings.delivery_time)
        } else if (modality === 2 && !!settings.pickup_time) {
          time = parseInt(settings.pickup_time)
        }
      }

      const result = diffTimes(`${Math.trunc(time / 60)}:${time % 60}:00`, workTimeHour)
      return !!time ? result > currentHour : true
    }
    return false
  }

  const diffTimes = (startTime, endTime) => {
    const times = [0, 0, 0]
    const max = times.length

    const a = (startTime || "").split(":")
    const b = (endTime || "").split(":")

    // normalize time values
    for (let i = 0; i < max; i++) {
      a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
      b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
    }

    // store time values
    for (let i = 0; i < max; i++) {
      times[i] = b[i] - a[i]
    }

    let hours = times[0]
    let minutes = times[1]
    let seconds = times[2]

    if (minutes < 0) {
      const h = (hours * 60) << 0
      hours = Math.trunc((h + minutes) / 60)
      minutes = 60 + minutes
    }

    return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2)
  }

  const showCardByStartingDate = () => {
    let weekDay = dateNow.getDay()
    let date = new Date(dateNow)
    let result = []
    const newdate = new Date()

    for (let showedDays = 0; showedDays < schedules.length; showedDays++) {
      if (weekDay > 6) weekDay = 0

      const fixedDate = getFormatDate(newdate).getDate("DD/MM/YYYY")
      const alterDate = getFormatDate(date).getDate("DD/MM/YYYY")

      if (fixedDate === alterDate) {
        //Valida se no intervalo passa pelo dia atual para remover os horários anteriores ao horário atual, assim não permite realizar agendamento para os horários que já passaram.
        const workTimesFiltered = schedules[weekDay].work_times.filter((workTime) =>
          checkHourTime(workTime.hr_start, getFormatDate(newdate).getHour("all"))
        )

        result.push({
          ...schedules[weekDay],
          date: getFormatDate(date).getDate("YYYY-MM-DD"),
          work_times: [...workTimesFiltered],
        })

        date.setDate(date.getDate() + 1)
        weekDay++
      } else {
        result.push({ ...schedules[weekDay], date: getFormatDate(date).getDate("YYYY-MM-DD") })
        date.setDate(date.getDate() + 1)
        weekDay++
      }
    }

    result = result.map((scheduleDayOfWeek) => {
      let filteredWorkingTimes = []

      let selectedWorkTime = null
      //Validando os horários de agendamento se atingiram o limite de pedidos e se é entregue no bairro.
      const work_times = scheduleDayOfWeek.work_times.filter(
        ({ neighbourhoods_working_time, qty_items, hr_end, hr_start, uuid_schedule_working_time }, index) => {
          if (uuid_schedule_working_time === selectedScheduleUid) {
            selectedWorkTime = scheduleDayOfWeek.work_times[index]
          }

          const scheduleDate = scheduleDayOfWeek.date
          const neighbourhoodFound = neighbourhoodOnDeliveryArea(userSelectedAddress.uuid_neighbourhood, [
            ...neighbourhoods_working_time,
            ...scheduleDayOfWeek.neighbourhoods,
          ])

          let neighbourhoodValidateQty
          if (!!neighbourhoods_working_time && neighbourhoods_working_time.length > 0) {
            const neighbourhoodsWorkingTimeIds = neighbourhoods_working_time.map(({ id_neighbourhood }) => id_neighbourhood)
            const qty_schedules = orderSchedules.reduce((sum, orderSchedule) => {
              if (
                orderSchedule.dt_schedule === scheduleDate &&
                orderSchedule.hr_schedule_start === hr_start &&
                orderSchedule.hr_schedule_end === hr_end &&
                neighbourhoodsWorkingTimeIds.includes(orderSchedule.id_neighbourhood)
              ) {
                return sum + orderSchedule.qty_schedules
              } else {
                return sum
              }
            }, 0)
            neighbourhoodValidateQty = qty_items > qty_schedules
          } else {
            let allNeighbourhoodsWorkingTimesIds = []
            let totalItemsNeighbourhood = 0
            const totalItems = scheduleDayOfWeek.work_times.reduce((sum, workTime) => {
              if (workTime.hr_end === hr_end && workTime.hr_start === hr_start) {
                allNeighbourhoodsWorkingTimesIds = [
                  ...allNeighbourhoodsWorkingTimesIds,
                  ...workTime.neighbourhoods_working_time.map(({ id_neighbourhood }) => id_neighbourhood),
                ]
                if (!!workTime.neighbourhoods_working_time && workTime.neighbourhoods_working_time.length > 0) {
                  totalItemsNeighbourhood += workTime.qty_items
                }
                return sum + workTime.qty_items
              } else {
                return sum
              }
            }, 0)

            let totalSchedulesNeighbourhoods = 0
            const totalSchedules = orderSchedules.reduce((sum, orderSchedule) => {
              if (
                orderSchedule.dt_schedule === scheduleDate &&
                orderSchedule.hr_schedule_start === hr_start &&
                orderSchedule.hr_schedule_end === hr_end
              ) {
                if (allNeighbourhoodsWorkingTimesIds.includes(orderSchedule.id_neighbourhood)) {
                  totalSchedulesNeighbourhoods += orderSchedule.qty_schedules
                }
                return sum + orderSchedule.qty_schedules
              } else {
                return sum
              }
            }, 0)

            neighbourhoodValidateQty =
              totalItemsNeighbourhood - totalSchedulesNeighbourhoods > 0 &&
              totalItems - totalSchedules === totalItemsNeighbourhood - totalSchedulesNeighbourhoods
                ? false
                : totalItems - totalSchedules > 0
          }

          return !!neighbourhoodFound && !!neighbourhoodValidateQty
        }
      )

      for (const worktime of work_times) {
        const filteredWorkingTimesIndex =
          filteredWorkingTimes.length > 0
            ? filteredWorkingTimes.findIndex(({ hr_end, hr_start }) => hr_start === worktime.hr_start && hr_end === worktime.hr_end)
            : -1
        if (filteredWorkingTimesIndex === -1) {
          filteredWorkingTimes.push(worktime)
        } else if (!!worktime.neighbourhoods_working_time && worktime.neighbourhoods_working_time.length > 0) {
          filteredWorkingTimes.splice(filteredWorkingTimesIndex, 1, worktime)
        }
      }

      if (!!selectedWorkTime) {
        const indexTimes = filteredWorkingTimes.findIndex(
          ({ hr_end, hr_start }) => selectedWorkTime.hr_end === hr_end && selectedWorkTime.hr_start === hr_start
        )
        if (indexTimes === -1) {
          // setSelectedDate({})
          // setSelectedScheduleOption(false)
          setSelectedScheduleUid("")

          onMessageDialog("info").fire({
            title: `O horário selecionado foi esgotado, por favor, selecione outro horário.`,
            icon: "info",
            timer: 0,
            showCancelButton: false,
            // cancelButtonText: '<i class="ion-close-round"/> Não',
            confirmButtonText: '<i class="ion-checkmark"/> Entendi',
            showLoaderOnConfirm: true,
            reverseButtons: false,
          })
        } else {
          if (filteredWorkingTimes[indexTimes].uuid_schedule_working_time !== selectedWorkTime.uuid_schedule_working_time) {
            setSelectedScheduleUid(filteredWorkingTimes[indexTimes].uuid_schedule_working_time)
          }
        }
      }

      return {
        ...scheduleDayOfWeek,
        work_times: filteredWorkingTimes,
      }
    })

    setSchedulesOrganized([...result])
  }

  //Função de selecionar o dia
  const handleSelectWeekDay = (item) => {
    setScheduleOptionsSelected({ ...scheduleOptionsSelected, scheduleData: item })
    setSelectedScheduleUid(item.uuid_schedule)
  }
  //Função de selecionar o horário
  const handleWorkingTimeSelection = (item) => {
    setScheduleOptionsSelected({ ...scheduleOptionsSelected, workingTime: item })
    setSelectedWorkinTimeUid(item.uuid_schedule_working_time)
  }

  const getWorkingTimesFromWeekDay = () => {
    const item = schedulesOrganized.find(({ uuid_schedule }) => uuid_schedule === selectedScheduleUid)

    return !!item ? item.work_times : []
  }

  return (
    <div className="schedule-date-selector">
      <div className="schedule-date-selector--body">
        <div className="schedule-date-selector--body--week-day">
          {(() => {
            if (loadingSchedule) {
              return (
                <Label className="schedule-date-selector--body--loading">
                  <Spinner size="sm" role="status">
                    {""}
                  </Spinner>
                  Carregando horários...
                </Label>
              )
            } else if (schedulesOrganized.filter(({ st_open }) => st_open).length > 0) {
              return schedulesOrganized.map((item, index) => (
                <ScheduleWeekDay
                  key={index}
                  contentData={item}
                  selected={item.uuid_schedule === selectedScheduleUid}
                  onSelect={() => handleSelectWeekDay(item)}
                />
              ))
            } else {
              return (
                <div className={"schedule-date-selector--body--week-day--working-times"}>
                  <span>Sem horários disponíveis</span>
                </div>
              )
            }
          })()}
        </div>
        {!!selectedScheduleUid ? (
          <div className={"schedule-date-selector--body--week-day--working-times"}>
            <span>Selecione um horário</span>
            <div className={"schedule-date-selector--body--week-day--working-times--items"}>
              {(() => {
                const items = getWorkingTimesFromWeekDay()
                if (items?.length > 0) {
                  return items.map((item, index) => {
                    return (
                      <ScheduleWorkingTime
                        key={index}
                        contentData={item}
                        onSelectWorkingTime={() => handleWorkingTimeSelection(item)}
                        selected={item.uuid_schedule_working_time === selectedWorkinTimeUid}
                      />
                    )
                  })
                }
              })()}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
