// Resources
import API from "../helpers/API"
import GeneralTools from "../helpers/GeneralTools"
import { getCookie } from "../helpers/StorageTools"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const CustomerController = {
  insert(content, type) {
    // Definindo os parametros
    let params = {
      login: content.login,
      id_company: content.id_company,
      nm_customer: content.customerName,
      password: content.password,
      ds_referred: "website",
      contacts: [
        {
          ds_contact: "Celular",
          val_contact: GeneralTools.formatPhone(content.phone, false) || null,
        },
        {
          ds_contact: "E-mail",
          val_contact: content.email || null,
        },
      ],
    }

    switch (type) {
      case "phone": {
        params.contacts[0].val_contact = content.login
        break
      }
      case "email": {
        params.contacts[1].val_contact = content.login
        break
      }
      default: {
        break
      }
    }

    params.contacts = params.contacts.filter(({ val_contact }) => val_contact !== null)

    return API.request("/v1/customer", params, "POST")
  },

  update(uuid_customer, content) {
    let params = { ...content }

    if (!!content.addresses && content.addresses.length > 0) {
      params.addresses = content?.addresses
        ?.filter(({ customer_address }) => !customer_address.dt_deleted_at)
        ?.map((address) => {
          const {
            st_primary,
            zipcode,
            id_street,
            nm_street,

            street_type: { id_street_type, nm_type: nm_street_type },
            neighbourhood: {
              nm_neighbourhood,
              city: {
                id_city,
                nm_city,
                uuid_city,
                state: { id_state, uuid_state, nm_state, ds_acronym },
              },
            },
            customer_address: {
              ds_title,
              ds_address_additional_info: complement,
              ds_observation: reference,
              num_address,
              geo_coordinates,
              id_customer_address,
            },
            canDelete,
            deleted,
          } = address

          return {
            zipcode,
            ds_title,
            id_street,
            id_customer_address,
            nm_street,
            id_street_type,
            nm_street_type,
            nm_neighbourhood,
            id_city,
            uuid_city,
            nm_city,
            id_state,
            uuid_state,
            nm_state,
            ds_acronym,
            home_number: num_address,
            complement,
            obs: reference,
            st_primary,
            coordinates: !!geo_coordinates ? { latitude: geo_coordinates[0], longitude: geo_coordinates[1] } : null,
            canDelete,
            deleted,
          }
        })
    }

    // console.log("params", params)
    return API.request("/v1/customer/" + uuid_customer, params, "PUT")
  },

  async signIn(login, password, loginWhatsapp) {
    const params = {
      login,
      password,
      loginWhatsapp,
      origin: "subscriber-site",
    }

    return API.request("/v1/auth/login/website", params, "POST")
  },

  updatePass(customerUid, password, oldPassword, phone, onlyPhone) {
    let params = {}

    if (!!password) params = { ...params, password }
    if (!!oldPassword) params = { ...params, oldPassword }
    if (!!phone) params = { ...params, phone }
    if (!!onlyPhone) params = { ...params, onlyPhone }

    return API.request("/v1/customer/password/" + customerUid, params, "PUT")
  },

  fetchOne(customerUid, customerIncludes) {
    return API.request("/v1/customer/" + customerUid, customerIncludes, "GET")
  },

  recoveryPassword(customerUid) {
    return API.request("/v1/customer/recovery", { customerUid }, "POST")
  },
}

export default CustomerController
