import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./CFButton.scss"
import { Spinner } from "reactstrap"

const CFButton = ({ title, onClick, outline = false, icon = null, style = {}, disabled = false, extraClass = "", loading, loadingText }) => {
  return (
    <button
      //
      onClick={onClick}
      className={!disabled && !loading ? "component-button " + (outline ? " button-props-outline " : "") + extraClass : "component-button button-props-disabled"}
      style={style}
      disabled={disabled}
    >
      {loading ?
        (
          <>
            <Spinner size={"sm"} color="white" />
            <p>{loadingText}</p>
          </>
        )
        :
        (
          <>
            {icon ? <FontAwesomeIcon icon={icon} /> : ""}
            <p>{title}</p>
          </>
        )
      }
    </button>
  )
}

export default CFButton
