import React from "react"

import "./CFSelect.scss"

export default function CFSelect({ options, value, label, onChange, selected, style, disabled, multiple = false, valid = true, type = "default" }) {
  const onChangeData = (changedValue) => {
    const option = options.find((option) => {
      return option[`${value}`] === changedValue.target.value
    })
    onChange(option)
  }

  return (
    <select
      disabled={disabled}
      multiple={multiple}
      className={"component-select " + (valid ? "valid" : "invalid")}
      onChange={onChangeData}
      style={style}
      value={selected || "no-option"}
    >
      {(() => {
        const hasSelectedItem = options.find((option) => {
          return option[`${value}`] === selected
        })
        if (!hasSelectedItem) {
          if (options.length > 0) {
            switch (type) {
              case "default":
                return <option>Selecione uma opção</option>
              case "neighbourhood":
                return <option>Selecione um bairro</option>
              case "city":
                return <option>Selecione uma cidade</option>
              case "state":
                return <option>Selecione um estado</option>
            }
          } else {
            return <option>Sem opções para seleção</option>
          }
        }
      })()}

      {options.map((option, index) => {
        return (
          <option key={index + option[`${label}`]} value={option[`${value}`]}>
            {option[`${label}`]}
          </option>
        )
      })}
    </select>
  )
}
