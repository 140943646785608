import { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getLocalItem, setLocalItem } from "../../helpers/StorageTools"

import "./ModalPopup.scss"
import { getUrlAWSManagerFile } from "../../helpers/GeneralTools"

export default function ModalPopup({ status, content, onClose }) {
  const [dontShowAgain, setDontShowAgain] = useState(false)
  // TODO: Rotina para quando o usuário clicar em "Não mostrar novamente"
  //   const showAgainOption = (event) => {
  //     setDontShowAgain(event.target.checked)
  //   }

  const onCloseModal = () => {
    let popups = {}
    const sessionsPopups = getLocalItem("popups", true)
    if (!!sessionsPopups) {
      popups = sessionsPopups
    }

    popups[content.uuid_popup] = !dontShowAgain
    setLocalItem("popups", popups, true)
    onClose()
  }

  if (!content) {
    return <></>
  }

  return (
    <Modal isOpen={status} toggle={onCloseModal} centered className="modal-popup">
      <div className="modal-popup--image">
        <img src={getUrlAWSManagerFile(content.url_popup_image)} alt="Mensagem de inicio." />
      </div>

      <div className="modal-popup--close">
        <FontAwesomeIcon icon="times" onClick={onCloseModal} />
      </div>
      {!!content.desc_popup ? (
        <div className="modal-popup--desc">
          <p>{content.desc_popup}</p>
        </div>
      ) : (
        ""
      )}

      {/* A rotina para aparecer a opção de não mostrar ficou para o futuro, conforme conversa com o Fabricio
      talvez alterar o componente input para um outro checkbox, porém por hora está Ok.
       */}
      {/* <div className="modal-popup--show-again">
        <p>Não mostrar novamente?</p>
        <input type="checkbox" id="modal-popup-show-again-input" onClick={(event) => showAgainOption(event)} />
      </div> */}
    </Modal>
  )
}
