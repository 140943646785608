import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// import QuantityControl from "../QuantityControl/QuantityControl"

// import noImage from "../../assets/img/builder/no-image.png"
import "./ComboItem.scss"
import { formatFloat, formatMoney } from "../../helpers/GeneralTools"

const ComboItem = ({ children, title, price, observation, removeCombo, editCombo }) => {
  const getComboTotal = () => {
    let totalCombo = price
    // children.forEach((item) => {
    //   if (!!item) {
    //     item?.forEach((element) => {
    //       totalCombo = totalCombo + formatFloat(element.props?.beverageData?.val_price || 0) + formatFloat(element.props?.productData?.val_price || 0)
    //     })
    //   }
    // })

    return totalCombo
  }
  return (
    <div className="combo-item">
      <div className="combo-item--data">
        <div className="combo-item--data--info">
          <div className="combo-item--data--info--left">
            <div className="combo-item--data--info--left--title">
              <span>{title}</span>
            </div>
            <div className="combo-item--data--info--left--actions">
              {/*TODO: Implementar rotina de edição do combo*/}
              {/*<FontAwesomeIcon className="combo-item--data--info--left--actions--edit" icon={"pen"} onClick={editCombo} />*/}
              <FontAwesomeIcon className="combo-item--data--info--left--actions--delete" icon={"trash"} onClick={removeCombo} />
            </div>
          </div>
          <div className="combo-item--data--info--right">{formatMoney(getComboTotal())}</div>
        </div>
        <div className="combo-item--data--items">
          {children}
          {!!observation ? <span className="combo-item--data--items--observation">{observation}</span> : ""}
        </div>
      </div>
      {/*<div className="combo-item--divisor" />*/}
    </div>
  )
}

export default ComboItem
