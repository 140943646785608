import React from "react"

// External Libs
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./ModalFidelityDetails.scss"

const ModalFidelityDetails = ({ status, content, onClose }) => {
  if (!content) {
    return ""
  }

  return (
    <Modal isOpen={status} toggle={onClose} className="modal-fidelity-details" backdrop={"static"} fade={false}>
      <ModalHeader className={"modal-fidelity-details--header"}>
        <span>Regras da campanha de fidelidade</span>
        <FontAwesomeIcon icon={"times"} onClick={onClose} />
      </ModalHeader>
      <ModalBody className={"modal-fidelity-details--body"}>
        <div className={"modal-fidelity-details--body--content"}>
          <div className={"modal-fidelity-details--body--content--rule-block"}>
            <strong>Regras</strong>
            <span>{content.ds_rule_fidelity}</span>
          </div>
          <div className={"modal-fidelity-details--body--content--prize-block"}>
            <strong>Ganhe</strong>
            <span>{content.ds_prize_fidelity}</span>
          </div>
          <div className={"modal-fidelity-details--body--content--retrieve-block"}>
            <strong>Como usar</strong>
            <span>
              {content.prize_fidelity === "coupon"
                ? `Na tela do carrinho, basta ir na opção de "Cupons e Promoções" que a mesma estará disponível para utilização.`
                : `Após escolher os itens do seu pedido, basta ir na opção de "Cupons e Promoções" que a mesma estará disponível para utilização.`}
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color={"danger"} onClick={onClose}>
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalFidelityDetails
