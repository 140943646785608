// Componentes internos
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados dos usuários
 */
const ProductController = {
  fetchOne(uuid_product) {
    return API.request("/v1/product/" + uuid_product, [], "GET")
  },

  fetchSizes(uuid_product) {
    return API.request("/v1/product/" + uuid_product + "/sizes", [], "GET")
  },

  fetchFlavoursBySize(data) {
    const { uuid_product } = data
    return API.request("/v1/product/" + uuid_product + "/flavours/website", data, "GET")
  },
  fetchFlavours({ uuid_product, withSizes = false, withIngredients = false }) {
    return API.request("/v1/product/" + uuid_product + "/flavours", { withSizes, withIngredients, stWebsite: true }, "GET")
  },

  fetchOneFlavour(params) {
    return API.request("/v1/flavour/one/", params, "GET")
  },

  fetchComplements(uuid_product) {
    return API.request("/v1/product/" + uuid_product + "/complements", { withSizes: true, withAdditionals: true }, "GET")
  },

  fetchAdditionals(uuid_product) {
    return API.request("/v1/product/" + uuid_product + "/additionals", [], "GET")
  },

  fetchIngredients(id_product) {
    return API.request("/v1/product/" + id_product + "/ingredients", [], "GET")
  },

  fetchAll() {
    const params = {
      orderField: "id",
      orderKeyword: "asc",
      page: 1,
      pageSize: 1000,
      stWebsite: true,
      withCompany: true,
    }

    return API.request("/v1/product", params, "GET")
  },

  fetchAllMemory() {
    return API.request("/v1/product/memory/all", [], "GET")
  },

  fetchAllOrder() {
    const params = {
      order: [
        {
          field: "id_product",
          keyword: "ASC",
        },
      ],
      where: { st_status: "1" },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    }

    return API.request("/v1/product", params, "GET")
  },
}

export default ProductController
