import React, { useEffect } from "react"
//External Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MaskedInput from "react-text-mask"
//Components
import ProfileChangePassword from "./ProfileChangePassword"
import CFButton from "../CFButton/CFButton"
//Helpers
import { onMessageDialog } from "../../helpers/GeneralTools"

import "./PersonalInfo.scss"

const PersonalInfo = ({ enablePersonalInfo, returnMenu, initialUser, onUpdatePersonalInfo }) => {
  const [currentUser, setCurrentUser] = React.useState({ ...initialUser })
  const [validations, setValidations] = React.useState({ name: false, phone: false })

  const [changePasswordView, setChangePasswordView] = React.useState(false)

  const emailIndexRef = React.useRef(-1)
  const phoneIndexRef = React.useRef(-1)

  React.useEffect(() => {
    verifyUsersDifs()
  }, [])

  const verifyUsersDifs = () => {
    let needUpdate = false
    if (currentUser.nm_customer !== initialUser.nm_customer) needUpdate = true

    // Contato email
    emailIndexRef.current = currentUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 2)
    if (emailIndexRef.current !== -1) {
      if (currentUser?.contacts[emailIndexRef.current]?.val_contact !== initialUser?.contacts[emailIndexRef.current]?.val_contact) {
        needUpdate = true
      }
    }
    // Contato celular
    phoneIndexRef.current = currentUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 1)
    if (phoneIndexRef.current !== -1) {
      if (
        currentUser?.contacts[phoneIndexRef.current]?.val_contact.replace(/[^a-z0-9]/gi, "") !==
        initialUser?.contacts[phoneIndexRef.current]?.val_contact
      ) {
        needUpdate = true
      }
    }

    if (currentUser.dt_birth !== initialUser.dt_birth) needUpdate = true

    return needUpdate
  }

  const handleUpdatePersonalInfo = () => {
    let validation = { name: false, phone: false }

    if (currentUser.contacts[phoneIndexRef.current].val_contact.replace(/[^a-z0-9]/gi, "").length < 11) {
      validation = {
        ...validation,
        phone: true,
      }
    }

    if (currentUser.nm_customer.length < 2) {
      validation = {
        ...validation,
        name: true,
      }
    }

    if (validation.name || validation.phone) {
      setValidations(validation)
      onMessageDialog("info")
        .fire({
          title: "Preencha os campos obrigatórios!",
          icon: "info",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
    } else {
      onUpdatePersonalInfo(currentUser)
    }
  }

  const onChangeEmail = (event) => {
    let tempUser = { ...currentUser }
    const emailIndex = tempUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 2)
    if (emailIndex !== -1) {
      tempUser.contacts[emailIndex].val_contact = event.target.value
    } else {
      tempUser.contacts.push({
        fk_id_contact: 2,
        val_contact: event.target.value,
      })
    }
    emailIndexRef.current = tempUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 2)
    setCurrentUser(tempUser)
  }

  const onChangePhone = (event) => {
    let tempUser = { ...currentUser }
    const indexPhone = tempUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 1)
    tempUser.contacts[indexPhone].val_contact = event.target.value
    setValidations({ ...validations, phone: false })
    phoneIndexRef.current = tempUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 1)
    setCurrentUser(tempUser)
  }

  return (
    <div className={"personal-info " + enablePersonalInfo}>
      <div className={"personal-info--header"}>
        <FontAwesomeIcon
          icon={"chevron-left"}
          onClick={() => {
            setCurrentUser({ ...initialUser })
            returnMenu()
          }}
        />
        <span>Meus dados</span>
      </div>

      <div className={"personal-info--body"}>
        <div className={"personal-info--body--input " + (validations.name ? "required-input" : "")}>
          <input
            //
            type="text"
            onChange={(event) => {
              setValidations({ ...validations, name: false })
              setCurrentUser({ ...currentUser, nm_customer: event.target.value })
            }}
            value={currentUser.nm_customer || ""}
            placeholder=" "
          />
          <label>Nome Completo</label>
        </div>

        <div className={"personal-info--body--input " + (validations.phone ? "required-input" : "")}>
          <MaskedInput
            mask={["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholder=" "
            value={currentUser?.contacts[phoneIndexRef.current]?.val_contact.replace(/[^a-z0-9]/gi, "") || ""}
            onChange={onChangePhone}
          />
          <label>Telefone</label>
        </div>

        <div className={"personal-info--body--input"}>
          <input
            //
            type="text"
            onChange={onChangeEmail}
            // onChange={(event) => setCurrentUser({ ...currentUser, contacts: { ...currentUser.contacts, email: event.target.value } })}
            value={currentUser?.contacts[emailIndexRef.current]?.val_contact || ""}
            placeholder=" "
          />
          <label>E-mail (opcional)</label>
        </div>

        <div className={"personal-info--body--input"}>
          <input
            id="birth-input"
            type={"date"}
            onChange={(event) => {
              setCurrentUser({ ...currentUser, dt_birth: event.target.value })
            }}
            value={currentUser.dt_birth || ""}
            placeholder={"Data de nascimento"}
          />
          <label>Data de Nascimento (opcional)</label>
        </div>

        <div className={"personal-info--body--button"}>
          <CFButton title="Alterar senha" onClick={() => setChangePasswordView(!changePasswordView)} />
        </div>
        <div className={"personal-info--need-update "}>
          {/*+ (!verifyUsersDifs() ? "readonly-group" : "")*/}
          <CFButton title="Atualizar" onClick={() => handleUpdatePersonalInfo(currentUser)} />
        </div>
      </div>

      {changePasswordView ? (
        <ProfileChangePassword onClose={() => setChangePasswordView(!setChangePasswordView)} customerUid={currentUser.uuid_customer} />
      ) : (
        ""
      )}
    </div>
  )
}

export default PersonalInfo
