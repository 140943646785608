// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados das taxas de entrega
 */
const DeliveryFeeController = {
  fetchDeliveryFee(type = "neighbourhood", companies = null, companyUid = null) {
    let headers = {}
    if (!!companyUid) headers = { company: companyUid }

    return API.request("/v1/delivery/fee", { type, companies }, "GET", headers)
  },

  fetchByneighbourhood(uuid_neighbourhood) {
    return API.request("/v1/delivery/fee/neighbourhood/" + uuid_neighbourhood, [], "GET")
  },

  calculate(latitude, longitude, neighbourhoodId, modalityId, customerAddressId, companyId, neighbourhoodName, origin) {
    return API.request("/v1/fee/calculate", { latitude, longitude, neighbourhoodId, modalityId, customerAddressId, companyId, neighbourhoodName, origin }, "GET")
  },
}

export default DeliveryFeeController
