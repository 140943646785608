import React from "react"

//External Libs
import { Label, Spinner } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Contexts  console.log("user",user)
import UserContext from "../../contexts/UserContext"
import MenuContext from "../../contexts/MenuContext"
import SettingsContext from "../../contexts/SettingsContext"
import TabContext from "../../contexts/TabContext"
import LoadingContext from "../../contexts/LoadingContext"

//Helpers
import { removeLocalItem, setLocalItem } from "../../helpers/StorageTools"
import { onMessageDialog } from "../../helpers/GeneralTools"
// import { getLocalUser } from "../../helpers/UserTools"

//Controllers
import UserController from "../../controllers/UserController"
import CustomerController from "../../controllers/CustomerController"

//Components
import OrdersInfo from "./OrdersInfo"
import OrderDetails from "./OrderDetails"
import PersonalInfo from "./PersonalInfo"
import OrderProgress from "./OrderProgress"
import AddressesInfo from "./AddressesInfo"
import FidelitiesInfo from "./FidelitiesInfo"
import FullContentLoading from "../FullContentLoading"
import CFButton from "../CFButton/CFButton"

import userImage from "../../assets/img/general/no-user.png"
import "./Profile.scss"
import OrderContext from "../../contexts/OrderContext"

const Profile = ({ userConnected }) => {
  const { user, setUser, setUserSelectedAddress } = React.useContext(UserContext)
  const { setModalSignIn } = React.useContext(MenuContext)
  const { loading, setLoading } = React.useContext(LoadingContext)
  const { goToFidelity, goToOrderProgress, backToOrderProgress, goToOrders, moveToOrders, setGoToOrderProgress, showOrderProgressContext, clearOrderProgressContext } =
    React.useContext(TabContext)
  const { version } = React.useContext(SettingsContext)
  const { setOrder, order } = React.useContext(OrderContext)

  const [showOrdersInfo, setShowOrdersInfo] = React.useState({ status: false, from: null, content: null })
  const [pendingOrders, setPendingOrders] = React.useState(0)
  const [showFidelitiesInfo, setShowFidelitiesInfo] = React.useState(false)
  const [showPersonalInfo, setShowPersonalInfo] = React.useState(false)
  const [showAddressesInfo, setShowAddressesInfo] = React.useState(false)
  const [showOrderDetails, setShowOrderDetails] = React.useState({ status: false, content: null })
  const [showOrderProgress, setShowOrderProgress] = React.useState({ status: false, content: null })

  React.useEffect(() => {
    // Verificando se tem usuário conectado
    if (!userConnected) {
      setModalSignIn({ status: true })
    }
    //
    // else {
    //   const localUser = getLocalUser()
    //
    //   if (!!localUser) {
    //     setLoading(true)
    //     setUser(localUser)
    //     CustomerController.fetchOne(localUser.uuid_customer)
    //       .then((responseCustomer) => {
    //         if (responseCustomer.status === 200) {
    //           const addresses = responseCustomer.data?.streets || []
    //
    //           if (addresses.length > 0) {
    //             const defaultAddress = addresses.find(({ st_primary }) => st_primary) || addresses[0]
    //             if (!userSelectedAddress?.nm_street) {
    //               setLocalItem("userSelectedAddress", defaultAddress, true)
    //               onChangeUserSelectedAddress(defaultAddress)
    //             }
    //           }
    //
    //           setUser({ ...user, addresses })
    //           setLocalItem("user", { ...user, addresses }, true)
    //         }
    //       })
    //       .finally(() => {
    //         setLoading(false)
    //       })
    //   }
    // }
  }, [userConnected])

  React.useEffect(() => {
    // Verificando o número de pedidos dos usuários
    const pendingOrders = user?.customer_orders?.filter(({ dt_deleted_at, st_confirmed }) => !dt_deleted_at && !st_confirmed)?.length || 0
    setPendingOrders(pendingOrders)
  }, [user])

  React.useEffect(() => {
    if (goToOrderProgress?.status) {
      if (goToOrderProgress?.scheduleOrderUid) {
        // TODO: Mostrar Pedidos Agendados
      } else {
        if (goToOrderProgress?.order) {
          setShowOrdersInfo({ status: true, from: "goToProgress", content: goToOrderProgress?.order })
        }
      }
    }
  }, [goToOrderProgress])

  React.useEffect(() => {
    if (!!goToFidelity) {
      setShowFidelitiesInfo(goToFidelity)
    }
  }, [goToFidelity])

  React.useEffect(() => {
    if (!!goToOrders) {
      setShowOrdersInfo({ status: true, from: null, content: null })
      moveToOrders(false)
    }
  }, [goToOrders])

  React.useEffect(() => {
    setShowOrderProgress(showOrderProgressContext)
    setShowOrdersInfo({ status: true, from: "goToProgress", content: showOrderProgressContext.content })
  }, [showOrderProgressContext])

  const handlePersonalInfo = () => {
    setShowPersonalInfo(!showPersonalInfo)
  }

  const handleAddressesInfo = () => {
    setShowAddressesInfo(!showAddressesInfo)
  }

  const handleOrderInfo = (step, content, to = "details") => {
    if (to === "details") {
      setShowOrderDetails({ status: true, content })
    }

    if (to === "progress") {
      setShowOrderProgress({ status: true, content })
    }
  }

  const onUpdatePersonalInfo = async (newUser) => {
    let updateData = {}

    //Verifica se o cadastro do cliente
    const phoneIndex = newUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 1)
    const emailIndex = newUser.contacts.findIndex(({ fk_id_contact }) => fk_id_contact === 2)

    let phone = null
    let email = null

    if (user.nm_customer !== newUser.nm_customer) {
      updateData = {
        ...updateData,
        nm_customer: newUser.nm_customer,
      }
    }

    //Atualização dos dados de contatos
    if (emailIndex !== -1 && phoneIndex !== -1) {
      //Atualiza email e telefone
      phone = newUser.contacts[phoneIndex].val_contact.replace(/[^a-z0-9]/gi, "")
      email = newUser.contacts[emailIndex].val_contact
      updateData = {
        ...updateData,
        contacts: newUser.contacts.map(({ fk_id_contact, val_contact }) => {
          if (fk_id_contact === 2) {
            return {
              val_contact: newUser.contacts[emailIndex].val_contact,
              ds_contact: "e-mail",
            }
          } else if (fk_id_contact === 1) {
            return {
              val_contact: newUser.contacts[phoneIndex].val_contact.replace(/[^a-z0-9]/gi, ""),
              ds_contact: "celular",
            }
          }
        }),
      }
    } else if (emailIndex !== -1) {
      //Atualiza somente email

      email = newUser.contacts[emailIndex].val_contact
      updateData = {
        ...updateData,
        contacts: newUser.contacts.map(({ fk_id_contact, val_contact }) => {
          if (fk_id_contact === 2) {
            return {
              val_contact: newUser.contacts[emailIndex].val_contact,
              ds_contact: "e-mail",
            }
          }
        }),
      }
    } else if (phoneIndex !== -1) {
      //Atualiza somente telefone
      phone = newUser.contacts[phoneIndex].val_contact.replace(/[^a-z0-9]/gi, "")
      updateData = {
        ...updateData,
        contacts: newUser.contacts.map(({ fk_id_contact, val_contact }) => {
          if (fk_id_contact === 1) {
            return {
              val_contact: newUser.contacts[phoneIndex].val_contact.replace(/[^a-z0-9]/gi, ""),
              ds_contact: "celular",
            }
          }
        }),
      }
    }

    if (!!newUser.dt_birth) {
      updateData = {
        ...updateData,
        dt_birth: newUser.dt_birth,
      }
    }

    const login = !!phone ? phone : email
    const secondaryLogin = !!phone ? email : null

    setLoading(true)

    const uidCustomer = newUser.uuid_customer
    await UserController.verifyContacts(login, secondaryLogin, uidCustomer)
      .then((responseVerify) => {
        // console.log("responseVerify verifyContacts", responseVerify)
        if (responseVerify.status === 200) {
          CustomerController.update(user.uuid_customer, updateData).then((responseUpdate) => {
            // setLoading(false)
            if (responseUpdate.status === 200) {
              onMessageDialog("sucess")
                .fire({
                  title: responseUpdate.message,
                  icon: "success",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                })
                .then()

              const userUpdated = {
                ...newUser,
                contacts: responseUpdate.data.contacts.map(({ contact_customer: { val_contact, fk_id_contact } }) => {
                  return {
                    val_contact,
                    fk_id_contact,
                  }
                }),
              }

              setUser(userUpdated)
              setLocalItem("user", userUpdated, true)
            }

            if (responseUpdate.status === 400) {
              onMessageDialog("error")
                .fire({
                  title: responseUpdate.data.message,
                  icon: "warning",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                })
                .then()
            }
          })
        } else if (responseVerify.status === 400) {
          onMessageDialog("warning").fire({
            title: `Telefone ou e-mail já cadastrado!`,
            icon: "warning",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
          })
        } else {
          onMessageDialog("warning").fire({
            title: `Ocorreu um erro ao verificar seus contatos, por favor, tente novamente."}`,
            icon: "warning",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSignOut = () => {
    sessionStorage.clear()
    removeLocalItem("user")
    setUserSelectedAddress(null)
    setUser(null)
    setOrder({ ...order, benefits: [] })
  }

  return (
    <div className="profile">
      {loading ? <FullContentLoading loading /> : ""}
      {!!user && !!userConnected ? (
        <div className="profile--user">
          <div className="profile--user--header">
            <img src={userImage} alt="Foto do usuário" />
            <Label>{user.nm_customer || "Não identificado!"}</Label>
          </div>

          <div className="profile--user--body">
            {/*Meus dados*/}
            <div className="profile--user--body--menu-option" onClick={() => handlePersonalInfo()}>
              <div className="profile--user--body--menu-option--left">
                <FontAwesomeIcon icon="user" />
              </div>
              <div className="profile--user--body--menu-option--center">
                <div className="profile--user--body--menu-option--center--top">
                  <span>Meus Dados</span>
                </div>
                <div className="profile--user--body--menu-option--center--bottom">
                  <span>Suas informações pessoais</span>
                </div>
              </div>
              <div className="profile--user--body--menu-option--right">
                <FontAwesomeIcon icon={"chevron-right"} />
              </div>
            </div>

            {/*Endereços*/}
            <div className="profile--user--body--menu-option" onClick={() => handleAddressesInfo()}>
              <div className="profile--user--body--menu-option--left">
                <FontAwesomeIcon icon="location-dot" />
              </div>
              <div className="profile--user--body--menu-option--center">
                <div className="profile--user--body--menu-option--center--top">
                  <span>Endereços</span>
                </div>
                <div className="profile--user--body--menu-option--center--bottom">
                  <span>Seus endereços cadastrados</span>
                </div>
              </div>
              <div className="profile--user--body--menu-option--right">
                <FontAwesomeIcon icon={"chevron-right"} />
              </div>
            </div>

            {/*Meus Pedidos*/}
            {(() => {
              return (
                <div className="profile--user--body--menu-option" onClick={() => setShowOrdersInfo({ status: true, from: null, content: null })}>
                  <div className="profile--user--body--menu-option--left">
                    <FontAwesomeIcon icon="chart-bar" />
                  </div>
                  <div className="profile--user--body--menu-option--center">
                    <div className="profile--user--body--menu-option--center--top">
                      <span>Meus pedidos</span>
                    </div>
                    <div className="profile--user--body--menu-option--center--bottom">
                      <span>Seus últimos pedidos</span>
                    </div>
                  </div>
                  {pendingOrders > 0 ? (
                    <div className="profile--user--body--menu-option--right--label">{pendingOrders}</div>
                  ) : !user || !user.customer_orders ? (
                    <Spinner className="profile--user--body--menu-option--right--spinner" size="sm" color="danger" />
                  ) : (
                    ""
                  )}
                  <div className="profile--user--body--menu-option--right">
                    <FontAwesomeIcon icon={"chevron-right"} />
                  </div>
                </div>
              )
            })()}
            {/*Fidelidade*/}
            <div className="profile--user--body--menu-option" onClick={() => setShowFidelitiesInfo(!showFidelitiesInfo)}>
              <div className="profile--user--body--menu-option--left">
                <FontAwesomeIcon icon="percent" />
              </div>
              <div className="profile--user--body--menu-option--center">
                <div className="profile--user--body--menu-option--center--top">
                  <span>Fidelidade</span>
                </div>
                <div className="profile--user--body--menu-option--center--bottom">
                  <span>Acompanhe as campanhas de fidelidade</span>
                </div>
              </div>
              <div className="profile--user--body--menu-option--right">
                <FontAwesomeIcon icon={"chevron-right"} />
              </div>
            </div>

            {/*Sair*/}
            <div className="profile--user--body--menu-option" onClick={() => onSignOut()}>
              <div className="profile--user--body--menu-option--left">
                <FontAwesomeIcon icon="right-from-bracket" />
              </div>
              <div className="profile--user--body--menu-option--center">
                <div className="profile--user--body--menu-option--center--top">
                  <span>Sair</span>
                </div>
              </div>
              <div className="profile--user--body--menu-option--right">
                <FontAwesomeIcon icon={"chevron-right"} />
              </div>
            </div>
          </div>
          <div className="profile--user--body--version">
            <span>Versão: {version}</span>
          </div>

          {/*Telas adjacentes*/}
          <PersonalInfo
            initialUser={user}
            onUpdatePersonalInfo={onUpdatePersonalInfo}
            enablePersonalInfo={showPersonalInfo ? "personal-info-active" : ""}
            returnMenu={() => handlePersonalInfo()}
          />

          <FidelitiesInfo
            enableFidelitiesInfo={showFidelitiesInfo ? "fidelities-info-active" : ""}
            returnMenu={() => setShowFidelitiesInfo(!showFidelitiesInfo)}
          />

          <AddressesInfo setLoading={setLoading} status={showAddressesInfo} returnMenu={() => handleAddressesInfo()} />

          <OrdersInfo
            status={showOrdersInfo.status}
            customerUid={user.uuid_customer}
            returnMenu={() => {
              setShowOrdersInfo({ status: false, from: null, content: null })
              setGoToOrderProgress({ status: false, order: null })
            }}
            handleOrderInfo={handleOrderInfo}
            showOrderDetails={showOrderDetails}
            setShowOrderDetails={setShowOrderDetails}
            showOrderProgress={showOrderProgress}
            setShowOrderProgress={setShowOrderProgress}
            from={showOrdersInfo.from}
            content={showOrdersInfo.content}
          />

          <OrderProgress
            status={showOrderProgress.status}
            content={showOrderProgress.content}
            returnMenu={() => {
              setShowOrderProgress({ status: false, content: null })
              setShowOrdersInfo({ status: false, from: null, content: null })
              clearOrderProgressContext()
            }}
            handleOrderInfo={handleOrderInfo}
          />

          <OrderDetails
            status={showOrderDetails.status}
            order={showOrderDetails.content}
            returnMenu={() => {
              setShowOrderDetails({ status: !showOrderDetails.status, content: null })
            }}
            handleOrderInfo={handleOrderInfo}
          />
        </div>
      ) : (
        <div className="profile--no-user">
          <span>Você não está logado.</span>
          <span>Realize login e veja os suas informações</span>

          <div className="profile--no-user--button">
            <CFButton
              title={"Entrar"}
              icon={"right-to-bracket"}
              onClick={() => {
                setModalSignIn({ status: true })
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Profile
