import React from "react"

export default React.createContext({
  order: null,
  deliveryType: 0,
  currentOrderCombo: 0,
  setCurrentOrderCombo: () => null,
  setOrder: () => null,
  finishOrder: () => null,
})
