import React from "react"
import { Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./OrderResumeBar.scss"

import GeneralTools from "../helpers/GeneralTools"
import { getDiscountOrder } from "../helpers/OrderTools"
import SettingsContext from "../contexts/SettingsContext"

const OrderResumeBar = ({ order = null, setTab, deliveryFee, completeOrder }) => {
  const { settings, modality } = React.useContext(SettingsContext)
  // console.log("orederresumebar", { order, deliveryFee, completeOrder, settings, modality })
  const discount = getDiscountOrder(completeOrder, order[0], deliveryFee, settings, modality)
  // console.log("discount", discount)
  return (
    <section className="section-order-preview" onClick={!!order ? () => setTab() : () => { }}>
      <FontAwesomeIcon icon={"cart-shopping"} />
      &nbsp;
      <Label>Ver pedido</Label>&nbsp;
      {(() => {
        if (!!order) {
          return <Label>{GeneralTools.formatMoney(order[0] + deliveryFee - discount)}</Label>
        }
      })()}
    </section>
  )
}

export default OrderResumeBar
