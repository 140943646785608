import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import TabContext from "../contexts/TabContext"
import MenuContext from "../contexts/MenuContext"
import "./Footer.scss"

const Footer = () => {
  const { tabSelected, hideTabs, setTabSelected } = React.useContext(TabContext)
  const { setModalOrderPreview, modalOrderPreview } = React.useContext(MenuContext)

  if (hideTabs) {
    return ""
  }
  return (
    <footer className="section-footer">
      <div className={"section-footer--menu-item " + (tabSelected === "home" ? "selected-tab" : "")} onClick={() => setTabSelected("home")}>
        <FontAwesomeIcon icon="house" />
      </div>
      {/*<div className="section-footer--menu-item">*/}
      {/*  <FontAwesomeIcon icon="percent" />*/}
      {/*</div>*/}
      <div className={"section-footer--menu-item " + (modalOrderPreview ? "selected-tab" : "")} onClick={() => setModalOrderPreview(true)}>
        <FontAwesomeIcon icon="cart-shopping" />
      </div>

      {/*<div className={"section-footer--menu-item " + (tabSelected === "favorites" ? "selected-tab" : "")} onClick={() => setTabSelected("favorites")}>*/}
      {/*  <FontAwesomeIcon icon="heart" />*/}
      {/*</div>*/}
      <div className={"section-footer--menu-item " + (tabSelected === "profile" ? "selected-tab" : "")} onClick={() => setTabSelected("profile")}>
        <FontAwesomeIcon icon="user" />
      </div>
    </footer>
  )
}

export default Footer
