import React from "react"

//Helpers
import { getFormatDate } from "../../helpers/GeneralTools"

import "./ScheduleWeekDay.scss"

const ScheduleWeekDay = ({ contentData, selected = false, onSelect }) => {
  const getScheduleWorkDayStatus = () => {
    if (!contentData.st_open || contentData.work_times.length === 0) {
      return " closed-schedule-week-day"
    }

    if (selected) {
      return " selected-schedule-week-day"
    }

    return ""
  }

  return (
    <div
      onClick={contentData.st_open && contentData.work_times.length > 0 ? () => onSelect() : () => {}}
      className={"schedule-week-day--working-time" + getScheduleWorkDayStatus()}
    >
      <div className="schedule-week-day--working-time--top">
        <span>{getFormatDate(contentData.date + " 00:00").getDate("DD/MM")}</span>
      </div>
      <div className={"schedule-week-day--working-time--divider" + getScheduleWorkDayStatus()} />
      <div className="schedule-week-day--working-time--bottom">
        <span>{contentData.name.substring(0, 3).toUpperCase()}</span>
      </div>
    </div>
  )
}

export default ScheduleWeekDay
