import API from "../helpers/API"

const FidelityController = {
  fetchAll() {
    return API.request("/v1/fidelity/all", [], "GET")
  },

  deleteExpiredPoint(fidelityId) {
    return API.request("/v1/fidelity/customer/" + fidelityId, [], "DELETE")
  },
}

export default FidelityController
