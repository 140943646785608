import React from "react"

import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import CFButton from "../../../components/CFButton/CFButton"
import CFCheckButton from "../../../components/CFCheckButton/CFCheckButton"

import "./ModalNumberAddress.scss"
import { onMessageDialog } from "../../../helpers/GeneralTools"

const ModalNumberAddress = ({ status, content, onClose, onConfirmNumber }) => {
  const [number, setNumber] = React.useState("")
  const [noNumber, setNoNumber] = React.useState(false)

  const inputNumberRef = React.useRef(null)

  React.useEffect(() => {
    if (status && !!content) {
      setNumber(content.num_address || "")
    }
  }, [status])

  const onOpened = () => inputNumberRef?.current && inputNumberRef.current.focus()

  if (!!content) {
    return (
      <Modal className="modal-number-address" isOpen={status} toggle={onClose} backdrop={"static"} fade={false} keyboard={false} onOpened={onOpened}>
        <ModalHeader className="modal-number-address--header">
          <Label>Número do endereço</Label>
        </ModalHeader>
        <ModalBody className="modal-number-address--body">
          <Label className="modal-number-address--body--title">Informe o número do endereço</Label>
          <Label className="modal-number-address--body--label-address">{content.label}</Label>
          <Input
            type="number"
            value={number}
            placeholder="Número"
            innerRef={inputNumberRef}
            onChange={(event) => setNumber(event.target.value)}
            disabled={noNumber}
          />

          <div className="modal-number-address--body--no-number">
            <Label>Meu endereço não tem número</Label>
            <CFCheckButton
              onClick={() => {
                setNoNumber(!noNumber)
                setNumber("")
              }}
              status={noNumber}
            />
          </div>
        </ModalBody>
        <ModalFooter className="modal-number-address--footer">
          <CFButton title="Voltar" onClick={onClose} outline={true} />
          <CFButton
            title="Avançar"
            onClick={() => {
              if (noNumber || !!number) {
                onConfirmNumber(number, content.feature)
                setNoNumber(false)
              } else {
                onMessageDialog("warning")
                  .fire({
                    title: `Número de endereço inválido`,
                    icon: "info",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                  })
                  .then()
              }
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }

  return ""
}

export default ModalNumberAddress
