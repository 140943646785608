// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de agendamento
 */
const ScheduleController = {
  fetchAll() {
    return API.request(`/v1/schedule`, [], "GET")
  },

  fetchAllScheduleOrders(params) {
    return API.request("/v1/schedule/resume/orders/", params, "GET")
  },

  fetchAllByCustomer(params) {
    return API.request(`/v1/schedule/order`, params, "GET")
  },
}

export default ScheduleController
