export const checkBetweenIntervalPeriod = (currentDate, periods = [], periodTimesFieldName) => {
  const dayOfWeek = currentDate.day()
  const currentHour = currentDate.hour()
  const currentMinute = currentDate.minute()

  if (periods.length > 0) {
    return (
      periods[dayOfWeek]?.st_day_of_week &&
      periods[dayOfWeek][periodTimesFieldName].find(({ start_hour, start_minute, end_hour, end_minute }) => {
        if (currentHour > start_hour || (currentHour === start_hour && currentMinute >= start_minute)) {
          if (currentHour < end_hour || (currentHour === end_hour && currentMinute <= end_minute)) {
            return true
          }
        }
        return false
      })
    )
  }
  return true
}
