import React from "react"

//Helpers
import GeneralTools, { getUrlAWSManagerFile } from "../../../helpers/GeneralTools"

//External Libs
import { Modal, ModalBody, ModalFooter, Label } from "reactstrap"

//Components
import CFButton from "../../../components/CFButton/CFButton"
import FullContentLoading from "../../../components/FullContentLoading"
import { removeLocalItem, setLocalItem } from "../../../helpers/StorageTools"

//Styles
import placeholder from "../../../assets/img/general/placeholder@2x.png"
import "./ModalAddressNotFound.scss"

const ModalAddressNotFound = ({ status, companies, title, onClose, onUpdate }) => {
  const [loading, setLoading] = React.useState(false)

  const onSelectedCompany = (company) => {
    if (!company.url_redirect) {
      // Vai definir que o pedido está fora da área de entrega
      // handleOutsideCoverageArea(isOutsideCoverageArea)
      setLocalItem("company", company)
      // Limpando todos os dados salvos no Storage
      removeLocalItem("menuOptions")
      removeLocalItem("settings")

      removeLocalItem("highlights")
      removeLocalItem("combos")

      removeLocalItem("products")
      removeLocalItem("productSizes")
      removeLocalItem("productFlavours")

      removeLocalItem("beverages")
      removeLocalItem("schedules")

      removeLocalItem("deliveryFee")
      removeLocalItem("deliveryTime")

      onUpdate(company)
    } else {
      GeneralTools.swalMessage("delete")
        .fire({
          title: `Você será direcionado ao endereço desta loja, deseja prosseguir?`,
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "Voltar",
          showConfirmButton: true,
          confirmButtonText: "Prosseguir",
          reverseButtons: true,
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            setLoading(true)
            window.open(company.url_redirect, "_self")
          }
        })
    }
  }

  if (!companies && companies.length === 0) return ""

  if (loading) return <FullContentLoading loading={loading} />

  return (
    <Modal isOpen={status} keyboard={false} backdrop={true} className={"modal-address-not-found"} toggle={onClose}>
      <ModalBody>
        {/*<div>*/}
        {(() => {
          return (
            <div className={"modal-address-not-found--company-list"}>
              <Label>{title}</Label>
              <Label className={"modal-address-not-found--company-list--tittle"}>Deseja trocar de loja? </Label>
              {companies.map((company, key) => {
                return (
                  <div key={key}>
                    <div className="modal-address-not-found--company" onClick={() => onSelectedCompany(company)}>
                      <div className="modal-address-not-found--company--img">
                        <img alt={`logo ${company.nm_company}`} src={!!company.url_image ? getUrlAWSManagerFile(company.url_image) : placeholder} />
                      </div>

                      <div className="modal-address-not-found--companies--company">
                        <div className="modal-address-not-found--companies--company--name">{company.nm_company}</div>
                        <div className="modal-address-not-found--companies--company--modalities">
                          <div className="modal-address-not-found--companies--company--modalities--active">Trocar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })()}
        {/*</div>*/}
      </ModalBody>
      <ModalFooter className={"modal-address-not-found--modal-footer"}>
        <CFButton title={"Cancelar"} onClick={onClose} outline={true} />
      </ModalFooter>
    </Modal>
  )
}

export default ModalAddressNotFound
