import React from "react"
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Contexts

//Components

//Helpers

import "./ModalClientAddressNotFound.scss"
import CFButton from "../../../components/CFButton/CFButton"
const ModalClientAddressNotFound = ({ status, address, onClose, openAddressModal }) => {
  return (
    <Modal isOpen={status} backdrop={"static"} fade={false} className="modal-client-address-not-found">
      <ModalBody className={"modal-client-address-not-found--body"}>
        <h1>Infelizmente não conseguimos encontrar seu endereço!</h1>
        <div className="modal-client-address-not-found--body-buttons">
          <CFButton title="Tente novamente" outline={true} onClick={onClose} />
          <CFButton
            title="Inserir manualmente"
            onClick={() => {
              openAddressModal(address)
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalClientAddressNotFound
