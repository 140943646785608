// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const CredentialController = {
  fetchOne() {
    return API.request("/v1/site", [], "GET")
  },
}

export default CredentialController
