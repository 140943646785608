// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados de combos
 */
const SettingController = {
  // fetchPaymentsMethods() {
  //     return API.request('/setting/payment-methods', [], 'GET');
  // },
  fetchAllCategoryPaymentsMethods(filter = {}) {
    const params = {
      orderField: "id",
      orderKeyword: "asc",
      page: 1,
      pageSize: 1000,
      ...filter,
    }

    return API.request("/v1/category/payment/method/all", params, "GET")
  },

  fetchAll() {
    return API.request("/v1/setting", [], "GET")
  },
}

export default SettingController
